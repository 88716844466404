import { createSlice } from "@reduxjs/toolkit";

const initialAuthState = {
  isLoggedIn: sessionStorage.getItem("user") ? true : false,
  darkMode: sessionStorage.getItem("darkMode") ? true : false,
  user: sessionStorage.getItem("user"),
  userProfile: {},
};

const authSlice = createSlice({
  name: "authentication",
  initialState: initialAuthState,
  reducers: {
    login(state, action) {
      sessionStorage.setItem("user", JSON.stringify(action.payload));
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    logout(state) {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("userProfile");
      state.user = {};
      state.isLoggedIn = false;
    },
    update(state, action) {
      state.user = action.payload;
    },
    setDarkMode(state, action) {
      sessionStorage.setItem("darkMode", action.payload);
      state.darkMode = action.payload;
    },
    userProfile(state, action) {
      sessionStorage.setItem("userProfile", JSON.stringify(action.payload));
      state.userProfile = action.payload;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
