import { Auth } from "aws-amplify";
import axios from "axios";

const SNP_API_BASE_URL = process.env.APT_API;

class SnpService {
  async uploadFile(file) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    let body = file;

    return await axios.post(
      SNP_API_BASE_URL + "/snp/search/file/rsid",
      body,
      myInit
    );
  }

  async getRSID(id) {
    let myInit = {
      params: {
        rsid: `${id}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await axios.get(SNP_API_BASE_URL + "/snp/search/rsid", myInit);
  }

  async getRSIDAll(id) {
    let myInit = {
      params: {
        rsid: `${id}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(SNP_API_BASE_URL + "/snp/search/rsid", myInit);
  }
  async getRSIDList(id) {
    let myInit = {
      params: {
        query: `${id}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(SNP_API_BASE_URL + "/snp/search/list/rsids", myInit);
  }
}

export default new SnpService();
