import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";

const AcsBulkBtn = (props) => {
  const handleBulkNext = () => {
    if (props.activeStep === 0) {
      props.setActiveStep(5);
    }
  };

  return (
    <div
      style={{
        float: "right",
        top: "43px",
        position: "relative",
        right: "125px",
      }}
    >
      <Button
        variant="contained"
        size="small"
        onClick={handleBulkNext}
        disabled={
          props.activeStep === 0 &&
          props.client &&
          props.project &&
          props.trackingNumber &&
          props.formListHeader.site &&
          !props.formListHeader.subject_code &&
          props.formListHeader.carrier &&
          props.formListHeader.dt_received_timezone
            ? false
            : true
        }
      >
        Bulk
      </Button>
    </div>
  );
};

export default AcsBulkBtn;
