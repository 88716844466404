import React from "react";
import { Box, MuiAppBar, Toolbar, Typography, Paper } from "../mui";

import logo from "../assets/sampled-logo.png";

export const Floater = ({ msg }) => {
  return (
    <marquee
      width="100%"
      direction="right"
      //   height="15px"
      bgcolor="red"
      scrollamount="8"
      style={{
        color: "#eaf76c",
        fonstSize: "2px",
        fontWeight: "bold",
        // fontFamily: "sans-serif",
      }}
    >
      {msg}
    </marquee>
  );
};

const Maintenance = () => {
  return (
    <Box>
      <MuiAppBar
        position="fixed"
        elevation={1}
        sx={{
          bgcolor: "white",
        }}
      >
        <Toolbar>
          <Box
            sx={{ flex: 1, height: 30, display: { xs: "none", md: "flex" } }}
          >
            <img src={logo} alt="Logo" />
          </Box>
        </Toolbar>
      </MuiAppBar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
          width: "100%",
        }}
      >
        <Box
          component={Paper}
          variant="elevation"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
            height: "40%",
            width: "70%",
          }}
        >
          <Typography variant="h4" component="h1" align="center">
            Temporarily Down for Maintenance.
          </Typography>
          <Typography variant="body1" align="center" mt={2}>
            We apologize for the inconvenience. The website is currently
            undergoing maintenance. We should be back online shortly.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Maintenance;
