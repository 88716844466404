import { Stack, Divider, Modal, Typography, Button } from "@mui/material";
import React from "react";
// import UIBreadcrumbs from "../../components/UIBreadcrumbs";
import AptService from "../../services/AptService";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
const style = {
    position: "absolute",
    display: "flex",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
};

const PreviewFormPopUp = (props) => {
  const handleAptRun = () => {
    AptService.runAptTool(props.formList)
      .then((resp) => {
        props.handleClose();
        // setRunningWorkflows(resp.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Modal
      open={props.openDetail}
      onClose={() => props.handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style}>
        <Stack direction="row" style={{ paddingBottom: "10px" }}>
          <Typography color="secondary" sx={{ fontSize: 18 }}>
            Preview Data
          </Typography>
        </Stack>
        <Divider style={{ width: "100%" }}></Divider>

        <Stack style={{ overflow: "auto", paddingTop: "8px" }}>
          {Object.keys(props.formList).length !== 0 &&
            Object.keys(props.formList)
              .slice(0, -1)
              .map((row, index) => (
                <Stepper nonLinear style={{ padding: "2px" }} key={index}>
                  <Step completed={false}>
                    <Typography color="secondary" sx={{ fontSize: 14 }}>
                      {row}
                    </Typography>
                  </Step>
                  <Step completed={row.status === "COMPLETED" ? true : false}>
                    <Typography color="secondary" sx={{ fontSize: 14 }}>
                      {props.formList[row]}
                    </Typography>
                  </Step>
                </Stepper>
              ))}
        </Stack>
        <Button
          style={{ width: "100%" }}
          variant="contained"
          onClick={handleAptRun}
        >
          Confirm
        </Button>
      </Stack>
    </Modal>
  );
};

export default PreviewFormPopUp;
