import { Auth } from "aws-amplify";
import axios from "axios";

const ONHOLD_API_BASE_URL = process.env.MAIN_API;

class OnHoldService {
  async getOnHoldSamples() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ONHOLD_API_BASE_URL + "/onhold-tracking/on-hold-samples",
      myInit
    );
  }

  async exportOnHoldSamples(list) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return await axios.get(
      ONHOLD_API_BASE_URL + "/onhold-tracking/on-hold-samples-export",
      myInit
    );
  }

  async getOnHoldClients() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ONHOLD_API_BASE_URL + "/onhold-tracking/onhold-clients",
      myInit
    );
  }

  async ingestOnHoldSamplesFile(file, client) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    const formData = new FormData();
    formData.append("file_obj", file);
    formData.append("client", client);

    return await axios.post(
      ONHOLD_API_BASE_URL + "/onhold-tracking/onhold-samples",
      formData,
      myInit
    );
  }

  async bulkEditOnHoldSamples(file) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.put(
      ONHOLD_API_BASE_URL + "/onhold-tracking/bulk-edit-onhold-samples",
      file,
      myInit
    );
  }
  async downloadTemplate() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ONHOLD_API_BASE_URL + "/onhold-tracking/onhold-bulk-edit/template",
      myInit
    );
  }

  async downloadIngestTemplate() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ONHOLD_API_BASE_URL + "/onhold-tracking/onhold-bulk-ingest/template",
      myInit
    );
  }

  async singleEditOnHoldSample(sampledata) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.put(
      ONHOLD_API_BASE_URL + "/onhold-tracking/onhold-sample-edit",
      sampledata,
      myInit
    );
  }
  async getAllCarriers() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ONHOLD_API_BASE_URL + "/onhold-tracking/search/carriers",
      myInit
    );
  }
  async previewOnHoldFile(file) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    const formData = new FormData();
    formData.append("file_obj", file);

    return await axios.post(
      ONHOLD_API_BASE_URL + "/onhold-tracking/map/preview/orig",
      formData,
      myInit
    );
  }
}

export default new OnHoldService();
