import { useState, useEffect } from "react";
import ManagementService from "../../../services/ManagementService";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../../../Store/snackbar";
import { Box } from "../../../mui";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { GridToolbarColumnsButton } from "@mui/x-data-grid";
import { GridToolbarDensitySelector } from "@mui/x-data-grid";
import { Typography } from "@mui/material";

const AF2History = () => {
  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const dispatch = useDispatch();

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  // columns configuration for DataGrid Table
  const AF2ActiveColumns = [
    {
      field: "file_name",
      headerName: "File Name",
      minWidth: 300,
      editable: false,
      valueGetter: (params) => {
        if (!params.value) {
          return "None/Null";
        }
        return params.value;
      },
    },
    {
      field: "file_date",
      headerName: "File Date",
      minWidth: 220,
      editable: false,
      valueGetter: (params) => {
        if (!params.value) {
          return "None/Null";
        }
        return params.value;
      },
    },
    {
      field: "deltas_file",
      headerName: "Deltas File",
      minWidth: 350,
      editable: false,
      valueGetter: (params) => {
        const fileNameArray = params.value.split("/");
        const fileName = fileNameArray[fileNameArray.length - 1];
        if (!params.value) {
          return "None/Null";
        }
        return fileName;
      },
    },
    {
      field: "deltas_summary_file",
      headerName: "Deltas Summary",
      minWidth: 390,
      editable: false,
      valueGetter: (params) => {
        const fileNameArray = params.value.split("/");
        const fileName = fileNameArray[fileNameArray.length - 1];
        if (!params.value) {
          return "None/Null";
        }
        return fileName;
      },
    },
    {
      field: "file_status",
      headerName: "Status",
      minWidth: 120,
      editable: false,
      valueGetter: (params) => {
        if (!params.value) {
          return "None/Null";
        }
        return params.value;
      },
    },
    {
      field: "status_update_date",
      headerName: "Accepted/Rejected Date",
      minWidth: 180,
      editable: false,
      valueGetter: (params) => {
        if (!params.value) {
          return "None/Null";
        }
        return params.value;
      },
    },
    {
      field: "action_by",
      headerName: "Action",
      minWidth: 250,
      editable: false,
      valueGetter: (params) => {
        if (!params.value) {
          return "None/Null";
        }
        return params.value;
      },
    },
  ];

  useEffect(() => {
    getAF2FileRecords();
    return;
  }, []);

  const getAF2FileRecords = async () => {
    setDataLoading(true);
    await ManagementService.getAF2FileRecords()
      .then((resp) => {
        const fiterData = resp.data.filter(
          (row) =>
            row.file_status === "accepted" ||
            row.file_status === "rejected" ||
            row.file_status === "error"
        );
        setTableData(
          fiterData.map((row, id) => {
            return { id, ...row };
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed to get Samples Pending Accessioning.",
          })
        );
      });
    setDataLoading(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "20px",
      }}
    >
      <Typography sx={{ mb: 1, fontSize: 15, fontWeight: "800" }}>
        Total Number of Records: {tableData.length}
      </Typography>
      <Box
        height="375px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <DataGrid
          rows={tableData}
          columns={AF2ActiveColumns}
          getCellClassName={(params) => {
            if (params.field === "file_status") {
              return params.value;
            }
          }}
          loading={dataLoading}
          density="compact"
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
          }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default AF2History;
