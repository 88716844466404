import { Box, Grid } from "@mui/material";
import {
  Autocomplete,
  Button,
  Divider,
  Tab,
  Tabs,
  TextField,
  Typography,
  Link,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
} from "../mui";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import UIWrapper from "../components/UIWrapper";
import AcsService from "../services/AcsService";
import BiService from "../services/BiService";
import { snackbarActions } from "../Store/snackbar";
import Alert from "@mui/material/Alert";
import { FormContainer } from "react-hook-form-mui";
import FileUploadBulkEdit from "../components/FileUploadBulkEdit";

const tableRowData = ["Inventory Code", "Column Name"];
const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const EditFileUpload = (props) => {
  const [value, setValue] = React.useState("two");
  const [clientOptions, setClientOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [clientState, setClientState] = useState("");
  const [projectState, setProjectState] = useState("");
  const [files, setFiles] = useState(null);
  // const [uploadData, setUploadData] = React.useState([]);
  const [errData, setErrorData] = React.useState([]);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [validateFileLoading, setValidateFileLoading] = React.useState(false);
  const [btnDisable, setBtnDisable] = React.useState(true);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [showSummary, setShowSummary] = React.useState(false);
  const [responseData, setResponseData] = React.useState(null);
  const [pageLarge, setPageLarge] = React.useState(0);
  const [rowsPerPageLarge, setRowsPerPageLarge] = React.useState(6);

  const handleChangePageLarge = (event, newPage) => {
    setPageLarge(newPage);
  };

  const handleChangeRowsPerPageLarge = (event) => {
    setRowsPerPageLarge(+event.target.value);
    setPageLarge(0);
  };
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const downloadTemplate = () => {
    AcsService.downloadTemplate(clientState, projectState)
      .then((resp) => {
        const url = resp.data.presigned_url;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "template");
        document.body.appendChild(link);
        link.click();
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Successfully Downloaded Template",
          })
        );
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.detail,
          })
        );
        setFiles(null);
      });
    return;
  };
  const handleEditSubmission = (e) => {
    setSubmitLoading(true);
    AcsService.uploadEditFile(clientState, projectState, files)
      .then((resp) => {
        console.log("$", resp);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data.msg,
          })
        );
        setFiles(null);
        setSubmitLoading(false);
        setBtnDisable(true);
        if (resp.data.summary.length !== 0) {
          setShowSummary(true);
          setResponseData(resp.data);
        }
      })
      .catch((err) => {
        setFiles(null);
        setSubmitLoading(false);
        setBtnDisable(true);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Upload Submission Failed",
          })
        );
      });
    return;
  };

  const handleEditValidation = (files) => {
    AcsService.uploadEditFileValidations(clientState, projectState, files)
      .then((resp) => {
        console.log(resp);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );
      })
      .catch((err) => {
        setFiles(null);
        setErrorData(err.response.data.detail);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Upload Submission Failed",
          })
        );
      });
    return;
  };

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    let errText = errData.map((err) => err.split(".").join("\n"));
    const file = new Blob(errText, { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const handleCloseSummary = () => {
    setShowSummary(false);
  };
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        BiService.searchProjects(clientState, projectState).then((resp) =>
          setProjectOptions(resp.data)
        ),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [projectState, clientState]);

  useEffect(() => {
    BiService.getAllClients("").then((resp) => setClientOptions(resp.data));
    return;
  }, []);

  return (
    <UIWrapper title="Edit or Update Data" paper>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="primary tabs example"
        >
          <Tab value="two" label="File Upload" />
        </Tabs>
        <Divider style={{ width: "100%" }}></Divider>
        {value === "two" && (
          <Grid
            container
            spacing={2}
            sx={{ mt: 1 }}
            align="center"
            justify="center"
          >
            <Grid item xs={12}>
              <Typography>
                Upload a file with the following columns: inventory_code,
                col_name, new_value, and reason. <br></br> Column Name
                (col_name) is the biosphere name of the data attribute that you
                are targeting (e.g. number_of_cells). <br></br>New Value
                (new_value) is the new value that you are replacing / updating
                in the column name
                <br /> <br />
                <Typography>
                  Quickly{" "}
                  <Button
                    disabled={clientState && projectState ? false : true}
                    onClick={downloadTemplate}
                    style={{
                      textTransform: "capitalize",
                      padding: "0",
                      margin: "-1px 0 0 0",
                      fontSize: "0.85rem",
                    }}
                  >
                    Download
                  </Button>{" "}
                  the template file
                </Typography>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormContainer>
                <Typography
                  color="rgba(0, 0, 0, 0.6)"
                  style={{ marginBottom: "5px" }}
                >
                  Select a Client and Project..
                </Typography>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  size="small"
                  margin="dense"
                  options={clientOptions}
                  onChange={(e, new_value) => setClientState(new_value)}
                  renderInput={(params) => (
                    <TextField margin="dense" {...params} label="Client" />
                  )}
                  value={clientState}
                  sx={{ minWidth: 275 }}
                />
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  size="small"
                  options={projectOptions}
                  onChange={(e, new_value) => setProjectState(new_value)}
                  renderInput={(params) => (
                    <TextField margin="dense" {...params} label="Project" />
                  )}
                  value={projectState}
                  disabled={!clientState}
                />
              </FormContainer>
            </Grid>
            <Grid item xs={12} md={8}>
              <FileUploadBulkEdit
                files={files}
                setFiles={setFiles}
                setErrorData={setErrorData}
                accept={["xlsx"]}
                disabled={!projectState}
                projectState={projectState}
                clientState={clientState}
                setBtnDisable={setBtnDisable}
                setValidateFileLoading={setValidateFileLoading}
                setShowSummary={setShowSummary}
                setResponseData={setResponseData}
              />
            </Grid>
            <Grid item xs={12}>
              {files && errData.length != 0 && (
                <Alert severity="success">
                  {files && files.name
                    ? `${files.name} Successfully Uploaded`
                    : "File Successfully Uploaded"}
                </Alert>
              )}
            </Grid>
            <Grid item xs={12}>
              {errData.length > 0 &&
                errData.map((err, key) => {
                  return (
                    <Alert
                      key={key}
                      severity="error"
                      style={{ margin: "5px 0 0 0" }}
                    >
                      {err}
                    </Alert>
                  );
                })}
              {errData.length > 0 && (
                <Button
                  variant="outlined"
                  onClick={downloadTxtFile}
                  style={{ margin: "10px 0 0 0", textTransform: "none" }}
                >
                  Export to .txt
                </Button>
              )}
            </Grid>
            {validateFileLoading && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CircularProgress color="success" size={20} />
                  <Typography>Validating...</Typography>
                </Grid>
              </Grid>
            )}
            {showSummary && (
              <Grid item xs={12}>
                <Stack direction="column">
                  <Stack
                    direction="row"
                    onClick={() => setIsExpanded(!isExpanded)}
                    style={{ cursor: "pointer" }}
                  >
                    {isExpanded ? (
                      <ExpandMoreIcon
                        fontSize="large"
                        style={{ color: "#9b51e0" }}
                      ></ExpandMoreIcon>
                    ) : (
                      <ChevronRightIcon
                        fontSize="large"
                        style={{ color: "#9b51e0" }}
                      ></ChevronRightIcon>
                    )}
                    <Typography
                      align="left"
                      variant="h6"
                      style={{ fontWeight: "bold", color: "#484D56" }}
                    >
                      Summary
                    </Typography>
                    <IconButton
                      onClick={handleCloseSummary}
                      style={{ margin: "0 0 0 auto" }}
                      color="secondary"
                      aria-label="Close Summary"
                    >
                      <ClearIcon />
                    </IconButton>
                  </Stack>

                  {isExpanded && (
                    <Box sx={{ width: "100%" }}>
                      <Divider
                        style={{ width: "100%", marginBottom: "10px" }}
                      ></Divider>

                      <Grid
                        style={{
                          textAlign: "start",
                          margin: "0 0 0 10px",
                        }}
                        item
                        xs={12}
                        key="1"
                      >
                        <Typography
                          variant="subtitle2"
                          style={{
                            fontWeight: "bold",
                            color: "#9b51e0",
                          }}
                        >
                          {responseData.summary_msg}
                        </Typography>
                        <TableContainer style={{ marginTop: "15px" }}>
                          <Table
                            stickyHeader={true}
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            size={"small"}
                          >
                            <TableHead sx={{ minWidth: 650 }}>
                              <TableRow sx={{ minWidth: 650 }}>
                                {tableRowData.map((row, index) => (
                                  <TableCell
                                    align="left"
                                    key={index}
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {row}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody style={{ border: "none" }}>
                              {responseData.summary.length !== undefined &&
                                responseData.summary
                                  .slice(
                                    pageLarge * rowsPerPageLarge,
                                    pageLarge * rowsPerPageLarge +
                                      rowsPerPageLarge
                                  )
                                  .map((row, index) => (
                                    <TableRow
                                      hover={true}
                                      key={index}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell align="left">
                                        {row.inventory_code || "None / Null"}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.column_name || "None / Null"}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                            </TableBody>
                          </Table>
                          <TablePagination
                            rowsPerPageOptions={[6, 10, 25]}
                            component="div"
                            count={responseData.summary.length}
                            rowsPerPage={rowsPerPageLarge}
                            page={pageLarge}
                            onPageChange={handleChangePageLarge}
                            onRowsPerPageChange={handleChangeRowsPerPageLarge}
                          />
                        </TableContainer>
                      </Grid>
                      <Divider
                        style={{ width: "100%", marginTop: "10px" }}
                      ></Divider>
                    </Box>
                  )}
                </Stack>
              </Grid>
            )}

            <Grid item xs={12}>
              {submitLoading ? (
                <Box sx={{ flexDirection: "row" }}>
                  <Button variant="contained">
                    Submitting
                    <CircularProgress
                      style={{ margin: "0 0 0 5px" }}
                      color="inherit"
                      size={15}
                    />
                  </Button>
                </Box>
              ) : (
                <Button
                  onClick={handleEditSubmission}
                  disabled={
                    errData.length === 0 && files != null && !btnDisable
                      ? false
                      : true
                  }
                  variant="contained"
                >
                  Submit Edit File
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </UIWrapper>
  );
};
export default EditFileUpload;
