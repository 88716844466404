import { Button } from "../../mui";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddColumnDialog from "./RuleDialogs/AddColumnDialog";
import AppendDialog from "./RuleDialogs/AppendDialog";
import JoinDialog from "./RuleDialogs/JoinDialog";
import SliceDialog from "./RuleDialogs/SliceDialog";
import ValueMapDialog from "./RuleDialogs/ValueMapDialog";

const Options = ({ columns }) => {
  const { rules, selectedCount } = useSelector((state) => state.mapManifest);
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(null);

  return (
    <>
      <Button
        sx={{ m: 1, flex: 1 }}
        variant="contained"
        color="yellow"
        disabled={!(selectedCount == 0)}
        onClick={() => setOpenDialog("add-column")}
      >
        Add Column
      </Button>
      <Button
        sx={{ m: 1, flex: 1 }}
        variant="contained"
        color="blue"
        disabled={!(selectedCount == 1)}
        onClick={() => setOpenDialog("value-map")}
      >
        Value Map
      </Button>
      <Button
        sx={{ m: 1, flex: 1 }}
        variant="contained"
        color="red"
        disabled={!(selectedCount == 1)}
        onClick={() => setOpenDialog("append-prepend")}
      >
        Append / Prepend
      </Button>
      <Button
        sx={{ m: 1, flex: 1 }}
        variant="contained"
        color="turquoise"
        disabled={!(selectedCount == 1)}
        onClick={() => setOpenDialog("slice")}
      >
        Slice
      </Button>
      <Button
        sx={{ color: "black", m: 1, flex: 1 }}
        variant="contained"
        color="primary"
        disabled={!(selectedCount == 2)}
        onClick={() => setOpenDialog("join")}
      >
        Join
      </Button>
      <ValueMapDialog
        open={openDialog == "value-map"}
        onClose={() => setOpenDialog(null)}
      />
      <AddColumnDialog
        open={openDialog == "add-column"}
        onClose={() => setOpenDialog(null)}
      />
      <AppendDialog
        open={openDialog == "append-prepend"}
        onClose={() => setOpenDialog(null)}
      />
      <SliceDialog
        open={openDialog == "slice"}
        onClose={() => setOpenDialog(null)}
      />
      <JoinDialog
        open={openDialog == "join"}
        onClose={() => setOpenDialog(null)}
      />
    </>
  );
};

export default Options;
