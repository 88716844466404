import { createSlice } from "@reduxjs/toolkit";

const initialNavigationState = {
  path : ""
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState: initialNavigationState,
  reducers: {
    setPath(state, action) {
      state.path = action.payload;
    },
  },
});

export const navigationActions = navigationSlice.actions;

export default navigationSlice.reducer;
