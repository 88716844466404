import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch } from "react-router-dom";
import { navigationActions } from "../../Store/navigation";

const ParentWrapper = (props) => {
  const dispatch = useDispatch();
  const { path, children } = props;
  useEffect(() => {
    dispatch(navigationActions.setPath(path));
  }, [path]);
  return (
    <Box>
      <Switch>{children}</Switch>
    </Box>
  );
};

export default ParentWrapper;
