import { useEffect, useState, useRef } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import OnHoldService from "../../services/OnHoldService";
import UIWrapper from "../../components/UIWrapper";
import { CSVLink } from "react-csv";
import { Typography, Button, Modal, Box, Stack, Tooltip } from "../../mui";
import PreAcsBulkEdit from "./OnHoldBulkEdit";
import { snackbarActions } from "../../Store/snackbar";
import EditOnHoldSample from "./EditOnHoldSample";
import { useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const onHoldColumns = [
  // { field: "id", headerName: "ID", width: 90, hide: true },
  {
    field: "client",
    headerName: "Client",
    minWidth: 100,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "project",
    headerName: "Project",
    minWidth: 150,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "inventory_code",
    headerName: "Inventory Code",
    minWidth: 180,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "tracking_number",
    headerName: "Tracking Number",
    minWidth: 180,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "carrier",
    headerName: "Carrier",
    minWidth: 100,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "storage_location",
    headerName: "Storage Location",
    minWidth: 150,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value && params.value !== 0) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "temperature",
    headerName: "Temperature",
    minWidth: 100,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value && params.value !== 0) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "tube_type",
    headerName: "Tube Type",
    minWidth: 100,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value && params.value !== 0) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "date_disposition",
    headerName: "Date Disposition",
    minWidth: 150,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "date_received",
    headerName: "Date Received",
    minWidth: 150,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "created_on",
    headerName: "Created On",
    minWidth: 180,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "created_by",
    headerName: "Created By",
    minWidth: 200,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "final_disposition",
    headerName: "Final Disposition",
    minWidth: 100,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value && params.value !== 0) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "on_hold_reason",
    headerName: "On-Hold Reason",
    minWidth: 100,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "updated_by",
    headerName: "Updated By",
    minWidth: 200,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "comments",
    headerName: "Comment",
    minWidth: 100,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "Edit",
    minWidth: 60,
    flex: 1,
    renderCell: () => (
      <Tooltip title="Edit Aliquot Values" style={{ cursor: "pointer" }}>
        <EditIcon color={"primary"}></EditIcon>
      </Tooltip>
    ),
    editable: false,
  },
];

const OnHoldQueue = () => {
  const [tableData, setTableData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const csvLink = useRef();
  const [fileName, setFileName] = useState("");
  const [originalData, setOriginalData] = useState({});

  const changeHandler = (event) => {
    setOpenUploadModal(true);
  };
  const handleUploadClose = () => {
    setOpenUploadModal(false);
  };

  const handleAcsEdit = (params) => {
    if (params.field === "Edit") {
      setOriginalData(params.row);
      setOpenEditModal(true);
    }
  };

  const downloadTemplate = () => {
    OnHoldService.downloadTemplate()
      .then((resp) => {
        const url = resp.data.presigned_url;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "template");
        document.body.appendChild(link);
        link.click();
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Successfully downloaded the template file",
          })
        );
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
      });
    return;
  };
  useEffect(() => {
    if (exportData && csvLink.current && csvLink.current.link) {
      setTimeout(() => {
        csvLink.current.link.click();
        setExportData([]);
      });
    }
  }, [exportData]);

  const handleOnHoldSamplesExport = async () => {
    await OnHoldService.exportOnHoldSamples()
      .then((resp) => {
        let currentTimestamp = Date.now();
        let date = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(currentTimestamp);
        date = date.replace(/[^a-zA-Z0-9- ]/g, "_");
        date = date.replace(/\s/g, "");
        date = date.slice(0, -2);
        setFileName("on_hold_sample_export_" + date + ".csv");
        setExportData(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setDataLoading(true);

    const getSample = async () => {
      await OnHoldService.getOnHoldSamples()
        .then((resp) => setTableData(resp.data))
        .catch((err) => {
          console.log(err);
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage:
                typeof err?.response?.data?.detail === "string"
                  ? err.response.data.detail
                  : "Something went wrong.",
            })
          );
        });
      setDataLoading(false);
    };
    getSample();

    return;
  }, [refresh]);

  useEffect(() => {
    if (selectedFile) {
      const data = new FormData();
      data.append("file", selectedFile);
      OnHoldService.bulkEditOnHoldSamples(data)
        .then((resp) => {
          // setUploadData(resp.data);
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "success",
              snackbarMessage: resp.data,
            })
          );
          setSelectedFile(null);
          setRefresh((prev) => !prev);
        })
        .catch((err) => {
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage:
                typeof err?.response?.data?.detail === "string"
                  ? err.response.data.detail
                  : "Something went wrong.",
            })
          );
          setSelectedFile(null);
        });
      return;
    }
  }, [selectedFile]);

  return (
    <UIWrapper title="Samples On-Hold" paper>
      <Box
        sx={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Typography sx={{ mb: 1, fontSize: 15, fontWeight: "800" }}>
          Total Number of On-Hold Samples: {tableData.length}
        </Typography>
        <Box
          height="375px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <DataGrid
            rows={tableData}
            columns={onHoldColumns}
            loading={dataLoading}
            density="compact"
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
            }}
            onCellClick={handleAcsEdit}
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
            }}
          />
        </Box>
      </Box>

      {tableData && tableData.length !== 0 && (
        <>
          <Button
            style={{ marginTop: "15px" }}
            type="submit"
            variant="contained"
            onClick={handleOnHoldSamplesExport}
          >
            Export
          </Button>
          <Button
            style={{ marginTop: "15px", marginLeft: "20px" }}
            type="submit"
            variant="contained"
            onClick={changeHandler}
          >
            Bulk Update On-Hold Queue
          </Button>
        </>
      )}
      {exportData.length ? (
        <CSVLink
          data={exportData}
          filename={fileName}
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
      ) : null}
      <Modal
        open={openUploadModal}
        onClose={handleUploadClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack>
          <PreAcsBulkEdit
            files={selectedFile}
            setFiles={setSelectedFile}
            setOpen={setOpenUploadModal}
            downloadTemplate={downloadTemplate}
            accept={["csv", "xlsx"]}
          />
        </Stack>
      </Modal>
      {openEditModal ? (
        <EditOnHoldSample
          openServiceModal={openEditModal}
          setOpenServiceModal={setOpenEditModal}
          recordForEdit={originalData}
          setRefresh={setRefresh}
        />
      ) : null}
    </UIWrapper>
  );
};

export default OnHoldQueue;
