import React, { useState } from "react";
import RuleDialogWrapper from "../../../../components/ManifestBot/RuleDialogs/RuleDialogWrapper";
import { Box, Button, Divider, TextField } from "../../../../mui";

const AddRowDialog = ({ open, onClose, setRows }) => {
  const [newColHeader, setNewColHeader] = useState({ init: true, name: "" });

  const onSubmitHandler = () => {
    setRows((init) => [...init, {
        id: init.length,
        orig_name: newColHeader.name.match(/[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g).join("_").replace(/[^A-Z0-9]/ig, "_").toLowerCase(),
        client_name: newColHeader.name
    }]);
    onClose();
  };

  return (
    <RuleDialogWrapper
      open={open}
      onClose={onClose}
      title="Add a Client Header"
      directions={"Use the client's nomenclature here."}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          jusitfyContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          label="Client Header"
          onChange={(event) =>
            setNewColHeader({ init: false, name: event.target.value })
          }
          error={!newColHeader.init && newColHeader.name.length == 0}
          sx={{ mt: 2, mb: 2, minWidth: 200 }}
        />
        <Divider sx={{ width: "100%" }} />
        <Button
          sx={{ mt: 1 }}
          onClick={onSubmitHandler}
          disabled={newColHeader.init || newColHeader.name.length == 0}
        >
          Submit
        </Button>
      </Box>
    </RuleDialogWrapper>
  );
};

export default AddRowDialog;
