import React from "react";
import AptService from "../../services/AptService";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Tooltip from "@mui/material/Tooltip";
import InputLabel from "@mui/material/InputLabel";
import {
  Button,
  Paper,
  Box,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

const styles = {
  h1: {
    fontFamily: "Roboto",
    color: "rgba(0, 0, 0, 0.6)",
    paddingLeft: "25px",
    paddingTop: "25px",
    paddingBottom: "20px",
  },
};

const AptNewProjectsForm = (props) => {
  const [list, setlist] = React.useState(props.allClients[0].id);
  const [arrayVal, setArrayVal] = React.useState("");
  const [projNameVal, setProjNameVal] = React.useState("");
  const [addSucess, setAddSucess] = React.useState(false);
  const [addFail, setAddFail] = React.useState(false);

  const handleChange = (event) => {
    setlist(event.target.value);
  };

  const handleChangeArray = (e) => {
    setArrayVal(e.target.value);
  };
  const handleChangeProjectName = (e) => {
    setProjNameVal(e.target.value);
  };

  const handleSubmit = () => {
    AptService.postProjects(list, arrayVal, projNameVal)
      .then((resp) => {
        props.handleAddedProject(projNameVal);
        setAddSucess(true);
        setAddFail(false);
        setArrayVal("");
        setProjNameVal("");
      })
      .catch((err) => {
        setAddSucess(false);
        setAddFail(true);
      });
  };

  return (
    <Paper style={{ marginTop: "15px" }}>
      <Tooltip
        title="Go Back To Tables"
        onClick={() => props.handleChangeViewTable()}
      >
        <CancelPresentationIcon
          style={{
            size: "extralarge",
            color: "rgba(227, 27, 12, 1)",
            width: "2em",
            height: "1.5em",
            cursor: "pointer",
            float: "right",
          }}
        ></CancelPresentationIcon>
      </Tooltip>
      <Typography variant="h4" style={styles.h1}>
        Create New APT Project
      </Typography>
      {addSucess === true && (
        <Alert style={{ margin: "10px" }} severity="success">
          &quot;Successfully Added New Project — check it out!&quot;
        </Alert>
      )}
      {addFail === true && (
        <Alert style={{ margin: "10px" }} severity="error">
          &quot;Failed To Add New Project — check it out!&quot;
        </Alert>
      )}

      <ValidatorForm
        style={{ paddingLeft: "25px", paddingTop: "25px" }}
        onSubmit={handleSubmit}
      >
        <InputLabel id="demo-simple-select-label">Clients</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={list}
          onChange={handleChange}
          style={{ width: "400px" }}
        >
          {props.allClients.map((row, index) => (
            <MenuItem key={index} value={row.id}>
              {row.client}
            </MenuItem>
          ))}
        </Select>

        <TextValidator
          autoFocus
          margin="dense"
          label="Array ID"
          type="text"
          variant="standard"
          style={{ width: "400px" }}
          validators={["required"]}
          errorMessages={["this field is required"]}
          value={arrayVal}
          name="arrayVal"
          onChange={handleChangeArray}
        />
        <TextValidator
          autoFocus
          margin="dense"
          label="Project Name"
          type="text"
          variant="standard"
          validators={["required"]}
          errorMessages={["this field is required"]}
          style={{ width: "400px" }}
          value={projNameVal}
          name="projNameVal"
          onChange={handleChangeProjectName}
        />
        <Box style={{ padding: "25px", paddingLeft: "25px" }}>
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Box>
      </ValidatorForm>
    </Paper>
  );
};

export default AptNewProjectsForm;
