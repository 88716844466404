import React, { useEffect, useState } from "react";
import { Typography, Box, Modal, TextField, Button, Select, MenuItem } from "@mui/material";
import {
    Stack,
    Paper,
    Tooltip
} from "@mui/material";

import BiService from "../services/BiService";
import UIWrapper from "../components/UIWrapper";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useDispatch } from "react-redux";
import { snackbarActions } from "../Store/snackbar";
import LockResetIcon from '@mui/icons-material/LockReset';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import RefreshIcon from '@mui/icons-material/Refresh';
import RequestService from "../services/RequestService";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


const style = {
    position: "absolute",
    display: "flex",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 3,
};


const RequestTable = (props) => {
    const [requests, setRequests] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [selectedReq, setSelectedReq] = React.useState({});
    const [orgList, setOrgList] = React.useState([]);
    const [org, setOrg] = React.useState('');
    const [refreshTracker, setRefreshTracker] = React.useState(0);



    const dispatch = useDispatch();

    // const handleDownload = (file) => {
    //     BiService.getFile(file)
    //         .then((resp) => {
    //             console.log(resp.data)
    //             window.location.replace(resp.data)
    //             // setSetDownloads(resp.data)
    //             dispatch(
    //                 snackbarActions.showNotification({
    //                     snackbarOpen: true,
    //                     snackbarType: "success",
    //                     snackbarMessage: "Successful Download",
    //                 })
    //             );

    //         })
    //         .catch(err => {
    //             console.log(err)
    //             dispatch(
    //                 snackbarActions.showNotification({
    //                     snackbarOpen: true,
    //                     snackbarType: "error",
    //                     snackbarMessage: "Failed Download",
    //                 })
    //             );
    //         })
    // };

    const handleApproveForm = (row) => {
        setSelectedReq(row)
        RequestService.getOrgs()
            .then((resp) => {
                console.log(resp.data)
                setOrgList(resp.data)


            })
            .catch(err => {
                console.log(err)

            })

        setOpen(true)
    };

    const handleSelectedUser = (value,field) => {
        let obj = JSON.parse(JSON.stringify(selectedReq))
        obj[field] = value
        setSelectedReq(obj)
    };

    const handleDeny = (email) => {
        RequestService.denyAccess(email)
            .then((resp) => {
                console.log(resp)
                // setSetDownloads(resp.data)
                setRefreshTracker(refreshTracker + 1)
                dispatch(
                    snackbarActions.showNotification({
                        snackbarOpen: true,
                        snackbarType: "success",
                        snackbarMessage: 'Request Successful Denied',
                    })
                );


            })
            .catch(err => {
                console.log(err)
                setRefreshTracker(refreshTracker + 1)
                dispatch(
                    snackbarActions.showNotification({
                        snackbarOpen: true,
                        snackbarType: "error",
                        snackbarMessage: 'Request Denied Failure',
                    })
                );

            })
    };
    const handleApprove = () => {
        let finalData = selectedReq
        finalData["org"] = org
        RequestService.approveAccess(finalData)
            .then((resp) => {
                console.log(resp)
                setRefreshTracker(refreshTracker + 1)
                setOpen(false)
                dispatch(
                    snackbarActions.showNotification({
                        snackbarOpen: true,
                        snackbarType: "success",
                        snackbarMessage: 'Request Successful Approved',
                    })
                );
                setOrg('')

                // setSetDownloads(resp.data)

            })
            .catch(err => {
                console.log(err)
                setRefreshTracker(refreshTracker + 1)
                setOpen(false)
                dispatch(
                    snackbarActions.showNotification({
                        snackbarOpen: true,
                        snackbarType: "error",
                        snackbarMessage: 'Request Failed To Approve',
                    })
                );
                setOrg('')
            })
    };

    useEffect(() => {
        RequestService.getRequests()
            .then((resp) => {
                console.log(resp.data)
                setRequests(resp.data)

            })
            .catch(err => {
                console.log(err)
            })

    }, [refreshTracker]);

    useEffect(() => {
        RequestService.getRequests()
            .then((resp) => {
                console.log(resp.data)
                setRequests(resp.data)

            })
            .catch(err => {
                console.log(err)
            })

    }, []);

    return (
        <UIWrapper title="Request Management" paper>

            <Box style={{ padding: "20px" }}>
                <Stack direction="row">
                    <Typography
                        variant="h5"
                        color="secondary.main"
                        sx={{
                            marginBottom: '8px',

                        }}
                    >
                        Request Requests
                    </Typography>
                    <Tooltip
                        title="Refresh Requests"
                        style={{ cursor: "pointer" }}
                        onClick={() => setRefreshTracker(refreshTracker+1)}

                    >
                        <RefreshIcon color="primary" style={{ marginTop: "4px", marginLeft: '10px', cursor: "pointer" }}></RefreshIcon>
                    </Tooltip>
                </Stack>

                <TableContainer component={Paper} variant="outlined">
                    <Table size="small" aria-label="simple table">
                        <TableHead >
                            <TableRow>
                                <TableCell align="center">Created On</TableCell>
                                <TableCell align="center">First Name</TableCell>
                                <TableCell align="center">Last Name</TableCell>
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">Phone Number</TableCell>

                                <TableCell align="center">Org</TableCell>
                                <TableCell align="center">Supervisor</TableCell>
                                <TableCell align="center">Supervisor Email</TableCell>
                                <TableCell align="center">Reason</TableCell>
                                <TableCell align="center">Reviewed</TableCell>
                                <TableCell align="center">Response</TableCell>



                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                requests.map((row, index) => (

                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        
                                    >
                                        <TableCell align="center" component="td" scope="row">
                                            {row.created_on || 'null'}
                                        </TableCell>
                                        <TableCell align="center" component="td" scope="row">
                                            {row.first_name || 'null'}
                                        </TableCell>
                                        <TableCell align="center" component="td" scope="row">
                                            {row.last_name || 'null'}
                                        </TableCell>

                                        <TableCell align="center" component="td" scope="row">
                                            {row.email || 'null'}
                                        </TableCell>
                                        <TableCell align="center" component="td" scope="row">
                                            {row.phone_number || 'null'}
                                        </TableCell>

                                        <TableCell align="center" component="td" scope="row">
                                            {row.organization || 'null'}
                                        </TableCell>
                                        <TableCell align="center" component="td" scope="row">
                                            {row.supervisor || 'null'}
                                        </TableCell>
                                        <TableCell align="center" component="td" scope="row">
                                            {row.supervisor_email || 'null'}
                                        </TableCell>
                                        <TableCell align="center" component="td" scope="row">
                                            {
                                                <Tooltip
                                                    title={row.reason}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <Typography variant='body2'>
                                                        {row.reason.slice(0, 15) + "..."}
                                                    </Typography>
                                                </Tooltip>
                                            }
                                        </TableCell>
                                        <TableCell align="center" component="td" scope="row">
                                            {row.reviewed ? 'True' : 'False'}
                                        </TableCell>

                                        <TableCell align="center"
                                        // onClick={(e) =>
                                        //     handleDetailData(row)
                                        // }
                                        >
                                            {
                                                <>
                                                    <Tooltip
                                                        title="Approve Request"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => handleApproveForm(row)}
                                                    >
                                                        <CheckCircleIcon color="green"></CheckCircleIcon>
                                                    </Tooltip>

                                                    <Tooltip
                                                        title="Deny Request"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => handleDeny(row.email)}
                                                    >
                                                        <HighlightOffIcon color="red"></HighlightOffIcon>
                                                    </Tooltip>
                                                </>
                                            }

                                        </TableCell>
                                    </TableRow>

                                ))
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {
                selectedReq &&
                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Stack sx={style}>
                        <Typography
                            variant="h6"
                            color="secondary"
                        >
                            {"Select an Organization For: " + selectedReq.first_name}
                        </Typography>
                        <Select
                            labelId="filter-id"
                            value={org}
                            label="Organizations"
                            onChange={(event) => setOrg(event.target.value)}
                        // disabled={props.client ? false : true}

                        >
                            {orgList.map((ele, index) => (
                                <MenuItem key={index} value={ele.organization_id}>
                                    {ele.organization_name}
                                </MenuItem>
                            ))}
                        </Select>

                        <TextField
                            label={"Email"}
                            type="text"
                            style={{
                                textAlign: "center",
                                color: "white",
                                marginTop:"10px",
                                width: "100%",
                            }}
                            size="small"
                            value={selectedReq.email || ""}
                            onChange={(e) => handleSelectedUser(e.target.value,'email')}
                        />
                                <TextField
                            label={"First name"}
                            type="text"
                            style={{
                                textAlign: "center",
                                color: "white",
                                marginTop:"10px",
                                width: "100%",
                            }}
                            size="small"
                            value={selectedReq.first_name || ""}
                            onChange={(e) => handleSelectedUser(e.target.value,'first_name')}
                        />
                                <TextField
                            label={"Last Name"}
                            type="text"
                            style={{
                                textAlign: "center",
                                color: "white",
                                marginTop:"10px",
                                width: "100%",
                            }}
                            size="small"
                            value={selectedReq.last_name || ""}
                            onChange={(e) => handleSelectedUser(e.target.value,'last_name')}
                        />
                                  <TextField
                            label={"Phone Number"}
                            type="text"
                            style={{
                                textAlign: "center",
                                color: "white",
                                marginTop:"10px",
                                width: "100%",
                            }}
                            size="small"
                            value={selectedReq.phone_number || ""}
                            onChange={(e) => handleSelectedUser(e.target.value,'phone_number')}
                        />
                        <Button variant="contained" color='success' style={{ marginTop: "5px" }} onClick={() => handleApprove()}>
                            <SaveAltIcon
                                fontSize="medium"
                                style={{
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                }}
                            ></SaveAltIcon>
                            Approve
                        </Button>
                    </Stack>
                </Modal>

            }

        </UIWrapper>

    );
};

export default RequestTable;