import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddIcon,
  BoxSystem,
  Button,
  Divider,
  Fab,
  IconButton,
  RemoveCircleOutlineIcon,
  SwapHorizIcon,
  TextField,
  Typography
} from "../../../mui";
import { mapManifestActions } from "../../../Store/map-manifest";
import Conditions from "../Conditions";
import RuleDialogWrapper from "./RuleDialogWrapper";

const ColumnTitle = styled(Typography)(({ theme }) => ({
  "&": {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    background: "rgb(211,211,211,0.8)",
    minWidth: 150,
  },
}));

const SetSeparator = ({ setSeparator }) => {
  return (
    <BoxSystem sx={{ display: "flex", alignItems: "center", mb: 2, pr: 10 }}>
      <Typography sx={{ mr: 1 }}>Separator:</Typography>
      <Typography variant="h5">&quot;</Typography>
      <TextField
        size="small"
        onChange={(event) => setSeparator(event.target.value)}
        sx={{ maxWidth: 100 }}
      />
      <Typography variant="h5">&quot;</Typography>
    </BoxSystem>
  );
};

export const SetSlice = ({
  startSlice,
  endSlice,
  setStartSlice,
  setEndSlice,
  addSlicing,
  setAddSlicing,
  regex,
  setRegex,
}) => {
  const handleCancelSlice = () => {
    setStartSlice(0);
    setEndSlice(0);
    setAddSlicing(false);
  };
  return (
    <BoxSystem sx={{ display: "flex", alignItems: "center", mb: 2, mt: 1 }}>
      {addSlicing ? (
        <BoxSystem
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <BoxSystem sx={{ display: "flex", alignItems: "center" }}>
            <BoxSystem>
              <Typography
                sx={{ display: "flex", flex: 1, justifyContent: "center" }}
              >
                Begin
              </Typography>
              <TextField
                type="number"
                size="small"
                defaultValue={startSlice}
                onChange={(event) => setStartSlice(event.target.value)}
                sx={{ maxWidth: 75 }}
                error={startSlice >= endSlice && startSlice && endSlice}
              />
            </BoxSystem>
            <Typography mt={2.5} mr={1} ml={1}>
              :
            </Typography>
            <BoxSystem>
              <Typography
                sx={{ display: "flex", flex: 1, justifyContent: "center" }}
              >
                End
              </Typography>
              <TextField
                type="number"
                size="small"
                defaultValue={endSlice}
                sx={{ maxWidth: 75 }}
                onChange={(event) => setEndSlice(event.target.value)}
                error={startSlice >= endSlice && startSlice && endSlice}
              />
            </BoxSystem>
          </BoxSystem>
          <BoxSystem sx={{ display: "flex", alignItems: "center" }}>
            <Divider sx={{ width: "100%" }}>
              <Typography>or</Typography>
            </Divider>
            <IconButton
              color="yellow"
              sx={{ ml: 6 }}
              onClick={handleCancelSlice}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </BoxSystem>
          <TextField
            id="standard-adornment-weight"
            size="small"
            inputProps={{
              "aria-label": "weight",
            }}
            label="RegEx"
            onChange={(event) =>
              setRegex({ init: false, value: event.target.value })
            }
            error={!regex.init && regex.value.length == 0}
          />
        </BoxSystem>
      ) : (
        <Fab
          variant="extended"
          color="primary"
          size="small"
          sx={{ mt: 1 }}
          onClick={() => setAddSlicing(true)}
        >
          <AddIcon sx={{ mr: 1 }} />
          Slicing&nbsp;
        </Fab>
      )}
    </BoxSystem>
  );
};

const JoinDialog = ({ open, onClose }) => {
  const rules = useSelector((state) => state.mapManifest.rules);
  const selected = useSelector((state) => state.mapManifest.selected);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [separator, setSeparator] = useState("");
  const [startSlice, setStartSlice] = useState("");
  const [endSlice, setEndSlice] = useState("");
  const [addSlicing, setAddSlicing] = useState(false);
  const [regex, setRegex] = useState({ init: true, value: "" });
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      dispatch(mapManifestActions.initRule({ action: "join" }));
    }
    return;
  }, [open]);

  useEffect(() => {
    if (JSON.stringify(selected) !== "{}") {
      const filtered = Object.entries(selected).filter((obj) => obj[1]);
      if (filtered.length == 2) {
        console.log(filtered);
        setOrigin(filtered[0][0]);
        setDestination(filtered[1][0]);
      }
    }
    return;
  }, [selected]);

  const resetState = () => {
    setSeparator("");
    setStartSlice("");
    setEndSlice("");
    setAddSlicing(false);
    setRegex({ init: true, value: "" });
  };

  const onCloseHandler = () => {
    dispatch(mapManifestActions.deleteRule());
    onClose();
    resetState();
  };

  const swapHandler = () => {
    const temp_orig = origin;
    setOrigin(destination);
    setDestination(temp_orig);
  };

  const onSubmitHandler = () => {
    dispatch(
      mapManifestActions.join({
        origin,
        destination,
        startSlice,
        endSlice,
        separator,
        regex: regex.value,
      })
    );
    resetState();
    onClose();
  };

  return (
    <RuleDialogWrapper
      open={open}
      onClose={onCloseHandler}
      title="Join Two Columns"
      directions={
        "Join data from one column to another. Slice by index to get a substring from the Origin Column or use Regex"
      }
    >
      <BoxSystem
        sx={{
          display: "flex",
          flexDirection: "column",
          jusitfyContent: "center",
          alignItems: "center",
        }}
      >
        <BoxSystem sx={{ display: "flex", width: "100%", mt: 1 }}>
          <Typography
            sx={{ display: "flex", flex: 1, justifyContent: "center" }}
          >
            Origin
          </Typography>
          <Typography
            sx={{ display: "flex", flex: 1, justifyContent: "center" }}
          >
            Destination
          </Typography>
        </BoxSystem>
        <BoxSystem sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <ColumnTitle p={1} sx={{ borderRadius: 1 }}>
            {destination}
          </ColumnTitle>
          <IconButton color="primary" sx={{ m: 1 }} onClick={swapHandler}>
            <SwapHorizIcon />
          </IconButton>
          <ColumnTitle p={1} sx={{ borderRadius: 1 }}>
            {origin}
          </ColumnTitle>
        </BoxSystem>
        <SetSeparator setSeparator={setSeparator} />
        <Divider sx={{ width: "100%" }} />
        <SetSlice
          startSlice={startSlice}
          endSlice={endSlice}
          setStartSlice={setStartSlice}
          setEndSlice={setEndSlice}
          addSlicing={addSlicing}
          setAddSlicing={setAddSlicing}
          regex={regex}
          setRegex={setRegex}
        />
        <Divider sx={{ width: "100%" }} />
        <Conditions />
        <Divider sx={{ width: "100%" }} />
        <Button
          sx={{ mt: 1 }}
          onClick={onSubmitHandler}
          disabled={
            (addSlicing &&
              ((startSlice === "" && endSlice == "" && regex.value === "") ||
                ((startSlice !== "" || endSlice !== "") &&
                  startSlice >= endSlice) ||
                ((startSlice !== "" || endSlice !== "") &&
                  regex.value !== ""))) ||
            (rules.length &&
              rules[rules.length - 1]["rule"]["condition"].flat().includes(""))
          }
        >
          Submit
        </Button>
      </BoxSystem>
    </RuleDialogWrapper>
  );
};

export default JoinDialog;
