import { useEffect, useState, useMemo } from "react";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  useGridApiContext,
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  gridClasses,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import ProjectManagementService from "../../services/ProjectManagementService";
import { snackbarActions } from "../../Store/snackbar";
import { alpha, styled } from "@mui/material/styles";

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeadersInner": {
    backgroundColor:
      theme.palette.mode === "light" ? theme.palette.turquoise.main : "#044b1d",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
  },
  "& .MuiDataGrid-cell.MuiDataGrid-cell--editing, & .MuiDataGrid-row--editing .actions":
    {
      backgroundColor:
        theme.palette.mode === "light" ? "#fb9797" : "rgb(217 51 51 / 8%)",
    },

  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.green.light,
  },
  "& .MuiDataGrid-row": {
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));

function EditToolbar(props) {
  const { setRows, setRowModesModel, initialValue, tableName } = props;
  const apiRef = useGridApiContext();

  const handleClick = () => {
    apiRef.current.setPage(0);
    apiRef.current.scrollToIndexes({
      rowIndex: 0,
      colIndex: 0,
    });
    let id = Date.now();
    setRows((oldRows) => {
      return [{ ...initialValue, id, isNew: true }, ...oldRows];
    });
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "client" },
    }));
  };

  return (
    <GridToolbarContainer
      sx={{
        margin: 0,
        padding: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          margin: 0,
          padding: 0,
        }}
      >
        <Button color="secondary" variant="contained">
          {tableName}
        </Button>

        <Button
          color="info"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleClick}
        >
          Add record
        </Button>
      </Box>
    </GridToolbarContainer>
  );
}

const ClientsTable = ({
  tableName,
  saveUrl,
  createUrl,
  getUrl,
  isRuidTable,
  staticColumns,
  initialValue,
}) => {
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleRowEditStart = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut)
      event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow, oldRow) => {
    if (newRow?.client?.trim() === "") {
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Client name can't be blank.",
        })
      );
      if (newRow?.isNew) setRows(rows.filter((row) => row.id !== newRow.id));
      return oldRow;
    }
    let updatedRow = { ...oldRow };
    if (newRow?.isNew) {
      await ProjectManagementService.createOnboardingDetails(createUrl, newRow)
        .then((resp) => {
          updatedRow = {
            ...newRow,
            isNew: false,
            id: resp?.data?.id || newRow.id,
          };
          setRows((prev) => [
            updatedRow,
            ...prev.filter((row) => row.id !== newRow.id),
          ]);
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "success",
              snackbarMessage: `Successfully created a new entry for the client: ${resp?.data?.client}`,
            })
          );
        })
        .catch((err) => {
          setRows(rows.filter((row) => row.id !== newRow.id));
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage:
                typeof err?.response?.data?.detail === "string"
                  ? err.response.data.detail
                  : "Something went wrong.",
            })
          );
        });
    } else {
      await ProjectManagementService.updateOnboardingDetails(saveUrl, newRow)
        .then((resp) => {
          updatedRow = { ...newRow, isNew: false };
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "success",
              snackbarMessage: `Successfully updated the details for client: ${resp?.data?.client}`,
            })
          );
        })
        .catch((err) => {
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage:
                typeof err?.response?.data?.detail === "string"
                  ? err.response.data.detail
                  : "Something went wrong.",
            })
          );
        });
    }
    return updatedRow;
  };

  const actionColumn = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 80,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              key={1}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              key={2}
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            key={1}
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const columns = useMemo(() => {
    return [...staticColumns, ...actionColumn];
  }, [staticColumns, actionColumn]);

  useEffect(() => {
    setLoading(true);
    ProjectManagementService.getOnboardingDetails(getUrl)
      .then((resp) => {
        setRows(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <StripedDataGrid
      rows={rows}
      columns={columns}
      loading={loading}
      editMode="row"
      density="compact"
      rowModesModel={rowModesModel}
      onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
      onRowEditStart={handleRowEditStart}
      onRowEditStop={handleRowEditStop}
      disableSelectionOnClick
      processRowUpdate={processRowUpdate}
      isCellEditable={(params) =>
        isRuidTable
          ? true
          : params.field !== "client"
          ? true
          : params.row?.isNew
          ? true
          : false
      }
      components={{
        Toolbar: EditToolbar,
      }}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
      }
      componentsProps={{
        toolbar: {
          setRows,
          setRowModesModel,
          initialValue,
          tableName,
        },
      }}
      experimentalFeatures={{ newEditingApi: true }}
    />
  );
};

export default ClientsTable;
