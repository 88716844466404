import { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import AcsService from "../../services/AcsService";
import { Typography, Box } from "../../mui";
import { snackbarActions } from "../../Store/snackbar";
import { useDispatch } from "react-redux";

function CustomToolbar() {
  const [fileName, setFileName] = useState("");
  const generateFileName = () => {
    let currentTimestamp = Date.now();
    let date = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(currentTimestamp);
    date = date.replace(/[^a-zA-Z0-9- ]/g, "_");
    date = date.replace(/\s/g, "");
    date = date.slice(0, -2);
    setFileName("on_hold_sample_export_" + date + ".csv");
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Box onClick={generateFileName}>
        <GridToolbarExport
          csvOptions={{
            fileName: fileName,
          }}
        />
      </Box>
    </GridToolbarContainer>
  );
}

const onHoldColumns = [
  {
    field: "client",
    headerName: "Client",
    minWidth: 100,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "project",
    headerName: "Project",
    minWidth: 150,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "inventory_code",
    headerName: "Inventory Code",
    minWidth: 180,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "tracking_number",
    headerName: "Tracking Number",
    minWidth: 180,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "carrier",
    headerName: "Carrier",
    minWidth: 100,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "storage_location",
    headerName: "Storage Location",
    minWidth: 150,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value && params.value !== 0) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "temperature",
    headerName: "Temperature",
    minWidth: 100,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value && params.value !== 0) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "tube_type",
    headerName: "Tube Type",
    minWidth: 100,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value && params.value !== 0) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "date_disposition",
    headerName: "Date Disposition",
    minWidth: 150,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "date_received",
    headerName: "Date Received",
    minWidth: 150,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "created_on",
    headerName: "Created On",
    minWidth: 180,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "created_by",
    headerName: "Created By",
    minWidth: 200,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "final_disposition",
    headerName: "Final Disposition",
    minWidth: 100,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value && params.value !== 0) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "updated_by",
    headerName: "Updated By",
    minWidth: 200,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
];

const OnHoldSearch = () => {
  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setDataLoading(true);

    const getSample = async () => {
      await AcsService.getOnHoldSamples()
        .then((resp) => setTableData(resp.data))
        .catch((err) => {
          console.log(err);
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage:
                typeof err?.response?.data?.detail === "string"
                  ? err.response.data.detail
                  : "Something went wrong.",
            })
          );
        });
      setDataLoading(false);
    };
    getSample();

    return;
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Typography sx={{ mb: 1, fontSize: 15, fontWeight: "800" }}>
          Total Number of On-Hold Samples: {tableData.length}
        </Typography>
        <Box
          height="375px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <DataGrid
            rows={tableData}
            columns={onHoldColumns}
            loading={dataLoading}
            density="compact"
            // checkboxSelection
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
            }}
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default OnHoldSearch;
