import { Alert, Slide, Snackbar } from "../../mui";

import { useDispatch, useSelector } from "react-redux";
import { snackbarActions } from "../../Store/snackbar";

const CustomSnackbar = (props) => {
  const dispatch = useDispatch();
  const snackbarOpen = useSelector((state) => state.snackbar.snackbarOpen);
  const snackbarType = useSelector((state) => state.snackbar.snackbarType);
  const snackbarMessage = useSelector(
    (state) => state.snackbar.snackbarMessage
  );

  function SlideTransition(props) {
    return <Slide {...props} direction="right" timeout={500} />;
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      snackbarActions.showNotification({
        snackbarOpen: false,
        snackbarType: "success",
        snackbarMessage: "",
      })
    );
  };

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={null}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      TransitionComponent={SlideTransition}
      key={"vertical" + "horizontal"}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        color={snackbarType}
        sx={{ fontSize: 14 }}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
