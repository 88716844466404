import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { styled, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Divider,
  ExitToAppIcon,
  HomeIcon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MuiDrawer,
} from "../../mui";
import ServiceSendToLIMS from "../../pages/ServiceSendToLIMS";
import BiService from "../../services/BiService";
import { logoutUser } from "../../Store/auth-actions";
import ServiceIcon from "./ServiceList/ServiceIcon";
const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: 0,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: 0,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerWrapper = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Drawer = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const path = useSelector((state) => state.navigation.path);
  const { open, setOpen } = props;
  const [sideBarOptions, setSideBarOptions] = useState([]);
  const [adminView, setAdminView] = useState(false);
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const location = useLocation();

  const handleClose = (view) => {
    setOpen(false);
    props.handleExpanded();
  };

  useEffect(() => {
    if (path != "/home" && path.length) {
      BiService.getSidebar(path)
        .then((resp) => {
          setSideBarOptions(
            resp.data.sort((a, b) => {
              if (a.subservice_name < b.subservice_name) {
                return -1;
              }
              if (a.subservice_name > b.subservice_name) {
                return 1;
              }
              return 0;
            })
          );
          return resp.data;
        })
        .then((data) => {
          BiService.checkAccess(data[0].service_id).then((resp) => {
            if (resp.data.role_name == "ADMIN") {
              setAdminView(true);
            } else {
              setAdminView(false);
            }
          });
        })
        .catch((err) => {});
    } else {
      setSideBarOptions([]);
      setAdminView(false);
    }
  }, [path]);

  const handleOpenModal = () => {
    setOpenServiceModal(true);
  };
  return (
    isLoggedIn && (
      <>
        {" "}
        <DrawerWrapper variant="permanent" open={open}>
          <DrawerHeader style={{ border: "none" }} sx={{ mt: -1, mb: -1 }}>
            <IconButton
              onClick={() => handleClose()}
              style={{ color: "secondary" }}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>

          <List>
            <Link
              to="/home"
              style={{
                textDecoration: "none",
                color: theme.palette.secondary.main,
              }}
            >
              <ListItem
                button
                selected={path === "/home"}
                sx={{
                  "&.Mui-selected": {
                    bgcolor: theme.palette.nav.main,
                    "&:hover": {
                      bgcolor: theme.palette.nav.main,
                    },
                  },
                  "&:hover": {
                    bgcolor: theme.palette.nav.secondary,
                  },
                }}
              >
                <ListItemIcon
                  style={{ color: "secondary", paddingLeft: "2px" }}
                >
                  <HomeIcon></HomeIcon>
                </ListItemIcon>
                <ListItemText primary={"Home"} sx={{ ml: -1 }} />
              </ListItem>
            </Link>
            {sideBarOptions &&
              sideBarOptions.map((option) => (
                <Link
                  onClick={
                    option.subservice_route === "modal" ? handleOpenModal : null
                  }
                  key={option.subservice_name}
                  to={
                    option.subservice_route !== "modal"
                      ? option.subservice_route
                      : path
                  }
                  style={{
                    textDecoration: "none",
                    color: theme.palette.secondary.main,
                  }}
                >
                  <ListItem
                    button
                    selected={
                      openServiceModal
                        ? openServiceModal && option.subservice_route == "modal"
                        : location.pathname === option.subservice_route
                    }
                    sx={{
                      "&.Mui-selected": {
                        bgcolor: theme.palette.nav.main,
                        "&:hover": {
                          bgcolor: theme.palette.nav.main,
                        },
                      },
                      "&:hover": {
                        bgcolor: theme.palette.nav.secondary,
                      },
                    }}
                    key={option.subservice_name}
                  >
                    <ListItemIcon
                      style={{
                        color: theme.palette.secondary,
                        paddingLeft: "2px",
                      }}
                    >
                      <ServiceIcon iconName={option.icon} color="secondary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={option.subservice_name}
                      sx={{ ml: -1 }}
                    />
                  </ListItem>
                </Link>
              ))}
            {adminView && path != "/admin" && (
              <Link
                to={`${path}/users`}
                style={{
                  textDecoration: "none",
                  color: theme.palette.secondary.main,
                }}
              >
                <ListItem
                  button
                  key={"Home"}
                  selected={location.pathname === `${path}/users`}
                  sx={{
                    "&.Mui-selected": {
                      bgcolor: theme.palette.nav.main,
                      "&:hover": {
                        bgcolor: theme.palette.nav.main,
                      },
                    },
                    "&:hover": {
                      bgcolor: theme.palette.nav.secondary,
                    },
                  }}
                >
                  <ListItemIcon
                    style={{ color: "secondary", paddingLeft: "2px" }}
                  >
                    <SupervisedUserCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Users"} sx={{ ml: -1 }} />
                </ListItem>
              </Link>
            )}
          </List>
          <Divider />
          <List>
            <ListItem
              // selected={true}
              sx={{
                "&:hover": {
                  bgcolor: theme.palette.nav.secondary,
                },
              }}
              button
              key={"Logout"}
              style={{ textDecoration: "none", color: theme.palette.secondary }}
              onClick={() => dispatch(logoutUser())}
            >
              <ListItemIcon style={{ color: "secondary", paddingLeft: "2px" }}>
                <ExitToAppIcon></ExitToAppIcon>
              </ListItemIcon>
              <ListItemText primary={"Logout"} sx={{ ml: -1 }} />
            </ListItem>
          </List>
        </DrawerWrapper>
        {openServiceModal ? (
          <ServiceSendToLIMS
            openServiceModal={openServiceModal}
            setOpenServiceModal={setOpenServiceModal}
          />
        ) : null}
      </>
    )
  );
};

export default Drawer;
