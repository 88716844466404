import { BoxSystem } from "../../mui";
import React from "react";
import PreviewTable from "./PreviewTable";
import RuleDialogWrapper from "./RuleDialogs/RuleDialogWrapper";

const PreviewTableDialog = ({ open, onClose, onNext, previewData }) => {
  const onCloseHandler = () => {
    onClose();
  };

  return (
    <RuleDialogWrapper
      open={open}
      onClose={onCloseHandler}
      title="Preview"
      directions={
        "Here's how your rules change your input data. Add more rules or click next to save this rule profile!"
      }
    >
      <BoxSystem
        sx={{
          display: "flex",
          flexDirection: "column",
          jusitfyContent: "center",
          alignItems: "center",
        }}
      >
        {previewData.length && <PreviewTable content={previewData} />}
      </BoxSystem>
    </RuleDialogWrapper>
  );
};

export default PreviewTableDialog;
