import { useState } from "react";
import UIWrapper from "../../../components/UIWrapper";
import { Divider, Tab, Tabs } from "../../../mui";
import AF2Active from "./AF2Active";
import AF2History from "./AF2History";

const DataUpdation = () => {
  const [value, setValue] = useState("one");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <UIWrapper title="Data Updation" paper>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="AF2 Data Updation"
      >
        <Tab value="one" label="Active AF2" />
        <Tab value="two" label="AF2 History" />
      </Tabs>
      <Divider style={{ width: "100%" }}></Divider>
      {value === "one" && <AF2Active />}
      {value === "two" && <AF2History />}
    </UIWrapper>
  );
};

export default DataUpdation;
