import ClearIcon from '@mui/icons-material/Clear';
import { styled } from "@mui/material/styles";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  Box, Card,
  CardHeader, Collapse, Divider, DragIndicatorTwoToneIcon,
  ExpandMoreIcon, IconButton,
  Typography
} from "../../mui";
import { mapManifestActions } from "../../Store/map-manifest";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const formatCondition = (condition) => {
  return condition.map(
    (subObj) => `"${subObj[0]}" ${subObj[1]} "${subObj[2]}"`
  );
};

const formatRule = (ruleType, obj) => {
  if (ruleType == "value_mapping") {
    return obj.map((subObj) => (
      <>
        {subObj.join(" : ") + ","}
        <br></br>
      </>
    ));
  } else if (ruleType == "slice") {
    if (obj.regex) {
      return <>{"Regex : " + obj.regex}</>;
    }
    return (
      <>
        {"Start Slice : " + obj.startSlice}
        <br></br>
        {"End Slice : " + obj.endSlice}
      </>
    );
  } else if (ruleType == "append") {
    if (obj[0]) {
      if (obj[0][0] && obj[0][1]) {
        return (
          <>
            {"Prepend : " + obj[0][0]}
            <br></br>
            {"Append : " + obj[0][1]}
          </>
        );
      } else if (obj[0][0]) {
        return <>{"Prepend : " + obj[0][0]}</>;
      }
      return <>{"Append : " + obj[0][1]}</>;
    }
  } else if (ruleType == "join") {
    if (obj.startSlice) {
      return (
        <>
          {`Join with ${obj.target}`}
          <br></br>
          {`Separated by "${obj.separator}"`}
          <br></br>
          {`From Slice ${obj.startSlice} to ${obj.endSlice}`}
        </>
      );
    }
    return (
      <>
        {`Join with ${obj.target}`}
        <br></br>
        {`Separated by "${obj.separator}"`}
      </>
    );
  }
};

const Rule = ({ rule, index }) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Draggable draggableId={rule.id} index={index}>
      {(provided) => (
        <Card
          sx={{
            // display: "flex",
            // alignItems: "center",
            background: "rgb(211,211,211,0.3)",
            borderRadius: 1,
            mt: 1,
            mr: 2,
            ml: 2,
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <CardHeader
            sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}
            titleTypographyProps={{ color: "primary.main", fontSize: 15 }}
            action={
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            }
            title={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={() => dispatch(mapManifestActions.deleteSpecificRule(index))}><ClearIcon color="error" sx={{fontSize: 20, m: 0, p: 0}}/></IconButton>
                <DragIndicatorTwoToneIcon sx={{ mr: 1 }} />
                {`${rule.column} : (${rule.rule.action})`}
              </Box>
            }
            // subheader={rule.rule.action}
          >
            <Typography sx={{ flex: 7 }}>{rule.column}</Typography>
            <Typography sx={{ flex: 7 }}>{rule.rule.action}</Typography>
          </CardHeader>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                  {rule.rule.action}
                </Typography>
                <Typography paragraph sx={{ ml: 2 }}>
                  {formatRule(rule.rule.action, rule.rule["do"])}
                </Typography>
              </Box>
              <Divider orientation="vertical" sx={{ height: "auto", m: 2 }} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                  Conditions
                </Typography>
                {formatCondition(rule.rule["condition"]).map((obj, index) => (
                  <Typography key={index}>{obj}</Typography>
                ))}
              </Box>
            </Box>
          </Collapse>
        </Card>
      )}
    </Draggable>
  );
};

const RuleList = React.memo(({ rules }) => {
  return rules.map((rule, index) => (
    <Rule rule={rule} index={index} key={rule.id} />
  ));
});

const Rules = ({ columns }) => {
  const rules = useSelector((state) => state.mapManifest.rules);
  const dispatch = useDispatch();

  const onDragEnd = (result) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    dispatch(
      mapManifestActions.shiftRule({
        startIndex: result.source.index,
        endIndex: result.destination.index,
      })
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <Box
            sx={{ paddingBottom: 2 }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <RuleList rules={rules} />
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Rules;
