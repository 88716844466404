import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";

import { Divider, Paper, Grid, Box, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled } from "@mui/material/styles";
import DocCard from "./DocCard";
import Container from "@mui/material/Container";
import DropDownCardQuality from "./DropDownCardQuality";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DropDownCard = (props) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  // console.log("rw", props.data);
  // console.log("rw1", props.header === "quality_control");

  return (
    <Stack direction="column">
      <Stack
        direction="row"
        onClick={() => setIsExpanded(!isExpanded)}
        style={{ cursor: "pointer" }}
      >
        {isExpanded ? (
          <ExpandMoreIcon
            fontSize="large"
            style={{ color: "#9b51e0" }}
          ></ExpandMoreIcon>
        ) : (
          <ChevronRightIcon
            fontSize="large"
            style={{ color: "#9b51e0" }}
          ></ChevronRightIcon>
        )}
        <Typography
          align="left"
          variant="h6"
          style={{ fontWeight: "bold", color: "#484D56" }}
        >
          {props.header.charAt(0).toUpperCase() +
            props.header.slice(1).replace(/[^a-zA-Z0-9- ]/g, " ")}
        </Typography>
      </Stack>
      {isExpanded && (
        <Box sx={{ width: "100%" }}>
          <Divider style={{ width: "100%", marginBottom: "10px" }}></Divider>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {props.header !== "quality_control" ? (
              Object.keys(props.data).map((row, index) => (
                <>
                  {props.data[row] && typeof props.data[row] !== "object" && (
                    <Grid
                      style={{
                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                      item
                      xs={6}
                      key={index}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold", color: "#484D56" }}
                      >
                        {row
                          .replace(/[^a-zA-Z0-9- ]/g, " ")
                          .split(" ")
                          .map(
                            (word, index) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </Typography>
                      {props.data[row] ? (
                        <Typography
                          variant="subtitle2"
                          style={{ fontWeight: "bold", color: "#9b51e0" }}
                        >
                          {props.data[row]}
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle2"
                          style={{ fontWeight: "bold", color: "#9b51e0" }}
                        >
                          -
                        </Typography>
                      )}
                    </Grid>
                  )}
                </>
              ))
            ) : (
              <DropDownCardQuality
                header={props.header}
                data={props.data}
                // key={index}
                isExpanded={false}
              ></DropDownCardQuality>
            )}
            {props.header === "documents" && (
              <DocCard files={props.data.files}></DocCard>
            )}
          </Grid>
        </Box>
      )}
    </Stack>
  );
};

DropDownCard.propTypes = {
  header: PropTypes.string,
  data: PropTypes.object,
};

export default DropDownCard;
