import moment from "moment-timezone";

const getAllTimeZones = () => {
  const timeZones = moment.tz.names();
  const timeZoneList = [];
  timeZones.forEach((timezone) => {
    const timeZoneObject = moment.tz.zone(timezone);
    timeZoneList.push(`${timeZoneObject.abbr(new Date())} - ${timezone}`);
  });
  return timeZoneList;
};

export const timeZonesList = getAllTimeZones();
const userTzName = moment.tz.guess();
const userTzAbbr = moment.tz.zone(userTzName).abbr(new Date());
export const userTimeZone = `${userTzAbbr} - ${userTzName}`;
