import React from "react";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import ParentWrapper from "../../components/ParentWrapper";
import PrivateRoute from "../../components/PrivateRoute";
import ServiceCreateBulletin from "../ServiceCreateBulletin";

const ParentBulletin = (props) => {
  let { path } = useRouteMatch();
  return (
    <ParentWrapper path={path}>
      <PrivateRoute exact path={`${path}/editor`}>
        <ServiceCreateBulletin />
      </PrivateRoute>
      <Route>
        <Redirect to={`${path}/editor`} />
      </Route>
    </ParentWrapper>
  );
};

export default ParentBulletin;
