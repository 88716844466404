import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import UserSelectedServiceManager from "../../../src/pages/UserSelectedServiceManager";
import ParentWrapper from "../../components/ParentWrapper";
import PrivateRoute from "../../components/PrivateRoute";
import AcsForm from "../AcsForm";
import ManifestIngestTool from "../Manifest/ManifestIngestTool";
import UserAppManager from "../../../src/pages/UserAppManager";

const queryClient = new QueryClient();

const ParentAccession = (props) => {
  const { path } = useRouteMatch();
  return (
    <QueryClientProvider client={queryClient}>
      <ParentWrapper path={path}>
        <PrivateRoute exact path={`${path}/manifest/ingest`}>
          <ManifestIngestTool />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}/form`}>
          <AcsForm />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}/users`}>
          <UserAppManager service={`${path}`} />
        </PrivateRoute>
        <Route>
          <Redirect to={`${path}/manifest/ingest`} />
        </Route>
      </ParentWrapper>
    </QueryClientProvider>
  );
};

export default ParentAccession;
