import {
  AddIcon,
  ArrowRightAltIcon,
  RemoveCircleOutlineIcon,
  Button,
  Divider,
  IconButton,
  TextField,
  Fab,
  Box,
} from "../../../mui";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mapManifestActions } from "../../../Store/map-manifest";
import Conditions from "../Conditions";
import RuleDialogWrapper from "./RuleDialogWrapper";

const MapInput = ({ index }) => {
  const dispatch = useDispatch();
  const [key, setKey] = useState({ init: true, key: "" });
  const [value, setValue] = useState({ init: true, value: "" });

  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(mapManifestActions.keyMap({ index, value: key.key })),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [key]);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(mapManifestActions.valueMap({ index, value: value.value })),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [value]);

  return (
    <Box display="flex" alignItems="center" m={1}>
      <TextField
        id="standard-adornment-weight"
        size="small"
        inputProps={{
          "aria-label": "weight",
        }}
        label="Key"
        onChange={(event) => setKey({ init: false, key: event.target.value })}
        error={!key.init && key.key.length == 0}
      />
      <ArrowRightAltIcon />
      <TextField
        id="standard-adornment-weight"
        size="small"
        inputProps={{
          "aria-label": "weight",
        }}
        label="Value"
        onChange={(event) =>
          setValue({ init: false, value: event.target.value })
        }
        error={!value.init && value.value.length == 0}
      />
      <IconButton
        color="yellow"
        onClick={() => dispatch(mapManifestActions.deleteValueMap(index))}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </Box>
  );
};

const ValueMapDialog = ({ open, onClose }) => {
  const rules = useSelector((state) => state.mapManifest.rules);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      dispatch(mapManifestActions.initRule({ action: "value_mapping" }));
    }
    return;
  }, [open]);

  const onCloseHandler = () => {
    dispatch(mapManifestActions.deleteRule());
    onClose();
  };

  const onSubmitHandler = () => {
    if (rules[rules.length - 1]["rule"]["do"].length) onClose();
  };

  return (
    <RuleDialogWrapper
      open={open}
      onClose={onCloseHandler}
      title="Value Mapping"
      directions={
        'Map Values based on a match in a given column. Example: map the value "6 milliliter tube" to "6ml tube"'
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          jusitfyContent: "center",
          alignItems: "center",
        }}
      >
        {rules.length > 0 &&
          Array.isArray(rules[rules.length - 1]["rule"]["do"]) &&
          rules[rules.length - 1]["rule"]["do"].map((key, index) => (
            <MapInput index={index} key={index} />
          ))}
        <Fab
          variant="extended"
          color="primary"
          aria-label="add"
          size="small"
          sx={{ mt: 2, mb: 2 }}
          onClick={() => dispatch(mapManifestActions.addValueMap())}
        >
          <AddIcon sx={{ mr: 1 }} />
          Mapping&nbsp;
        </Fab>
        <Divider sx={{ width: "100%" }} />
        <Conditions />
        <Divider sx={{ width: "100%" }} />
        <Button
          sx={{ mt: 1 }}
          onClick={onSubmitHandler}
          disabled={
            rules.length > 0 &&
            (!rules[rules.length - 1]["rule"]["do"].length ||
              rules[rules.length - 1]["rule"]["do"].flat().includes("") ||
              rules[rules.length - 1]["rule"]["condition"].flat().includes(""))
          }
        >
          Submit
        </Button>
      </Box>
    </RuleDialogWrapper>
  );
};

export default ValueMapDialog;
