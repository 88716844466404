import { useEffect, useState, useRef } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  CancelIcon,
} from "../../mui";

import { Grid } from "@mui/material";
import ManagementService from "../../services/ManagementService";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../../Store/snackbar";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { isEqual } from "lodash";

const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const tableRowData = [
  "Tracking Number",
  "Client",
  "Number of Samples",
  "Created By",
];

const DeleteManifest = (props) => {
  const dispatch = useDispatch();
  const [projectList, setProjectList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [trackingNumberList, setTrackingNumberList] = useState([]);
  const [client, setClient] = useState("");
  const [project, setProject] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [tableData, setTableData] = useState([]);
  const [pageLarge, setPageLarge] = useState(0);
  const [rowsPerPageLarge, setRowsPerPageLarge] = useState(10);
  const [dataLoading, setDataLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [subTitleText, setSubTitleText] = useState("");

  const handleChangePageLarge = (event, newPage) => {
    setPageLarge(newPage);
  };

  const handleChangeRowsPerPageLarge = (event) => {
    setRowsPerPageLarge(+event.target.value);
    setPageLarge(0);
  };

  const addToList = async () => {
    await ManagementService.getManifestTrackingNumbers(
      client,
      project,
      trackingNumber
    )
      .then((resp) => {
        if (resp.data.length !== 0)
          resp.data.forEach((element) => {
            if (tableData.filter((v) => isEqual(v, element)).length === 0)
              setTableData((prev) => [...prev, element]);
          });
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
      });

    setTrackingNumber("");
  };

  const handleRemoveCell = (row) => {
    setTableData((prev) =>
      prev.filter(
        (item) =>
          row.tracking_number !== item.tracking_number ||
          row.created_by !== item.created_by
      )
    );
  };
  const handleShowConfirmation = () => {
    setConfirmation(true);
    const warningText = tableData
      .map((item) => item.tracking_number)
      .join(", ");
    setSubTitleText(warningText);
  };

  const submitData = async () => {
    setDataLoading(true);
    await ManagementService.deleteAccessionManifest(tableData)
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );
        clearForm();
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
      });
    setDataLoading(false);
  };
  const clearForm = () => {
    props.setOpenServiceModal(false);
    props.setRefresh((prev) => !prev);
    setTableData([]);
  };

  const handleClose = () => {
    props.setOpenServiceModal(false);
    setTableData([]);
  };

  useEffect(() => {
    setTrackingNumber("");
    if (client && project) {
      ManagementService.getTrackingNumbers(client, project)
        .then((resp) => {
          setTrackingNumberList(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [client, project]);

  useEffect(() => {
    setProject("");
    if (client) {
      ManagementService.getClientProjects(client)
        .then((resp) => {
          setProjectList(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [client]);

  useEffect(() => {
    ManagementService.getAllClients(client)
      .then((resp) => {
        setClientList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Modal
      open={props.openServiceModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style}>
        <Typography
          variant="h4"
          style={{
            fontFamily: "Roboto",
            fontWeight: "500",
            lineHeight: "116.7%",
            letterSpacing: "-1.5px",
          }}
          color="secondary"
        >
          Select Tracking Numbers
        </Typography>
        <Divider style={{ width: "100%" }}></Divider>
        <Stack direction="row" style={{ width: "100%" }}>
          <Autocomplete
            id="tags-standard"
            freeSolo
            inputValue={client || ""}
            options={clientList}
            onInputChange={(e, newValue) => setClient(newValue)}
            getOptionLabel={(option) =>
              typeof option === "string" || option instanceof String
                ? option
                : ""
            }
            style={{ width: "30%", margin: "15px 5px 5px 5px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                required={true}
                id="standard-multiline-flexible"
                size="small"
                type="text"
                name={"Client"}
                label={"Client"}
              />
            )}
          />
          <Autocomplete
            id="tags-standard"
            freeSolo
            inputValue={project || ""}
            disabled={client ? false : true}
            options={projectList}
            onInputChange={(e, newValue) => setProject(newValue)}
            getOptionLabel={(option) =>
              typeof option === "string" || option instanceof String
                ? option
                : ""
            }
            style={{ width: "30%", margin: "15px 5px 5px 5px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                required={true}
                id="standard-multiline-flexible"
                size="small"
                type="text"
                name={"Project"}
                label={"Project"}
              />
            )}
          />

          <Autocomplete
            id="tags-standard"
            freeSolo
            inputValue={trackingNumber || ""}
            disabled={client && project ? false : true}
            options={trackingNumberList}
            onInputChange={(e, newValue) => {
              setTrackingNumber(newValue);
            }}
            // onChange={() => {}}
            getOptionLabel={(option) =>
              typeof option === "string" || option instanceof String
                ? option
                : ""
            }
            style={{ width: "30%", margin: "15px 5px 5px 5px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                required={true}
                id="standard-multiline-flexible"
                size="small"
                type="text"
                name={"Tracking Number"}
                label={"Tracking Number"}
              />
            )}
          />
          <Button
            style={{ width: "10%", margin: "15px 5px 5px 5px" }}
            disabled={trackingNumber === "" ? true : false}
            type="submit"
            variant="outlined"
            onClick={addToList}
          >
            Add
          </Button>
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Grid
            // container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {tableData && (
              <TableContainer style={{ marginTop: "30px" }}>
                <Table
                  stickyHeader={true}
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size={"small"}
                >
                  <TableHead sx={{ minWidth: 650 }}>
                    <TableRow sx={{ minWidth: 650 }}>
                      {tableRowData.map((row, index) => (
                        <TableCell
                          align="left"
                          key={index}
                          style={{ fontWeight: "bold" }}
                        >
                          {row}
                        </TableCell>
                      ))}
                      <TableCell
                        align="left"
                        style={{ fontWeight: "bold" }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ border: "none" }}>
                    {tableData !== 0 &&
                      tableData
                        .slice(
                          pageLarge * rowsPerPageLarge,
                          pageLarge * rowsPerPageLarge + rowsPerPageLarge
                        )
                        .map((row, index) => (
                          <TableRow
                            hover={true}
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="left">
                              {row.tracking_number || "None / Null"}
                            </TableCell>
                            <TableCell align="left">
                              {row.client || "None / Null"}
                            </TableCell>
                            <TableCell align="left">
                              {row.number_of_samples || "None / Null"}
                            </TableCell>

                            <TableCell align="left">{row.created_by}</TableCell>
                            <TableCell
                              align="left"
                              onClick={(e) => handleRemoveCell(row)}
                            >
                              <Tooltip
                                title="Remove"
                                style={{ cursor: "pointer" }}
                              >
                                <CancelIcon color="secondary"></CancelIcon>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={tableData.length}
                  rowsPerPage={rowsPerPageLarge}
                  page={pageLarge}
                  onPageChange={handleChangePageLarge}
                  onRowsPerPageChange={handleChangeRowsPerPageLarge}
                />
              </TableContainer>
            )}
          </Grid>
        </Box>
        <Stack direction="row" style={{ width: "100%" }}>
          <Button
            style={{ width: "100%", margin: "15px 5px 5px 5px" }}
            disabled={tableData.length === 0 || dataLoading ? true : false}
            type="submit"
            variant="contained"
            onClick={handleShowConfirmation}
          >
            Submit
          </Button>
        </Stack>
        <ConfirmationDialog
          open={confirmation}
          handleClose={() => {
            setConfirmation(false);
          }}
          handleConfirm={submitData}
          title="Final Warning: Once a manifest is deleted, It will need to be fully re-ingested.
          Tracking numbers shown below will get deleted permanently.
          Please verify before deleting, or press cancel."
          subTitle={subTitleText}
        />
      </Stack>
    </Modal>
  );
};

export default DeleteManifest;
