import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { FormContainer } from "react-hook-form-mui";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../Store/snackbar";
import BiService from "../services/BiService";

const AcsCustomExport = ({ setValue }) => {
  const [profile, setProfile] = useState("");
  const [client, setClient] = useState("");
  const theme = useTheme();

  const [profileOptions, setProfileOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);

  const [details, setDetails] = useState([]);
  const dispatch = useDispatch();
  const userProfile = JSON.parse(sessionStorage.getItem("userProfile"));
  // console.log("up", userProfile);

  // console.log("sdsafj", clientOptions);

  const handleClient = (client) => {
    setClient(client);
    setProfile("");
    let prof = {};
    if (details.length !== 0) prof = details.find((el) => el[0] === client);

    console.log("prf", prof);
    if (prof[1] && prof[1].length !== 0)
      setProfileOptions(prof[1].map((el) => el["profile"]));
    // console.log(prof["config_params"].map((el) => el["profile"]));
  };

  useEffect(() => {
    // let list = [];
    // details?.forEach((ele) => {
    //   if (ele.client) list.push(ele.client);
    // });
    // setClientOptions(list);

    BiService.getCustomExportProfiles()
      .then((resp) => {
        // console.log("data", resp.data);
        let list = [];
        resp.data?.forEach((ele) => {
          if (ele[0]) list.push(ele[0]);
        });
        setClientOptions(list);
        setDetails(resp.data);
        console.log("ceprf", resp.data);
        if (list.length === 0) {
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: `Client: ${userProfile.group}  does not have custom data export functionality.`,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        // dispatch(
        //   snackbarActions.showNotification({
        //     snackbarOpen: true,
        //     snackbarType: "error",
        //     snackbarMessage: err.response.data.detail,
        //   })
        // );
      });
  }, []);

  const onSubmit = () => {
    BiService.doCustomExport({ client, profile })
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );
        setValue("two");
      })
      .catch((error) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.data.detail,
          })
        );
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        xs={12}
        mt={2}
      >
        <Typography
          variant="h4"
          color={theme.palette.mode == "light" ? "secondary.dark" : "#c9d1d9"}
          sx={{ mt: 2, mb: 1 }}
        >
          Select a Client and a Profile for Full Custom Data Export
        </Typography>
      </Grid>
      <Grid item md={12} container alignItems="center" justifyContent="center">
        <FormContainer>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* <Typography>{"Select a Client & profile"}</Typography> */}
            {/* <Divider orientation="horizontal" sx={{ width: "100%" }} /> */}
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              size="small"
              margin="dense"
              options={clientOptions}
              onChange={(e, new_value) => handleClient(new_value)}
              renderInput={(params) => (
                <TextField margin="dense" {...params} label="Client" />
              )}
              value={client}
              sx={{ minWidth: 200 }}
            />
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              disabled={!client}
              size="small"
              margin="dense"
              options={profileOptions}
              onChange={(e, new_value) => setProfile(new_value)}
              renderInput={(params) => (
                <TextField margin="dense" {...params} label="Profile" />
              )}
              value={profile}
              sx={{ minWidth: 200 }}
            />
          </Box>
        </FormContainer>
      </Grid>
      <Grid item xs={24} container justifyContent="center">
        <Button onClick={onSubmit} disabled={!client || !profile}>
          CUSTOM EXPORT
        </Button>
      </Grid>
    </Grid>
  );
};

export default AcsCustomExport;
