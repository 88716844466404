import {
  Button,
  Divider,
  Typography,
  Alert,
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Stack,
} from "../mui";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useInterval } from "../components/helper/utils";
import AptService from "../services/AptService";
import SnpService from "../services/SnpService";
import { snackbarActions } from "../Store/snackbar";

import SnpChart from "./SnpChart";

const tableRowData = [
  "ID",
  "RSID",
  "ARRAY ID",
  "CHR",
  "GENE ",
  "GENOME BUILD ",
  "POSITION ",
  "PROBESET ID ",
  "REF ALLELE ",
  "ALT ALLELE ",
];

const SnpTool = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const [value, setValue] = React.useState("one");
  const [filter, setFilter] = React.useState("RSID");
  const [searchType, setSearchType] = React.useState("bulk");
  // const [largeSearchList, setLargeSearchList] = React.useState([]);
  const [emptyHook, setEmptyHook] = React.useState(0);

  const [listLarge, setListLarge] = React.useState([]);
  const [largeInput, setLargeInput] = React.useState("");
  const [tableBulkData, setTableBulkData] = React.useState([]);
  const [currentData, setCurrentData] = React.useState([]);
  const [pageLarge, setPageLarge] = React.useState(0);
  const [rowsPerPageLarge, setRowsPerPageLarge] = React.useState(6);
  const [val, setVal] = useState([]);
  const [filterExpand, setFilterExpand] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [uploadData, setUploadData] = React.useState([]);

  const dispatch = useDispatch();

  const changeHandler = (event) => {
    if (event.target.files[0]) {
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: `Successfully Selected ${event.target.files[0].name}`,
        })
      );
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleSubmission = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("file", selectedFile);

    SnpService.uploadFile(data)
      .then((resp) => {
        console.log(resp);
        setUploadData(resp.data);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Successfully Uploaded",
          })
        );
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed Request",
          })
        );
      });
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleExpandView = () => {
    setFilterExpand(!filterExpand);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePageLarge = (event, newPage) => {
    setPageLarge(newPage);
  };

  const handleChangeRowsPerPageLarge = (event) => {
    setRowsPerPageLarge(+event.target.value);
    setPageLarge(0);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setDataLoading(true);
    setPageLarge(0);
    setTableBulkData([]);

    setEmptyHook(emptyHook + 1);
  };

  useInterval(() => {
    AptService.getIP()
      .then((resp) => {
        console.log("You Are Connected to VPN");
        setIsLoading(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("You Are Not Connected to VPN");
        console.log(err);
      });
  }, 1000 * 7);

  React.useEffect(() => {
    AptService.getIP()
      .then((resp) => {
        console.log("You Are Connected to VPN");
        setIsLoading(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("You Are Not Connected to VPN");
        console.log(err);
      });
  }, []);

  useEffect(() => {
    SnpService.getRSIDList(largeInput)
      .then((resp) => {
        setListLarge(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [largeInput]);

  useEffect(() => {
    let finalList = tableBulkData;
    finalList = finalList.concat(currentData);
    setTableBulkData(finalList);
  }, [currentData]);

  useEffect(() => {
    if (val.length === 0) setDataLoading(false);
    val.forEach((ele) => {
      SnpService.getRSID(ele)
        .then((resp) => {
          setCurrentData(resp.data);
          setDataLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setDataLoading(false);
        });
    });
  }, [emptyHook]);

  useEffect(() => {
    setTableBulkData(uploadData);
    setDataLoading(false);
  }, [uploadData]);

  return (
    <Box>
      <Typography
        variant="h1"
        style={{
          fontFamily: "Roboto",
          fontWeight: "500",
          fontSize: "54px",
          lineHeight: "116.7%",
          letterSpacing: "-1.5px",
          color: "rgba(0, 0, 0, 0.6)",
        }}
      >
        SNP Tools
      </Typography>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="primary tabs example"
      >
        <Tab value="one" label="SEARCH" />
        <Tab value="two" label="CHARTS" />
        <Tab value="three" label="MANAGAMENT" />
      </Tabs>
      <Divider style={{ width: "100%" }}></Divider>
      {isLoading === false && (
        <Box>
          <Alert style={{ top: "45%" }} severity="warning">
            You are currently not connected to the VPN please connect to resume
            session.
          </Alert>
          <CircularProgress
            style={{ position: "absolute", top: "45%", left: "35%" }}
            size={"11rem"}
          />
        </Box>
      )}
      {isLoading === true && value === "two" && (
        <SnpChart data={tableBulkData}></SnpChart>
      )}
      {isLoading === true && value === "one" && (
        <Box>
          <Box style={{ paddingTop: "25px" }}>
            <FormControl variant="standard" sx={{ minWidth: 100 }}>
              <InputLabel id="demo-simple-select-label"></InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filter}
                label="Filter"
                onChange={handleFilterChange}
              >
                <MenuItem value={"RSID"}>RSID</MenuItem>
                <MenuItem value={"GENE"}>GENE</MenuItem>
                <MenuItem value={"OTHER"}>Other..</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="standard" style={{ width: "380px" }}>
              <Autocomplete
                multiple
                id="tags-standard"
                freeSolo
                inputValue={largeInput}
                onInputChange={(e, newValue) => setLargeInput(newValue)}
                options={listLarge}
                onChange={(e, newValue) => setVal(newValue)}
                // getOptionLabel={option => option.title}
                value={val}
                renderTags={() => {}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="standard-multiline-flexible"
                    variant="standard"
                    placeholder="Search.."
                    margin="normal"
                  />
                )}
              />
            </FormControl>

            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit}
              style={{ marginTop: "10px", marginLeft: "10px" }}
            >
              Search
            </Button>
            <Button
              variant="contained"
              component="label"
              style={{ marginTop: "10px", marginLeft: "10px" }}
            >
              Select File
              <input
                type="file"
                name="file"
                accept=".csv"
                hidden
                onChange={changeHandler}
              />
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmission}
              style={{ marginTop: "10px", marginLeft: "10px" }}
            >
              Upload File
            </Button>
          </Box>

          <Stack direction="row">
            {filterExpand
              ? val.map((option, index) => (
                  <Chip
                    variant="outlined"
                    onDelete={() => {
                      setVal(val.filter((entry) => entry !== option));
                    }}
                    size="small"
                    key={index}
                    label={option}
                  />
                ))
              : val.slice(0, 5).map((option, index) => (
                  <Chip
                    variant="outlined"
                    onDelete={() => {
                      setVal(val.filter((entry) => entry !== option));
                    }}
                    size="small"
                    key={index}
                    label={option}
                  />
                ))}

            {6 <= val.length && (
              <Chip
                variant="outlined"
                size="small"
                label="Show More"
                onClick={handleExpandView}
              />
            )}
          </Stack>

          {dataLoading === false && (
            <Box>
              {searchType === "bulk" && (
                <TableContainer style={{ marginTop: "15px" }}>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                    size={"small"}
                  >
                    <TableHead sx={{ minWidth: 650 }}>
                      <TableRow sx={{ minWidth: 650 }}>
                        {tableRowData.map((row, index) => (
                          <TableCell
                            align="left"
                            key={index}
                            style={{ fontWeight: "bold" }}
                          >
                            {row}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ border: "none" }}>
                      {tableBulkData.length !== undefined &&
                        tableBulkData
                          .slice(
                            pageLarge * rowsPerPageLarge,
                            pageLarge * rowsPerPageLarge + rowsPerPageLarge
                          )
                          .map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="left">{row.id}</TableCell>
                              <TableCell align="left">{row.rsid}</TableCell>
                              <TableCell align="left">{row.array_id}</TableCell>
                              <TableCell align="left">{row.chr}</TableCell>
                              <TableCell align="left">
                                {row.gene.split(" ")[0]}
                              </TableCell>
                              <TableCell align="left">
                                {row.genome_build}
                              </TableCell>
                              <TableCell align="left">{row.position}</TableCell>
                              <TableCell align="left">
                                {row.probeset_id}
                              </TableCell>
                              <TableCell align="left">
                                {row.ref_allele}
                              </TableCell>
                              <TableCell align="left">
                                {row.alt_allele}
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[6, 10, 25]}
                    component="div"
                    count={tableBulkData.length}
                    rowsPerPage={rowsPerPageLarge}
                    page={pageLarge}
                    onPageChange={handleChangePageLarge}
                    onRowsPerPageChange={handleChangeRowsPerPageLarge}
                  />
                </TableContainer>
              )}
            </Box>
          )}
          {dataLoading === true && (
            <Box>
              <CircularProgress
                style={{ position: "absolute", top: "55%", left: "35%" }}
                size={"11rem"}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SnpTool;
