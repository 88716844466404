import { useEffect, useState, useRef } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import ManagementService from "../../services/ManagementService";
import UIWrapper from "../../components/UIWrapper";
import AcsSendToLIMS from "./AcsSendToLims";

import { CSVLink } from "react-csv";
import { Typography, Button, Divider, Box } from "../../mui";
import BiService from "../../services/BiService";
import { snackbarActions } from "../../Store/snackbar";
import { useDispatch } from "react-redux";
import DeleteManifest from "./DeleteManifest";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const PendingAccessionColumns = [
  // { field: "id", headerName: "ID", width: 90, hide: true },
  {
    field: "client",
    headerName: "Client",
    minWidth: 100,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "project",
    headerName: "Project",
    minWidth: 150,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "inventory_code",
    headerName: "Inventory Code",
    minWidth: 180,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "source",
    headerName: "Source",
    minWidth: 120,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "container_type",
    headerName: "Container Type",
    minWidth: 120,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
  {
    field: "tracking_number",
    headerName: "Tracking Number",
    minWidth: 180,
    flex: 1,
    editable: false,
    valueGetter: (params) => {
      if (!params.value) {
        return "None/Null";
      }
      return params.value;
    },
  },
];

const PendingAccession = () => {
  const [tableData, setTableData] = useState([]);
  const [openAccessioningModal, setOpenAccessioningModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [inventoryCodeData, setInventoryCodeData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const csvLink = useRef();
  const dispatch = useDispatch();

  const handleExport = async (event) => {
    let currentTimestamp = Date.now();
    let date = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(currentTimestamp);
    date = date.replace(/[^a-zA-Z0-9- ]/g, "_");
    date = date.replace(/\s/g, "");
    date = date.slice(0, -2);
    setFileName("pending_sample_export_" + date + ".csv");
    let keyArray = tableData.map(function (item) {
      return item["inventory_code"];
    });
    await BiService.exportSearch(keyArray)
      .then((resp) => {
        setInventoryCodeData(resp.data);
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Export Failed.",
          })
        );
      });
  };

  const handleOpenModal = () => {
    setOpenAccessioningModal(true);
  };

  useEffect(() => {
    setDataLoading(true);

    const getSample = async () => {
      await ManagementService.getPendingSamples()
        .then((resp) =>
          setTableData(
            resp.data.map((row, id) => {
              return { id, ...row };
            })
          )
        )
        .catch((err) => {
          console.log(err);
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: "Failed to get Samples Pending Accessioning.",
            })
          );
        });
      setDataLoading(false);
    };
    getSample();

    return;
  }, [refresh]);

  useEffect(() => {
    if (inventoryCodeData.length !== 0) {
      csvLink.current.link.click();
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Export Succesful.",
        })
      );
    }
  }, [inventoryCodeData]);

  return (
    <>
      <UIWrapper title="Samples Pending Accessioning" paper>
        <Box
          sx={{
            width: "100%",
            marginTop: "20px",
          }}
        >
          <Typography sx={{ mb: 1, fontSize: 15, fontWeight: "800" }}>
            Total Number of Samples pending Accessioning: {tableData.length}
          </Typography>
          <Box
            height="375px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <DataGrid
              rows={tableData}
              columns={PendingAccessionColumns}
              loading={dataLoading}
              density="compact"
              disableSelectionOnClick
              components={{
                Toolbar: CustomToolbar,
              }}
              sx={{
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
              }}
            />
          </Box>
        </Box>
        <Divider style={{ width: "100%", marginTop: "20px" }}></Divider>

        {tableData && tableData.length !== 0 && (
          <>
            <Button
              style={{ marginTop: "15px" }}
              type="submit"
              variant="contained"
              onClick={handleExport}
            >
              Export
            </Button>
            <Button
              style={{ marginTop: "15px", marginLeft: "20px" }}
              type="submit"
              variant="contained"
              onClick={handleOpenModal}
            >
              Accelerated Accessioning: Send To Lims
            </Button>
            <Button
              style={{ marginTop: "15px", marginLeft: "20px" }}
              type="submit"
              variant="contained"
              onClick={() => {
                setOpenDeleteModal(true);
              }}
            >
              Delete Manifest
            </Button>
          </>
        )}

        {inventoryCodeData.length ? (
          <CSVLink
            data={inventoryCodeData}
            filename={fileName}
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
        ) : null}
        {openAccessioningModal ? (
          <AcsSendToLIMS
            openServiceModal={openAccessioningModal}
            setOpenServiceModal={setOpenAccessioningModal}
            setRefresh={setRefresh}
          />
        ) : null}
        {openDeleteModal ? (
          <DeleteManifest
            openServiceModal={openDeleteModal}
            setOpenServiceModal={setOpenDeleteModal}
            setRefresh={setRefresh}
          />
        ) : null}
      </UIWrapper>
    </>
  );
};

export default PendingAccession;
