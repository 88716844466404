import AppsRoundedIcon from "@mui/icons-material/AppsRounded";
import { Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BiService from "../../../services/BiService";
import { snackbarActions } from "../../../Store/snackbar";
import ServiceIcon from "./ServiceIcon";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  borderRadius: 3,
  // "&:hover": {
  // backgroundColor: theme.palette.secondary.main,
  // color: theme.palette.primary.main,
  // },
}));

const ServiceList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [availableServices, setAvailableServices] = useState([]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    BiService.getAllServices()
      .then((resp) => {
        setAvailableServices(resp.data);
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed Request",
          })
        );
      });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Button
          onClick={handleClick}
          startIcon={<AppsRoundedIcon />}
          color="secondary"
          size="large"
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.action.hover,
              color:
                theme.palette.mode == "light" ? "primary.main" : "secondary",
            },
          }}
        >
          Services
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            bgcolor:
              theme.palette.mode == "light"
                ? "white"
                : theme.palette.background.default,
            color: "secondary",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
            border:
              theme.palette.mode == "light"
                ? null
                : "2px solid rgba(200,200,200,0.12)",
            borderRadius: 3,
            "&:after": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 20,
              width: 10,
              height: 10,
              bgcolor:
                theme.palette.mode == "light"
                  ? "white"
                  : theme.palette.background.default,
              borderTop:
                theme.palette.mode == "light"
                  ? null
                  : "2px solid rgba(200,200,200,0.12)",
              borderLeft:
                theme.palette.mode == "light"
                  ? null
                  : "2px solid rgba(200,200,200,0.12)",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        // transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ display: "flex", mr: 1, ml: 1 }}>
          <Box>
            {/* <StyledMenuItem onClick={() => history.push("/about")}>
              Recently Viewed
            </StyledMenuItem> */}
            <StyledMenuItem onClick={() => history.push("/services/search")}>
              All Services
            </StyledMenuItem>
          </Box>
          <Divider
            orientation="vertical"
            sx={{ height: "auto", ml: 1, mr: 1 }}
          />
          <Box sx={{ minWidth: 200 }}>
            {availableServices.map((service) => (
              <StyledMenuItem
                key={service.service_id}
                onClick={() => history.push(service.route)}
              >
                <ListItemIcon>
                  <ServiceIcon
                    iconName={service.icon}
                    color={
                      theme.palette.mode == "light"
                        ? service.color
                        : "secondary"
                    }
                  />
                </ListItemIcon>
                <Typography style={{ fontWeight: 900, fontSize: 12 }}>
                  {service.service_name.toUpperCase()}
                </Typography>
              </StyledMenuItem>
            ))}
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default ServiceList;
