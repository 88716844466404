import {
  CompareArrowsIcon,
  Button,
  Divider,
  TextField,
  BoxSystem,
} from "../../../mui";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { mapManifestActions } from "../../../Store/map-manifest";
import Conditions from "../Conditions";
import RuleDialogWrapper from "./RuleDialogWrapper";

const AppendDialog = ({ open, onClose }) => {
  const [prepend, setPrepend] = useState({ init: true, value: "" });
  const [append, setAppend] = useState({ init: true, value: "" });
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      dispatch(mapManifestActions.initRule({ action: "append" }));
    }
    return;
  }, [open]);

  const resetState = () => {
    setPrepend({ init: true, value: "" });
    setAppend({ init: true, value: "" });
  };

  const onCloseHandler = () => {
    dispatch(mapManifestActions.deleteRule());
    resetState();
    onClose();
  };

  const onSubmitHandler = () => {
    dispatch(
      mapManifestActions.append({
        prepend: prepend.value,
        append: append.value,
      })
    );
    resetState();
    onClose();
  };

  return (
    <RuleDialogWrapper
      open={open}
      onClose={onCloseHandler}
      title="Prepend / Append"
      directions={
        'Prepend or Append a Static value to a column. For example, right append "mL" to a volume of "10".'
      }
    >
      <BoxSystem
        sx={{
          display: "flex",
          flexDirection: "column",
          jusitfyContent: "center",
          alignItems: "center",
        }}
      >
        <BoxSystem display="flex" alignItems="center" m={2}>
          <TextField
            id="standard-adornment-weight"
            size="small"
            label="Prepend"
            onChange={(event) =>
              setPrepend({ init: false, value: event.target.value })
            }
          />
          <CompareArrowsIcon />
          <TextField
            id="standard-adornment-weight"
            size="small"
            label="Append"
            onChange={(event) =>
              setAppend({ init: false, value: event.target.value })
            }
          />
        </BoxSystem>
        <Divider sx={{ width: "100%" }} />
        <Conditions />
        <Divider sx={{ width: "100%" }} />
        <Button
          sx={{ mt: 1 }}
          onClick={onSubmitHandler}
          disabled={
            (prepend.init && append.init) ||
            (!prepend.value.length && !append.value.length)
          }
        >
          Submit
        </Button>
      </BoxSystem>
    </RuleDialogWrapper>
  );
};

export default AppendDialog;
