import { Auth } from "aws-amplify";
import axios from "axios";
// import * as qs from "qs";

const ACS_API_BASE_URL = process.env.MAIN_API;

// console.log("PRINT", BI_API_BASE_URL);

class ManagementService {
  async getPendingSamples() {
    let myInit = {
      // params: {
      //   file_name: `${file_name}`,
      // },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/management/pending-accession-data",
      myInit
    );
  }
  async exportPendingSamples(list) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/console/pending-samples-export",
      myInit
    );
  }
  async getAllClients(clientName) {
    let myInit = {
      params: {
        query: `${clientName}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/management/manifest-clients",
      myInit
    );
  }
  async getClientProjects(client) {
    let myInit = {
      params: {
        client: `${client}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/management/client-projects",
      myInit
    );
  }
  async getTrackingNumbers(client, project) {
    let myInit = {
      params: {
        client: `${client}`,
        project: `${project}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/management/client-projects",
      myInit
    );
  }

  async getTrackingNumberDetails(client, project, tracking_number) {
    let myInit = {
      params: {
        client: `${client}`,
        project: `${project}`,
        tracking_number: `${tracking_number}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/management/tracking-number-details",
      myInit
    );
  }

  async saveAcsLimsTrackingNumbers(data) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = data;

    return await axios.post(
      ACS_API_BASE_URL + "/management/accession-pending-samples",
      body,
      myInit
    );
  }

  async getManifestTrackingNumbers(client, project, tracking_number) {
    let myInit = {
      params: {
        client: `${client}`,
        project: `${project}`,
        tracking_number: `${tracking_number}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/management/get-manifest-tracking-numbers",
      myInit
    );
  }

  async deleteAccessionManifest(body) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.post(
      ACS_API_BASE_URL + "/management/delete-accession-manifest",
      body,
      myInit
    );
  }

  async getTableBarCodeDataLims(body) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.post(
      ACS_API_BASE_URL + "/management/check_duplicate_send_to_lims",
      body,
      myInit
    );
  }

  async getAF2FileRecords() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(ACS_API_BASE_URL + "/management/af2_data", myInit);
  }

  async acceptOrRejectAF2(body) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.post(
      ACS_API_BASE_URL + "/management/af2_update",
      body,
      myInit
    );
  }

  async getAF2File(file_name) {
    let myInit = {
      params: {
        file_key: `${file_name}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/management/download-af2file",
      myInit
    );
  }
}

export default new ManagementService();
