import { FormControlLabel, MenuItem, Switch } from "@mui/material";
import React, { useState } from "react";
import RuleDialogWrapper from "../../../../components/ManifestBot/RuleDialogs/RuleDialogWrapper";
import { Box, Button, Divider, TextField } from "../../../../mui";

const AddAccRowDialog = ({ open, onClose, setRows }) => {
  const [newColHeader, setNewColHeader] = useState({ init: true, name: "" });
  const [newColHierarchy, setNewColHierarchy] = useState("sample");
  const [newDataType, setNewDataType] = useState("string");
  const [newColRequired, setNewColRequired] = useState(false);
  const [newColIsInheritable, setNewColIsInheritable] = useState(false);

  const onSubmitHandler = () => {
    setRows((init) => [
      ...init,
      {
        id: init.length,
        orig_name: newColHeader.name
          .match(/[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g)
          .join("_")
          .replace(/[^A-Z0-9]/gi, "_")
          .toLowerCase(),
        client_name: newColHeader.name,
        required: newColRequired,
        hierarchy: newColHierarchy,
        data_type: newDataType,
        is_meta: true,
        is_inheritable: newColIsInheritable,
      },
    ]);
    console.log({
      orig_name: newColHeader.name
        .match(/[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g)
        .join("_")
        .replace(/[^A-Z0-9]/gi, "_")
        .toLowerCase(),
      client_name: newColHeader.name,
      required: newColRequired,
      hierarchy: newColHierarchy,
      is_inheritable: newColIsInheritable,
    });
    setNewColHierarchy("sample");
    setNewColRequired(false);
    setNewDataType("string");
    setNewColIsInheritable(false);
    onClose();
  };

  return (
    <RuleDialogWrapper
      open={open}
      onClose={onClose}
      title="Add a New Field"
      directions={"Fill out the core information for adding a new field"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          jusitfyContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          label="Client Header"
          onChange={(event) =>
            setNewColHeader({ init: false, name: event.target.value })
          }
          error={!newColHeader.init && newColHeader.name.length == 0}
          sx={{ mt: 2, minWidth: 200 }}
        />
        <TextField
          value={newColHierarchy}
          onChange={(event) => setNewColHierarchy(event.target.value)}
          select // tell TextField to render select
          label="Hierarchy"
          size="small"
          margin="dense"
          sx={{ minWidth: 200, mt: 2 }}
        >
          <MenuItem value={"subject"}>Subject</MenuItem>
          <MenuItem value={"sample"}>Sample</MenuItem>
        </TextField>
        <TextField
          value={newDataType}
          onChange={(event) => setNewDataType(event.target.value)}
          select // tell TextField to render select
          label="Data Type"
          size="small"
          margin="dense"
          sx={{ minWidth: 200, mt: 2 }}
        >
          <MenuItem value={"number"}>Number</MenuItem>
          <MenuItem value={"string"}>Text</MenuItem>
          <MenuItem value={"boolean"}>True/False</MenuItem>
          <MenuItem value={"date"}>Date</MenuItem>
          <MenuItem value={"time"}>Time</MenuItem>
        </TextField>
        <Box sx={{ minWidth: 200, mt: 2, mb: 2 }}>
          <FormControlLabel
            sx={{ m: 0 }}
            control={<Switch />}
            onChange={(event) => setNewColRequired(event.target.value == "on")}
            labelPlacement="start"
            label="Required:"
          />
          <FormControlLabel
            sx={{ m: 0 }}
            control={<Switch />}
            onChange={(event) =>
              setNewColIsInheritable(event.target.value == "on")
            }
            labelPlacement="start"
            label="Inherit Meta: "
          />
        </Box>
        <Divider sx={{ width: "100%" }} />
        <Button
          sx={{ mt: 1 }}
          onClick={onSubmitHandler}
          disabled={newColHeader.init || newColHeader.name.length == 0}
        >
          Submit
        </Button>
      </Box>
    </RuleDialogWrapper>
  );
};

export default AddAccRowDialog;
