import React from "react";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import ParentWrapper from "../../components/ParentWrapper";
import PrivateRoute from "../../components/PrivateRoute";
import Home from "../Home";

const ParentHome = (props) => {
  let { path } = useRouteMatch();
  return (
    <ParentWrapper path={path}>
      <PrivateRoute exact path={`${path}`}>
        <Home historyArray={props.historyArray}/>
      </PrivateRoute>
      <Route>
        <Redirect to={`${path}`} />
      </Route>
    </ParentWrapper>
  );
};

export default ParentHome;
