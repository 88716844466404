import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormContainer } from "react-hook-form-mui";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UIWrapper from "../../components/UIWrapper";
import ProjectManagementService from "../../services/ProjectManagementService";
import { snackbarActions } from "../../Store/snackbar";

const NewProject = () => {
  const [client, setClient] = useState("");
  const [project, setProject] = useState("");
  const [optionalClient, setOptionalClient] = useState("");
  const [optionalProject, setOptionalProject] = useState("");
  const [clientOptions, setClientOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    ProjectManagementService.getFullClients("").then((resp) =>
      setClientOptions(resp.data)
    );
    return;
  }, [setClientOptions]);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        ProjectManagementService.getClientProjects(client, project).then(
          (resp) => setProjectOptions(resp.data)
        ),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [project, setProjectOptions, client]);

  const onSubmit = () => {
    ProjectManagementService.createNewProject(
      client,
      project,
      optionalClient,
      optionalProject
    )
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: `Successfully Added Project: ${project}`,
          })
        );
        history.push("/project-management/projects/mapping");
      })
      .catch((error) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.data.detail,
          })
        );
      });
  };

  return (
    <UIWrapper title="Create a New Project" paper>
      <Grid container spacing={2}>
        <Grid item xs={12} mt={2}>
          <Typography>
            Select a Client to Create a New Project for. This will create a
            default project mapping. Optionally, you may select an existing
            Client & Project to use as the template for the project mapping.
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <FormContainer>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography>{"Select a Client & Project"}</Typography>
              <Divider orientation="horizontal" sx={{ width: "100%" }} />
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                size="small"
                margin="dense"
                options={clientOptions}
                onChange={(e, new_value) => setClient(new_value)}
                renderInput={(params) => (
                  <TextField margin="dense" {...params} label="Client" />
                )}
                value={client}
                sx={{ minWidth: 200 }}
              />
              <TextField
                id="standard-adornment-weight"
                size="small"
                inputProps={{
                  "aria-label": "weight",
                }}
                label="Project"
                onChange={(event) => setProject(event.target.value)}
                disabled={!client}
                sx={{ minWidth: 200, mt: 1 }}
                value={project}
              />
              <Typography sx={{ mt: 2 }}>
                {"Optional: Select a Template Client & Project"}
              </Typography>
              <Divider orientation="horizontal" sx={{ width: "100%" }} />
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                size="small"
                margin="dense"
                options={clientOptions}
                onChange={(e, new_value) => setOptionalClient(new_value)}
                renderInput={(params) => (
                  <TextField margin="dense" {...params} label="Client" />
                )}
                value={optionalClient}
                sx={{ minWidth: 200 }}
              />
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                size="small"
                margin="dense"
                options={projectOptions}
                onChange={(e, new_value) => setOptionalProject(new_value)}
                renderInput={(params) => (
                  <TextField margin="dense" {...params} label="Project" />
                )}
                value={optionalProject}
                sx={{ minWidth: 200 }}
                disabled={!optionalClient}
              />
            </Box>
          </FormContainer>
        </Grid>
        <Grid item xs={24} container justifyContent="center">
          <Button
            onClick={onSubmit}
            disabled={
              !client || !project || (optionalClient && !optionalProject)
            }
          >
            SUBMIT
          </Button>
        </Grid>
      </Grid>
    </UIWrapper>
  );
};

export default NewProject;
