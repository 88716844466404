import { useEffect, useState, useRef } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Alert,
} from "../../mui";
import MenuItem from "@mui/material/MenuItem";

import { Grid } from "@mui/material";
import ManagementService from "../../services/ManagementService";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../../Store/snackbar";
import { isEqual } from "lodash";
import AcsUniqueInvCode from "../AcsUniqueInvCode";
import dayjs from "dayjs";
import { userTimeZone, timeZonesList } from "../../components/helper/timeZone";

const carrierTypes = [
  {
    value: "BIOCAIR",
    label: "BIOCAIR",
  },
  {
    value: "DHL",
    label: "DHL",
  },
  {
    value: "FedEx",
    label: "FedEx",
  },
  {
    value: "FedEx Ground",
    label: "FedEx Ground",
  },
  {
    value: "Hand Delivered",
    label: "Hand Delivered",
  },
  {
    value: "Marken",
    label: "Marken",
  },
  {
    value: "Priority Express",
    label: "Priority Express",
  },
  {
    value: "Quickstat",
    label: "Quickstat",
  },
  {
    value: "TNT",
    label: "TNT",
  },
  {
    value: "UPS",
    label: "UPS",
  },
  {
    value: "USA Courier",
    label: "USA Courier",
  },
  {
    value: "USPS",
    label: "USPS",
  },
  {
    value: "VIP Cargo",
    label: "VIP Cargo",
  },
  {
    value: "World Courrier",
    label: "World Courrier",
  },
];

const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const tableRowData = [
  "Client",
  "Project",
  "Tracking Number",
  "Accessioning Manifest Count",
  "Carrier",
  "Date Received",
  "Time Received",
  "Timezone",
];

const AcsSendToLIMS = (props) => {
  const dispatch = useDispatch();
  const [projectList, setProjectList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [trackingNumberList, setTrackingNumberList] = useState([]);
  const [client, setClient] = useState("");
  const [project, setProject] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [tableData, setTableData] = useState([]);
  const [pageLarge, setPageLarge] = useState(0);
  const [rowsPerPageLarge, setRowsPerPageLarge] = useState(10);
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(false);

  const [showUnique, setShowUnique] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const tableBarCodeData = useRef(null);

  const handleCheckDuplicates = () => {
    const is_error = tableData.some(
      ({ carrier, date_received, time_received, dt_received_timezone }) =>
        !(date_received && time_received && dt_received_timezone && carrier)
    );
    if (is_error) {
      setError(true);
      // setTimeout(() => setError(false), 2000);
      return;
    }
    const req_data = tableData.map(({ client, project, tracking_number }) => {
      return { client, project, tracking_number };
    });
    setLoading(true);
    setShowUnique(true);
    ManagementService.getTableBarCodeDataLims(req_data)
      .then((resp) => {
        setLoading(false);
        tableBarCodeData.current = resp.data;
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
      });
  };

  const handleShowUniqueClose = () => {
    setShowUnique(false);
    tableBarCodeData.current = null;
  };

  const handleChangePageLarge = (event, newPage) => {
    setPageLarge(newPage);
  };

  const handleChangeRowsPerPageLarge = (event) => {
    setRowsPerPageLarge(+event.target.value);
    setPageLarge(0);
  };
  const handleFieldChange = (new_value, index, cell_name) => {
    setTableData((prev) => {
      prev[index][cell_name] = new_value;
      return [...prev];
    });
  };

  const submitData = async (updatedTableBarCodeData) => {
    setDataLoading(true);
    const uniqueBarcodeData = updatedTableBarCodeData.data?.reduce(
      (accum, curr) => {
        if (curr.inventory_code !== curr.old_inventory_code)
          accum[curr.old_inventory_code] = curr.inventory_code;

        return accum;
      },
      {}
    );
    const req_body = {
      tracking_details_data: tableData,
      unique_inventory_codes: uniqueBarcodeData,
    };
    await ManagementService.saveAcsLimsTrackingNumbers(req_body)
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );
        setShowUnique(false);
        clearForm();
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
      });
    setDataLoading(false);
  };
  const clearForm = () => {
    props.setOpenServiceModal(false);
    props.setRefresh((prev) => !prev);
    setTableData([]);
  };

  const addToList = async () => {
    await ManagementService.getTrackingNumberDetails(
      client,
      project,
      trackingNumber
    )
      .then((resp) => {
        if (resp.data.length !== 0)
          if (
            tableData.filter(
              ({
                client,
                project,
                tracking_number,
                accessioning_mainfest_count,
              }) =>
                isEqual(
                  {
                    client,
                    project,
                    tracking_number,
                    accessioning_mainfest_count,
                  },
                  resp.data[0]
                )
            ).length === 0
          ) {
            const date_received = dayjs().format("YYYY-MM-DD");
            const time_received = dayjs().format("HH:mm");
            const dt_received_timezone = userTimeZone;
            const carrier = "";
            const newRow = {
              ...resp.data[0],
              carrier,
              date_received,
              time_received,
              dt_received_timezone,
            };
            setTableData([...tableData, newRow]);
          }
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.detail,
          })
        );
      });
    setTrackingNumber("");
  };

  const handleClose = () => {
    props.setOpenServiceModal(false);
    setTableData([]);
  };

  useEffect(() => {
    setTrackingNumber("");
    if (client && project) {
      ManagementService.getTrackingNumbers(client, project)
        .then((resp) => {
          setTrackingNumberList(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [client, project]);

  useEffect(() => {
    setProject("");
    if (client) {
      ManagementService.getClientProjects(client)
        .then((resp) => {
          setProjectList(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [client]);

  useEffect(() => {
    ManagementService.getAllClients(client)
      .then((resp) => {
        setClientList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Modal
      open={props.openServiceModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style}>
        <Modal
          open={showUnique}
          onClose={handleShowUniqueClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack>
            <AcsUniqueInvCode
              loading={loading}
              tableBarCodeData={tableBarCodeData.current}
              processing={submitLoading}
              hamdleParentSubmission={submitData}
              service_name="management_console"
            />
          </Stack>
        </Modal>
        <Typography
          variant="h4"
          style={{
            fontFamily: "Roboto",
            fontWeight: "500",
            lineHeight: "116.7%",
            letterSpacing: "-1.5px",
          }}
          color="secondary"
        >
          Send Samples To LIMS
        </Typography>
        <Divider style={{ width: "100%" }}></Divider>
        <Stack direction="row" style={{ width: "100%" }}>
          <Autocomplete
            id="tags-standard"
            freeSolo
            inputValue={client || ""}
            options={clientList}
            onInputChange={(e, newValue) => setClient(newValue)}
            getOptionLabel={(option) =>
              typeof option === "string" || option instanceof String
                ? option
                : ""
            }
            style={{ width: "30%", margin: "15px 5px 5px 5px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                required={true}
                id="standard-multiline-flexible"
                size="small"
                type="text"
                name={"Client"}
                label={"Client"}
              />
            )}
          />
          <Autocomplete
            id="tags-standard"
            freeSolo
            inputValue={project || ""}
            disabled={client ? false : true}
            options={projectList}
            onInputChange={(e, newValue) => setProject(newValue)}
            getOptionLabel={(option) =>
              typeof option === "string" || option instanceof String
                ? option
                : ""
            }
            style={{ width: "30%", margin: "15px 5px 5px 5px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                required={true}
                id="standard-multiline-flexible"
                size="small"
                type="text"
                name={"Project"}
                label={"Project"}
              />
            )}
          />

          <Autocomplete
            id="tags-standard"
            freeSolo
            inputValue={trackingNumber || ""}
            disabled={client && project ? false : true}
            options={trackingNumberList}
            onInputChange={(e, newValue) => {
              setTrackingNumber(newValue);
            }}
            // onChange={() => {}}
            getOptionLabel={(option) =>
              typeof option === "string" || option instanceof String
                ? option
                : ""
            }
            style={{ width: "30%", margin: "15px 5px 5px 5px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                required={true}
                id="standard-multiline-flexible"
                size="small"
                type="text"
                name={"Tracking Number"}
                label={"Tracking Number"}
              />
            )}
          />
          <Button
            style={{ width: "10%", margin: "15px 5px 5px 5px" }}
            disabled={trackingNumber === "" ? true : false}
            type="submit"
            variant="outlined"
            onClick={addToList}
          >
            Add
          </Button>
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Grid
            // container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {tableData && (
              <TableContainer style={{ marginTop: "30px" }}>
                <Table
                  stickyHeader={true}
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size={"small"}
                >
                  <TableHead sx={{ minWidth: 650 }}>
                    <TableRow sx={{ minWidth: 650 }}>
                      {tableRowData.map((row, index) => (
                        <TableCell
                          align="left"
                          key={index}
                          style={{ fontWeight: "bold" }}
                        >
                          {row}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ border: "none" }}>
                    {tableData !== 0 &&
                      tableData
                        .slice(
                          pageLarge * rowsPerPageLarge,
                          pageLarge * rowsPerPageLarge + rowsPerPageLarge
                        )
                        .map((row, index) => (
                          <TableRow
                            hover={true}
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="left">
                              {row.client || "None / Null"}
                            </TableCell>
                            <TableCell align="left">
                              {row.project || "None / Null"}
                            </TableCell>
                            <TableCell align="left">
                              {row.tracking_number || "None / Null"}
                            </TableCell>

                            <TableCell align="left">
                              {row.accessioning_mainfest_count}
                            </TableCell>
                            <TableCell align="left">
                              <TextField
                                // label={"Carrier"}
                                select
                                variant="standard"
                                error={!row.carrier}
                                style={{
                                  minWidth: "150px",
                                }}
                                size="small"
                                value={row.carrier || ""}
                                onChange={(e) =>
                                  handleFieldChange(
                                    e.target.value,
                                    index,
                                    "carrier"
                                  )
                                }
                              >
                                {carrierTypes.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value || ""}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </TableCell>
                            <TableCell align="left">
                              <TextField
                                id="r_date"
                                // label="Date Received"
                                type="date"
                                error={!row.date_received}
                                variant="standard"
                                value={row.date_received}
                                onChange={(e) =>
                                  handleFieldChange(
                                    e.target.value,
                                    index,
                                    "date_received"
                                  )
                                }
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </TableCell>
                            <TableCell align="left">
                              <TextField
                                id="r_time"
                                // label="Time Received"
                                type="time"
                                variant="standard"
                                value={row.time_received}
                                error={!row.time_received}
                                onChange={(e) =>
                                  handleFieldChange(
                                    e.target.value,
                                    index,
                                    "time_received"
                                  )
                                }
                                size="small"
                              />
                            </TableCell>
                            <TableCell align="left">
                              <Autocomplete
                                id="tags-tz"
                                freeSolo
                                value={row.dt_received_timezone}
                                options={timeZonesList}
                                onChange={(e, new_value) =>
                                  handleFieldChange(
                                    new_value,
                                    index,
                                    "dt_received_timezone"
                                  )
                                }
                                getOptionLabel={(option) =>
                                  typeof option === "string" ||
                                  option instanceof String
                                    ? option
                                    : ""
                                }
                                style={{
                                  minWidth: "140px",
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    error={!row.dt_received_timezone?.trim()}
                                    required={true}
                                    id="user-tz"
                                    size="small"
                                    type="text"
                                    name={"user_timezone"}
                                    // label={"Select Timezone"}
                                  />
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={tableData.length}
                  rowsPerPage={rowsPerPageLarge}
                  page={pageLarge}
                  onPageChange={handleChangePageLarge}
                  onRowsPerPageChange={handleChangeRowsPerPageLarge}
                />
              </TableContainer>
            )}
          </Grid>
        </Box>
        {error && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop="5px"
          >
            <Alert
              style={{ top: "30%" }}
              onClose={() => setError(false)}
              severity="warning"
            >
              All Fields are mandatory. Please enter correct details.
            </Alert>
          </Box>
        )}
        <Stack direction="row" style={{ width: "100%" }}>
          <Button
            style={{ width: "100%", margin: "15px 5px 5px 5px" }}
            disabled={tableData.length === 0 || dataLoading ? true : false}
            type="submit"
            variant="contained"
            onClick={handleCheckDuplicates}
          >
            Send To LIMS
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default AcsSendToLIMS;
