import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";

import { Divider, Paper, Grid, Box, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled } from "@mui/material/styles";
import DocCard from "./DocCard";
import Container from '@mui/material/Container';
import ChildData from "./ChildData";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const DropDownCardChildren = (props) => {
    const [isExpanded, setIsExpanded] = React.useState(false);


    useEffect(() => {
        console.log(props)
    }, []);
    return (
        <Stack direction="column">
            <Stack
                direction="row"
                onClick={() => setIsExpanded(!isExpanded)}
                style={{ cursor: "pointer" }}
            >
                {isExpanded ? (
                    <ExpandMoreIcon
                        fontSize="large"
                        style={{ color: "#9b51e0" }}
                    ></ExpandMoreIcon>
                ) : (
                    <ChevronRightIcon
                        fontSize="large"
                        style={{ color: "#9b51e0" }}
                    ></ChevronRightIcon>
                )}
                <Typography
                    align="left"
                    variant="h6"
                    style={{ fontWeight: "bold", color: "#484D56" }}
                >
                    {props.header.charAt(0).toUpperCase() + props.header.slice(1).replace(/[^a-zA-Z0-9- ]/g, " ")}
                </Typography>
            </Stack>
            {isExpanded && (
                <Box sx={{ width: "100%" }}>
                    <Divider style={{ width: "100%", marginBottom: "10px" }}></Divider>
                    <Stack
                        direction='column'
                        style={{marginLeft:"5px"}}
                    >
                        {props.data
                            .map((row, index) => (
                                <ChildData
                                    header={row.inventory_code}
                                    data={row}
                                    isExpanded={false}
                                    key={index}
                                >
                                </ChildData>
                            ))}

                    </Stack>

                </Box>
            )}
        </Stack>
    );
};

DropDownCardChildren.propTypes = {
    header: PropTypes.string,
    data: PropTypes.array,
};

export default DropDownCardChildren;
