import { Auth } from "aws-amplify";
import { authActions } from "./auth";
import { snackbarActions } from "./snackbar";

export const authenticateUser = () => {
  return async (dispatch) => {
    Auth.federatedSignIn()
      .then((cred) => {
        return Auth.currentAuthenticatedUser();
      })
      .then((user) => {
        dispatch(authActions.login(user.attributes));
      })
      .catch((error) =>
        dispatch(
          snackbarActions.showNotification({
            status: "error",
            title: "Login Error!",
            message: error,
          })
        )
      );
  };
};

export const refreshUser = () => {
  return async (dispatch) => {
    Auth.currentAuthenticatedUser()
      .then((user) => dispatch(authActions.login(user.attributes)))
      .catch((error) => {
        dispatch(
          snackbarActions.showNotification({
            status: "error",
            title: "Login Error!",
            message: error,
          })
        );
        dispatch(authActions.logout());
      });
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    try {
      await Auth.signOut();
      dispatch(authActions.logout());
    } catch (error) {
      dispatch(
        snackbarActions.showNotification({
          status: "error",
          title: "Login Error!",
          message: error,
        })
      );
    }
  };
};
