import { useEffect, useState } from "react";
import { TextField, Stack, Button, Paper, Typography } from "@mui/material";
import BiotechIcon from "@mui/icons-material/Biotech";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import { JsonForms } from "@jsonforms/react";
import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import AcsService from "../../services/AcsService";

export default function AcsSamples(props) {
  const [containerList, setContainerList] = useState([]);
  let removeFormFields = (i) => {
    let newFormValues = [...props.formValues];
    newFormValues.splice(i, 1);
    props.setFormValues(newFormValues);
  };

  let handleChange = (i, data) => {
    if (data) {
      let newFormValues = props.formValues;
      newFormValues[i].data = data.data;
      props.setFormValues(newFormValues);
    }
  };

  let handleInvChange = (i, e) => {
    let newFormValues = JSON.parse(JSON.stringify(props.formValues));
    newFormValues[i].inventory_code_input = e.target.value;
    props.setFormValues(newFormValues);
  };
  let handleSelectChange = (i, e, changeDetail) => {
    let newFormValues = JSON.parse(JSON.stringify(props.formValues));
    newFormValues[i][changeDetail] = e.target.value;
    props.setFormValues(newFormValues);
  };
  let handleSelectOptionChange = (i, newVal, changeDetail) => {
    let newFormValues = JSON.parse(JSON.stringify(props.formValues));
    newFormValues[i][changeDetail] = newVal;
    props.setFormValues(newFormValues);
  };
  let handleAddInv = (i, e) => {
    let newFormValues = JSON.parse(JSON.stringify(props.formValues));
    newFormValues[i]["inventory_code"] = e;
    newFormValues[i]["inventory_code_input"] = "";
    props.setFormValues(newFormValues);
  };
  useEffect(() => {
    AcsService.getContainers(props.client)
      .then((resp) => {
        setContainerList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Paper
      style={{
        padding: "20px",
        margin: "35px",
        display: props.activeStep === 2 ? "block" : "none",
        background: "#eaeaf0",
      }}
      elevation={0}
      variant="outlined"
    >
      <Typography
        variant="h6"
        color="secondary"
        style={{ padding: "5px", color: "secondary", height: "100%" }}
      >
        Scan Samples:
      </Typography>
      <Stack direction="row" spacing={2} style={{ padding: "10px" }}>
        <Button
          variant="contained"
          onClick={() => props.addFormFields()}
          endIcon={<BiotechIcon />}
        >
          Add Samples
        </Button>
      </Stack>
      <Stack
        direction="column"
        spacing={2}
        style={{ padding: "10px", overflow: "auto" }}
        alignItems="center"
        justifyContent="center"
      >
        {props.formValues.map((option, index) => (
          <Paper
            style={{
              padding: "10px",
              width: "95%",
              paddingBottom: "25px",
            }}
            key={index}
            // variant="outlined"
            elevation={2}
          >
            <Stack direction="row" style={{ width: "100%" }}>
              <Stack direction="column" style={{ width: "100%" }}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  freeSolo
                  inputValue={option.inventory_code_input || ""}
                  onInputChange={(e) => handleInvChange(index, e)}
                  options={[]}
                  onChange={(e, newValue) => handleAddInv(index, newValue)}
                  // onChange={e => handleAddInv(index, e)}
                  // getOptionLabel={option => option.title}
                  getOptionLabel={(option) =>
                    typeof option === "string" || option instanceof String
                      ? option
                      : ""
                  }
                  value={option.inventory_code}
                  style={{ width: "100%", padding: "5px" }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        size="small"
                        key={index}
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required={true}
                      id="standard-multiline-flexible"
                      size="small"
                      type="text"
                      name={"inventory_code_input"}
                      variant="standard"
                      label={"Inventory Code"}
                    />
                  )}
                />

                <Stack direction="row" style={{ width: "100%" }}>
                  <Autocomplete
                    id="tags-standard"
                    value={option.source || ""}
                    options={props.sourceList}
                    onChange={(e, newValue) =>
                      handleSelectOptionChange(index, newValue, "source")
                    }
                    getOptionLabel={(option) =>
                      typeof option === "string" || option instanceof String
                        ? option
                        : ""
                    }
                    style={{ width: "50%", margin: "5px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={true}
                        id="standard-multiline-flexible"
                        size="small"
                        type="text"
                        name={"Source"}
                        label={"Source"}
                      />
                    )}
                  />

                  <Autocomplete
                    id="tags-standard"
                    value={option["container_type"] || ""}
                    options={containerList}
                    onChange={(e, newValue) =>
                      handleSelectOptionChange(
                        index,
                        newValue,
                        "container_type"
                      )
                    }
                    getOptionLabel={(option) =>
                      typeof option === "string" || option instanceof String
                        ? option
                        : ""
                    }
                    style={{ width: "50%", margin: "5px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={true}
                        id="standard-multiline-flexible"
                        size="small"
                        type="text"
                        name={"Container Type"}
                        label={"Container Type"}
                      />
                    )}
                  />
                </Stack>

                <JsonForms
                  schema={props.sampleInputs}
                  uischema={props.sampleSchema}
                  data={option["data"]}
                  key={index}
                  renderers={materialRenderers}
                  cells={materialCells}
                  onChange={(data) => handleChange(index, data)}
                />
              </Stack>

              <CancelPresentationIcon
                fontSize="medium"
                style={{ color: "#EE3263", cursor: "pointer", padding: "5px" }}
                onClick={() => removeFormFields(index)}
              ></CancelPresentationIcon>
            </Stack>
          </Paper>
        ))}
      </Stack>
    </Paper>
  );
}
