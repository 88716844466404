import { Auth } from "aws-amplify";
import axios from "axios";
import * as qs from "qs";

const BI_API_BASE_URL = process.env.MAIN_API;

class BiService {
  async getFile(file_name) {
    let myInit = {
      params: {
        file_name: `${file_name}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/export/get-file", myInit);
  }

  async getDownloads(type) {
    let myInit = {
      params: {
        download_type: `${type}`,
      },
      // paramsSerializer: (params) => {
      //   return qs.stringify(params, { arrayFormat: "repeat" });
      // },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/export/downloads", myInit);
  }

  async doExport(profile) {
    // console.log("profile",profile)
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    // let body = {};

    return await axios.post(BI_API_BASE_URL + "/export/data", profile, myInit);
  }
  async doCustomExport(profile) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    // let body = {};

    return await axios.post(
      BI_API_BASE_URL + "/export/custom-export",
      profile,
      myInit
    );
  }

  async fetchSmapleData(profile) {
    // console.log("profile",profile)
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    // let body = {};

    return await axios.post(
      BI_API_BASE_URL + "/sample/advancedsearch",
      profile,
      myInit
    );
  }

  async saveProfile(profile) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = profile;

    return await axios.post(
      BI_API_BASE_URL + "/export/user/save-profile",
      body,
      myInit
    );
  }

  async getProfiles(clientName) {
    let myInit = {
      params: {
        // query: `${clientName}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/export/user/profiles", myInit);
  }
  async getCustomExportProfiles() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/export/get-custom-export-clients",
      myInit
    );
  }

  async getFilters(clientName) {
    let myInit = {
      params: {
        // query: `${clientName}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/export/filters", myInit);
  }
  async getFiltersAdvancedSearch() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/sample/advance-search-filters",
      myInit
    );
  }

  async getFieldHeaders(client, project) {
    let myInit = {
      params: {
        client: `${client}`,
        projects: project,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/export/fields", myInit);
  }

  async getAllClients(clientName) {
    let myInit = {
      params: {
        query: `${clientName}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/export/search/clients", myInit);
  }

  async getClientProjects(clientName, project) {
    let myInit = {
      params: {
        client: `${clientName}`,
        query: `${project}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/export/search/projects", myInit);
  }

  async advancedSearch(filterObj) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = filterObj;

    return await axios.post(
      BI_API_BASE_URL + "/sample/advanced-file-search",
      body,
      myInit
    );
  }

  async uploadFile(file) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = file;

    return await axios.post(
      BI_API_BASE_URL + "/sample/file/inventory-code",
      body,
      myInit
    );
  }

  async exportSearch(list) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = list;

    return await axios.post(BI_API_BASE_URL + "/sample/export", body, myInit);
  }
  async exportAdvancedSearch(prf) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = prf;

    return await axios.post(
      BI_API_BASE_URL + "/sample/bulkexport",
      body,
      myInit
    );
  }

  async bulkSearch(list) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = list;

    return await axios.post(
      BI_API_BASE_URL + "/sample/bulk-search/inventory-code",
      body,
      myInit
    );
  }

  async getSingleCode(id) {
    let myInit = {
      params: {
        inventory_code: `${id}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/sample/search/inventory-code",
      myInit
    );
  }

  async getSingleMoreData(id, dataset) {
    let myInit = {};

    // if (parent) {
    myInit = {
      params: {
        inventory_code: `${id}`,
        dataset: `${dataset}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    // } else {
    //   myInit = {
    //     params: {
    //       inventory_code: `${id}`,
    //     },
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${(await Auth.currentSession())
    //         .getIdToken()
    //         .getJwtToken()}`,
    //     },
    //   };
    // }

    return await axios.get(
      BI_API_BASE_URL + "/sample/detailed/inventory-code",
      myInit
    );
  }

  async getCodeList(id) {
    let myInit = {
      params: {
        query: `${id}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/sample/list/inventory-code",
      myInit
    );
  }

  async mapManifestPreview(file, mapping) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("mapping", JSON.stringify(mapping));

    return await axios.post(
      BI_API_BASE_URL + "/manifest/map/preview/table",
      formData,
      myInit
    );
  }

  async previewOrig(file) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    const formData = new FormData();
    formData.append("file_obj", file);

    return await axios.post(
      BI_API_BASE_URL + "/manifest/map/preview/orig",
      formData,
      myInit
    );
  }

  async mapManifestExport(file, mapping) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("mapping", JSON.stringify(mapping));

    return await axios.post(
      BI_API_BASE_URL + "/manifest/map/preview/file",
      formData,
      myInit
    );
  }

  async getAllServices() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/navigation/service/directory",
      myInit
    );
  }

  async searchServices(query) {
    let myInit = {
      params: {
        query,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/navigation/service/search",
      myInit
    );
  }

  async getSidebar(route) {
    let myInit = {
      params: {
        route,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/navigation/service/sidebar",
      myInit
    );
  }

  async checkAccess(service_id) {
    let myInit = {
      params: {
        service_id,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/navigation/service/access-level",
      myInit
    );
  }

  async searchProjects(client, query) {
    let myInit = {
      params: {
        client,
        query,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/export/search/projects", myInit);
  }

  async ingestManifestFile(file, client, project, uniqueBarcodeData, profile) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("client", client);
    formData.append("project", project);
    formData.append("barcode_received", JSON.stringify(uniqueBarcodeData));
    if (profile) {
      formData.append("profile", profile);
    }
    return await axios.post(
      BI_API_BASE_URL + "/accession/accession-samples/file",
      formData,
      myInit
    );
  }
  async getEditHistory(client, project, inventory_code) {
    let myInit = {
      params: {
        client,
        project,
        inventory_code,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/edit/get-edit-history-data",
      myInit
    );
  }
  async getSources() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/export/search/sources", myInit);
  }
  async getContainers() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/export/search/containers",
      myInit
    );
  }
  async getAllCarriers() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/export/search/carriers", myInit);
  }
}

export default new BiService();
