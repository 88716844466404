import ArchitectureIcon from "@mui/icons-material/Architecture";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SaveIcon from "@mui/icons-material/Save";
import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormContainer } from "react-hook-form-mui";
import { useDispatch } from "react-redux";
import FileUpload from "../../components/FileUpload";
import UIWrapper from "../../components/UIWrapper";
import ProjectManagementService from "../../services/ProjectManagementService";
import { mapManifestActions } from "../../Store/map-manifest";
import { snackbarActions } from "../../Store/snackbar";
import DesignManifest from "./Steps/DesignManifest";
import SaveDesign from "./Steps/SaveDesign";

const icons = {
  1: <FileUploadOutlinedIcon />,
  2: <ArchitectureIcon />,
  3: <SaveIcon />,
};

const steps = ["Upload Manifest", "Design", "Save"];

const ManifestMapper = (props) => {
  const [files, setFiles] = useState(null);
  const [sampleContent, setSampleContent] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [clientOptions, setClientOptions] = useState([]);
  const [mappingOptions, setMappingOptions] = useState([]);
  const [clientState, setClientState] = useState("");
  const [mappingState, setMappingState] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    ProjectManagementService.getAllClients("").then((resp) =>
      setClientOptions(resp.data)
    );
    return;
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        ProjectManagementService.getManifestProfiles(clientState).then((resp) =>
          setMappingOptions(resp.data)
        ),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [clientState]);

  useEffect(() => {
    if (clientState && mappingState && files) {
      ProjectManagementService.previewOrig(files)
        .then((resp) => {
          setSampleContent(resp.data);
          ProjectManagementService.getMappingProfile(clientState, mappingState)
            .then((resp) => {
              dispatch(mapManifestActions.initTemplate(resp.data));
              setActiveStep(1);
            })
            .catch((err) => {
              dispatch(
                snackbarActions.showNotification({
                  snackbarOpen: true,
                  snackbarType: "error",
                  snackbarMessage: "Failed Preview",
                })
              );
            });
        })
        .catch((err) => {
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: "Failed Preview",
            })
          );
        });
    } else if (files) {
      ProjectManagementService.previewOrig(files)
        .then((resp) => {
          setSampleContent(resp.data);
          dispatch(mapManifestActions.init(Object.keys(resp.data[0])));
          setActiveStep(1);
        })
        .catch((err) => {
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: "Failed Preview",
            })
          );
        });
    }
  }, [clientState, mappingState, files]);

  useEffect(() => {
    dispatch(mapManifestActions.switch());
  }, [activeStep]);

  return (
    <UIWrapper
      title="Map Manifest"
      stepper
      paper
      icons={icons}
      steps={steps}
      activeStep={activeStep}
    >
      {activeStep == 0 && (
        <Grid
          container
          spacing={2}
          sx={{ mt: 1 }}
          align="center"
          justify="center"
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <Typography sx={{ mt: 2 }}>
                {"Optional: Select a Template Mapping Profile"}
              </Typography>
              <Divider orientation="horizontal" sx={{ width: "100%" }} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormContainer>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                size="small"
                margin="dense"
                options={clientOptions}
                onChange={(e, new_value) => setClientState(new_value)}
                renderInput={(params) => (
                  <TextField margin="dense" {...params} label="Client" />
                )}
                value={clientState}
                sx={{ minWidth: 275 }}
              />
              <TextField
                value={mappingState}
                onChange={(e) => setMappingState(e.target.value)}
                select // tell TextField to render select
                label="Profile"
                size="small"
                margin="dense"
                sx={{ width: "100%" }}
                disabled={!clientState}
              >
                {mappingOptions.map((mapping) => (
                  <MenuItem key={mapping.name} value={mapping.name}>
                    {mapping.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormContainer>
          </Grid>
          <Grid item xs={12}>
            <Divider flexItem sx={{ maxWidth: 400 }}>
              <Typography>or simply</Typography>
            </Divider>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <FileUpload files={files} setFiles={setFiles} accept={["csv"]} />
            </Box>
          </Grid>
        </Grid>
      )}
      {activeStep == 1 && (
        <DesignManifest
          files={files}
          setActiveStep={setActiveStep}
          sampleContent={sampleContent}
        />
      )}
      {activeStep == 2 && <SaveDesign setActiveStep={setActiveStep} />}
    </UIWrapper>
  );
};

export default ManifestMapper;
