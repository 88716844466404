import {
  AddIcon,
  RemoveCircleOutlineIcon,
  IconButton,
  MenuItem,
  TextField,
  Fab,
  BoxSystem,
} from "../../../mui";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mapManifestActions } from "../../../Store/map-manifest";

const conditions = [
  {
    value: "equals",
  },
  {
    value: "not equals",
  },
  {
    value: "contains",
  },
];

const ConditionInput = ({ index }) => {
  const dispatch = useDispatch();
  const order = useSelector((state) => state.mapManifest.order);
  const [column, setColumn] = useState("");
  const [condition, setCondition] = useState("");
  const [value, setValue] = useState("");

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          mapManifestActions.conditionColumnMap({ index, column: column })
        ),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [column]);

  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(mapManifestActions.conditionValueMap({ index, value })),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [value]);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          mapManifestActions.conditionConditionMap({ index, condition })
        ),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [condition]);

  return (
    <BoxSystem
      sx={{
        display: "flex",
        m: 1,
      }}
    >
      <TextField
        id="standard-adornment-weight"
        size="small"
        value={column}
        select
        label="Column"
        onChange={(event) => setColumn(event.target.value)}
        sx={{ minWidth: 200 }}
      >
        {order.map((option) => (
          <MenuItem key={option.id} value={option.name}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="standard-adornment-weight"
        size="small"
        value={condition}
        select
        label="Condition"
        onChange={(event) => setCondition(event.target.value)}
        sx={{ minWidth: 150, mr: 1, ml: 1 }}
      >
        {conditions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.value}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="standard-adornment-weight"
        size="small"
        value={value}
        label="Value"
        onChange={(event) => setValue(event.target.value)}
        sx={{ minWidth: 200 }}
      />
      <IconButton
        color="yellow"
        onClick={() => dispatch(mapManifestActions.deleteCondition(index))}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </BoxSystem>
  );
};

const Conditions = () => {
  const rules = useSelector((state) => state.mapManifest.rules);
  const dispatch = useDispatch();

  return (
    <>
      {rules.length &&
        rules[rules.length - 1]["rule"]["condition"].map((key, index) => (
          <ConditionInput index={index} key={index} />
        ))}
      <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        size="small"
        sx={{ mt: 2, mb: 2 }}
        onClick={() => dispatch(mapManifestActions.addCondition())}
      >
        <AddIcon sx={{ mr: 1 }} />
        Condition&nbsp;
      </Fab>
    </>
  );
};

export default Conditions;
