import { Auth } from "aws-amplify";
import axios from "axios";

const APT_API_BASE_URL = process.env.APT_API;

class AptService {
  async getIP() {
    let myInit = {
      params: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await axios.get(APT_API_BASE_URL, myInit);
  }

  async postProjects(client_id, array_id, projectName) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    let body = {
      client_id: `${client_id}`,
      array_id: `${array_id}`,
      project_name: `${projectName}`,
    };

    return await axios.post(
      APT_API_BASE_URL + "/client/new-project",
      body,
      myInit
    );
  }
  async postClients(name) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    let body = `${name}`;

    return await axios.post(
      APT_API_BASE_URL + "/client/new-client",
      body,
      myInit
    );
  }
  async updateClients(name, newName) {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    let body = {
      client_name: name,
      new_client_name: newName,
    };
    return await axios.post(
      APT_API_BASE_URL + "/client/update-client",
      body,
      config
    );
  }
  async deactivateClients(name) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    let body = `${name}`;

    return await axios.post(
      APT_API_BASE_URL + "/client/deactivate-client",
      body,
      myInit
    );
  }

  async getAllClient() {
    let myInit = {
      params: {
        client_name: "string",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await axios.get(APT_API_BASE_URL + "/client/all-clients", myInit);
  }
  async getAllProjects() {
    let myInit = {
      params: {
        client_name: "string",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await axios.get(APT_API_BASE_URL + "/client/all-projects", myInit);
  }

  async getInputPath(newPath) {
    let myInit = {
      params: {
        path: `${newPath}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(APT_API_BASE_URL + "/nextflow/list-inputs", myInit);
  }

  async getWorkflows(list) {
    let myInit = {
      params: {
        status: list,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(APT_API_BASE_URL + "/nextflow/workflows", myInit);
  }

  async getWorkflowsSpec() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      APT_API_BASE_URL + "/nextflow/workflows?status=QUEUED&status=RUNNING",
      myInit
    );
  }

  async checkStatus(workflow_id) {
    let myInit = {
      params: {
        workflow_id: workflow_id,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      APT_API_BASE_URL + `/nextflow/status/${workflow_id}`,
      myInit
    );
  }

  async getWorkflowLog(workflow_id) {
    let myInit = {
      params: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      APT_API_BASE_URL + `/nextflow/log/${workflow_id}`,
      myInit
    );
  }

  async cancelWorkflow(workflow_id) {
    let myInit = {
      params: {
        workflow_id: `${workflow_id}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      APT_API_BASE_URL + `/nextflow/cancel/${workflow_id}`,
      myInit
    );
  }

  async runAptTool(obj) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    // let body =  {
    //     "client": obj.project,
    //     "wf_params": obj,
    // }
    let body = {};
    if (obj.project === "test") {
      body = {
        client: "GenomicsPLC",
        project: "GenomicsPLC",
        wf_params: {
          mani_file:
            "/isilon/Brookslab/Affymetrix/GenomicsPLC/GenomicsPLC_Analysis/GenomicsPLC_Manifests/GenomicsPLC_P7_recluster_Manifest.xlsx",
          cel_dir:
            "/isilon/Brookslab/Affymetrix/GenomicsPLC/GenomicsPLC_Data/GenomicsPLC_P7R/CEL_files",
          pl_num: "P7_recluster",
          fqc_file:
            "/isilon/Brookslab/Affymetrix/GenomicsPLC/FQC_Genders/Genomics_P7R_QC_Genders.xlsx",
          concordance_out:
            "/isilon/Brookslab/Affymetrix/GenomicsPLC/GenomicsPLC_Analysis/Exported_QC/GenderConcordance",
          batch_date: "3-18-21",
        },
        nf_params: {},
        file_inputs: "string",
      };
    } else {
      body = {
        client: obj.project,
        project: obj.project,
        wf_params: {
          mani_file: obj.mani_file,
          cel_dir: obj.cel_dir,
          pl_num: obj.pl_num,
          fqc_file: obj.fqc_file,
          concordance_out: obj.concordance_out,
          batch_date: obj.batch_date,
        },
        nf_params: {},
        file_inputs: "string",
      };
    }

    return await axios.post(
      APT_API_BASE_URL + "/nextflow/trigger/apt-tools-workflow",
      body,
      myInit
    );
  }
}

export default new AptService();
