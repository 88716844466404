import FactCheckIcon from "@mui/icons-material/FactCheck";
import HubIcon from "@mui/icons-material/Hub";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PreviewIcon from "@mui/icons-material/Preview";
import ScienceIcon from "@mui/icons-material/Science";
import { useEffect, useState } from "react";
import UIWrapper from "../../components/UIWrapper";
import useRequest from "../../hooks/use-request";
import CoreDetails from "./NewProjectSteps/CoreDetails";
import ReviewSteps from "./NewProjectSteps/ReviewSteps";
import StepTemplate from "./NewProjectSteps/StepTemplate";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

const icons = {
  1: <HubIcon />,
  2: <LocalShippingIcon />,
  3: <ScienceIcon />,
  4: <FactCheckIcon />,
  5: <AccountTreeIcon />,
  6: <NotificationsActiveIcon />,
  7: <PostAddIcon />,
  8: <PreviewIcon />,
};

const steps = [
  "Details",
  "Accessioning",
  "Aliquoting",
  "Quality Control",
  "Services",
  "Status Updates",
  "Meta",
  "Review",
];

const UpdateProject = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [clientState, setClientState] = useState("");
  const [projectName, setProjectName] = useState("");
  const [coreMapping, setCoreMapping] = useState({});
  const [clientOptions, setClientOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [accRows, setAccRows] = useState([]);
  const [aliRows, setAliRows] = useState([]);
  const [qcRows, setQCRows] = useState([]);
  const [servicesRows, setServicesRows] = useState([]);
  const [suRows, setSURows] = useState([]);
  const [postRows, setPostRows] = useState([]);
  const { sendRequest: mappingAPI } = useRequest();

  useEffect(() => {
    mappingAPI(
      {
        url: "/new-project/search/clients",
        method: "GET",
      },
      (data) => setClientOptions(data.sort())
    );
    return;
  }, [setClientOptions]);

  useEffect(() => {
    if (clientState && projectName) {
      mappingAPI(
        {
          url: "/new-project/update-details",
          method: "GET",
          params: {
            client: clientState,
            project: projectName,
          },
        },
        (data) => {
          setCoreMapping(data.core);
          setAccRows(data.accessioning);
          setAliRows(data.aliquot);
          setQCRows(data.qc);
          setServicesRows(data?.services || []);
          setSURows(data.status_updates);
          setPostRows(data.post);
        }
      );
    }
    return;
  }, [
    clientState,
    projectName,
    setCoreMapping,
    setAccRows,
    setAliRows,
    setQCRows,
    setServicesRows,
    setSURows,
    setPostRows,
  ]);

  useEffect(() => {
    if (clientState) {
      mappingAPI(
        {
          url: "/new-project/search/projects",
          method: "GET",
          params: {
            client: clientState,
          },
        },
        (data) => setProjectOptions(data.sort())
      );
    }
  }, [clientState, setProjectOptions]);

  const onSubmit = () => {
    mappingAPI(
      {
        url: "/new-project/update",
        method: "POST",
        body: {
          client: clientState,
          project: projectName,
          core: coreMapping,
          accession: accRows,
          aliquot: aliRows,
          su: suRows,
          qc: qcRows,
          services: servicesRows,
          meta: postRows,
        },
        success: true,
      },
      () => {
        setActiveStep(0);
        setClientState("");
        setProjectName("");
        setCoreMapping({});
      }
    );
  };

  const accDirections = `
        Please fill out this table according to the client's needs for this project. Make sure to correctly map the Client Header as required (e.g. subject_code => USUBJID).
        All fields are editable. Some headers may be unavailable for the project and may be dropped. Only add fields that are available at accessioning to this table.
  `;

  const aliDirections = `
        Please fill out this table according to the client's nomenclature for values derived during Aliquoting. Make sure to correctly map the Client Header as required (e.g. vial_condition => DEVIATION).
        All fields are editable. Some headers may be unavailable for the project and may be dropped.
  `;

  const qcDirections = `
        Please fill out this table according to the client's nomenclature for values derived during Quality Control. Make sure to correctly map the Client Header as required (e.g. 260_280 => Purity).
        All fields are editable. Some headers may be unavailable for the project and may be dropped.
  `;

  const servicesDirections = `
  Please fill out this table according to the client's nomenclature for values derived during Service performed on the sample. Make sure to correctly map the Client Header as required (e.g. 260_280 => Purity).
  All fields are editable. Some headers may be unavailable for the project and may be dropped.
`;

  const suDirections = `
        Please fill out this table according to the client's nomenclature for values derived during Status Updates (e.g. Shipping or Disposal). Make sure to correctly map the Client Header as required (e.g. shipped_date => Shipped Date).
        All fields are editable. Some headers may be unavailable for the project and may be dropped.
  `;

  const postDirections = `
        Please add any columns that were not present in the last three stages. For example, if a value is uploaded into SampledSphere separate from files transferred from LIMS (e.g. AF, SU, DDS), make note of them here by using the client's nomenclature.
  `;

  return (
    <UIWrapper
      title="New Project"
      stepper
      paper
      icons={icons}
      steps={steps}
      activeStep={activeStep}
    >
      {activeStep == 0 && (
        <CoreDetails
          clientOptions={clientOptions}
          projectOptions={projectOptions}
          setActiveStep={setActiveStep}
          setProjectName={setProjectName}
          projectName={projectName}
          clientState={clientState}
          setClientState={setClientState}
          coreMapping={coreMapping}
          setCoreMapping={setCoreMapping}
        />
      )}
      {activeStep == 1 && (
        <StepTemplate
          step="accessioning"
          setActiveStep={setActiveStep}
          rows={accRows}
          setRows={setAccRows}
          allowAddField
          directions={accDirections}
        />
      )}
      {activeStep == 2 && (
        <StepTemplate
          step="aliquot"
          setActiveStep={setActiveStep}
          rows={aliRows}
          setRows={setAliRows}
          directions={aliDirections}
        />
      )}
      {activeStep == 3 && (
        <StepTemplate
          step="qc"
          setActiveStep={setActiveStep}
          rows={qcRows}
          setRows={setQCRows}
          directions={qcDirections}
        />
      )}
      {activeStep == 4 && (
        <StepTemplate
          step="services"
          setActiveStep={setActiveStep}
          rows={servicesRows}
          setRows={setServicesRows}
          directions={servicesDirections}
        />
      )}
      {activeStep == 5 && (
        <StepTemplate
          step="su"
          setActiveStep={setActiveStep}
          rows={suRows}
          setRows={setSURows}
          directions={suDirections}
        />
      )}
      {activeStep == 6 && (
        <StepTemplate
          step="post"
          setActiveStep={setActiveStep}
          rows={postRows}
          setRows={setPostRows}
          allowAddField
          directions={postDirections}
        />
      )}
      {activeStep == 7 && (
        <ReviewSteps
          client={clientState}
          project={projectName}
          coreMapping={coreMapping}
          setActiveStep={setActiveStep}
          onSubmit={onSubmit}
          rows={[
            ...accRows.map((obj) => {
              return { ...obj, stage: "Accessioning" };
            }),
            ...aliRows.map((obj, index) => {
              return {
                ...obj,
                id: index + accRows.length,
                required: false,
                is_inheritable: false,
                hierarchy: "N/A",
                stage: "Aliquot",
              };
            }),
            ...qcRows.map((obj, index) => {
              return {
                ...obj,
                id: index + aliRows.length + accRows.length,
                required: false,
                is_inheritable: false,
                hierarchy: "N/A",
                stage: "Quality Control",
              };
            }),
            ...servicesRows.map((obj, index) => {
              return {
                ...obj,
                id: index + aliRows.length + accRows.length + qcRows.length,
                required: false,
                is_inheritable: false,
                hierarchy: "N/A",
                stage: "Services",
              };
            }),
            ...postRows.map((obj, index) => {
              return {
                ...obj,
                id:
                  index +
                  aliRows.length +
                  accRows.length +
                  servicesRows.length +
                  qcRows.length,
                required: false,
                is_inheritable: false,
                hierarchy: "N/A",
                stage: "Meta",
              };
            }),
          ]}
        />
      )}
    </UIWrapper>
  );
};

export default UpdateProject;
