import React from "react";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ParentWrapper from "../../components/ParentWrapper";
import PrivateRoute from "../../components/PrivateRoute";
import UserAppManager from "../../../src/pages/UserAppManager";
import PendingAccession from "../PendingAccession/PendingAccession";
import DataUpdation from "./AF2DataUpdation/DataUpdation";
const queryClient = new QueryClient();

const ParentConsole = () => {
  let { path } = useRouteMatch();
  return (
    <QueryClientProvider client={queryClient}>
      <ParentWrapper path={path}>
        <PrivateRoute exact path={`${path}/pending-accession`}>
          <PendingAccession />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}/data-updation`}>
          <DataUpdation />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}/users`}>
          <UserAppManager service={`${path}`} />
        </PrivateRoute>
        <Route>
          <Redirect to={`${path}/pending-accession`} />
        </Route>
      </ParentWrapper>
    </QueryClientProvider>
  );
};

export default ParentConsole;
