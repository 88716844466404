import React from "react";
import { Stack, Typography } from "../../mui";

const privacyUrl = "https://sampled.com/legal/client-portals/privacy-policy/";
const termsUrl = "https://sampled.com/legal/client-portals/terms-of-use/";

const handleRedirect = (url) => {
  window.open(url, "_blank", "noreferrer noopener");
};

const Footer = () => {
  return (
    <Stack marginTop="auto" height="2rem">
      <Stack
        width="100%"
        sx={{ bgcolor: "#637697" }}
        p="0.3rem"
        position="absolute"
        bottom="-6px"
        left="-5px"
        zIndex="1210"
        borderRadius="0 0 0.4rem 0.4rem"
      >
        <Stack gap={2} direction="row" justifyContent="center">
          <Typography
            color="white"
            onClick={() => handleRedirect(privacyUrl)}
            fontSize="1rem"
          >
            Privacy Policy
          </Typography>
          <Typography color="white" fontSize="1rem">
            |
          </Typography>
          <Typography
            color="white"
            onClick={() => handleRedirect(termsUrl)}
            fontSize="1rem"
          >
            Terms of Use
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;
