import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Auth } from "aws-amplify";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDebounce } from "use-debounce";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "../../mui";
import Service from "./Service";

const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 3,
};

const BASE_URL = process.env.ADMIN_API;

const ServicesManager = (props) => {
  const [serviceQuery, setServiceQuery] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [tableBulkData, setTableBulkData] = useState([]);
  const [pageLarge, setPageLarge] = useState(0);
  const [rowsPerPageLarge, setRowsPerPageLarge] = useState(6);
  const [hidden, setVisibility] = useState(true);

  const debouncedFilter = useDebounce(serviceQuery, 333);
  const tableRowData = ["Full Name", "User Name", "Role"];

  const userProfile = JSON.parse(sessionStorage.getItem("userProfile"));

  const fetchServices = async () => {
    const response = await axios.get(
      `${BASE_URL}/admin/search/service?query=${serviceQuery}&service_type=${userProfile.user_type}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      }
    );
    return response.data;
  };

  const { isLoading, isSuccess, error, data } = useQuery(
    ["services", debouncedFilter],
    () => fetchServices(debouncedFilter),
    { keepPreviousData: true, enabled: Boolean(debouncedFilter) }
  );

  const fetchAllservices = async () => {
    const response = await axios.get(
      `${BASE_URL}/admin/search/service?service_type=${userProfile.user_type}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      }
    );
    return response.data;
  };

  const {
    isLoading: isLoadingAll,
    isSuccess: isSuccessAll,
    error: errorAll,
    data: dataAll,
  } = useQuery(["allservices"], () => fetchAllservices(), {
    keepPreviousData: true,
    enabled: true,
  });

  const allServices =
    isSuccessAll &&
    isSuccess &&
    dataAll.map((item) => {
      return item;
    });

  const services = [];
  const filteredServices = [];

  for (let i = 0; i < allServices.length; i++) {
    services.push({ label: allServices[i].service_name });
  }

  if (services.filter((s) => s.label === props.service)) {
    let filtered = props.service;
    if (filtered === undefined) {
      filtered = "";
    }
    if (filtered === props.service) {
      let searchFilter = filtered.split("/");
      filteredServices.push(searchFilter[1]);
      useEffect(() => {
        setInputValue(filteredServices[0]);
        setServiceQuery(filteredServices[0]);
        setVisibility(false);
        if (filteredServices[0] === "") {
          setServiceQuery(false);
        }
      });
    }
  }

  return (
    <>
      <section
        style={{
          display: "flex",
          justifyContent: "left",
        }}
      >
        <Autocomplete
          style={{ visibility: hidden ? "visible" : "hidden" }}
          disablePortal
          id="service-select"
          options={props.service ? filteredServices : services}
          sx={{ width: 300 }}
          inputValue={inputValue}
          onInputChange={(_event, serviceInputValue) => {
            setInputValue(serviceInputValue);
            setServiceQuery(serviceInputValue);
            if (serviceInputValue === "") {
              setServiceQuery(false);
            }
          }}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          renderInput={(params) => (
            <TextField
              {...params}
              disabled
              label="Select Service"
              variant="outlined"
              required
            />
          )}
        />

        {/* This will be the new search  */}
        {/* <span
          style={{
            display: "flex"
          }}>
          <Button
            variant="outlined"
            // onClick={handleClickOpen}
            style={{ border: "none", padding: "0px" }}
          >
            <PersonAddIcon style={{ padding: "0px" }} />
          </Button>
          <TextField
            id="outlined-basic"
            label="Search User"
            variant="outlined"
          // onChange={handleChange}
          />
        </span> */}
      </section>
      <TableContainer style={{ marginTop: "15px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size={"small"}>
          <TableHead sx={{ minWidth: 650 }}>
            <TableRow sx={{ minWidth: 650 }}>
              {tableRowData.map((row, index) => (
                <TableCell
                  align="left"
                  key={index}
                  style={{ fontWeight: "bold" }}
                >
                  {row}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ border: "none" }}>
            {isSuccess &&
              data.map((service) => (
                <>
                  <Service key={service.service_id} service={service} />
                </>
              ))}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[6, 10, 25]}
          component="div"
          count={tableBulkData.length}
          rowsPerPage={rowsPerPageLarge}
          page={pageLarge}
          onPageChange={(event, newPage) => {
            console.log(newPage);
            setPageLarge(newPage);
          }}
        />
      </TableContainer>
      {isLoading && <span>Loading..</span>}
      {error && <span>Error!</span>}
      {isSuccess && !data.length && null}
    </>
  );
};

export default ServicesManager;
