import { useTheme } from "@mui/material/styles";
import { Box, Paper, Typography } from "../../mui";
import UIBreadcrumbs from "../UIBreadcrumbs";
import UIStepper from "./UIStepper";

const UIWrapper = (props) => {
  const theme = useTheme();
  const { title, stepper, paper, steps, activeStep, icons } = props;

  return (
    <Box>
      <UIBreadcrumbs />
      {stepper ? (
        <Box component={paper ? Paper : null} p={2} mt={2} sx={{borderRadius: 2}} variant={theme.palette.mode == "light" ? "elevation" : "outlined"}>
          <UIStepper steps={steps} activeStep={activeStep} icons={icons} />
          {paper && props.children}
        </Box>
      ) : (
        <Typography variant="h4" color={theme.palette.mode == "light" ? "secondary.dark" : "#c9d1d9"} sx={{ mt: 2, mb: 1 }}>
          {title}
        </Typography>
      )}
      {(!stepper && paper) && <Box component={Paper} p={2} mt={2} sx={{borderRadius: 2}} variant={theme.palette.mode == "light" ? "elevation" : "outlined"}>{props.children}</Box>}
      {(!stepper && !paper) && <Box>{props.children}</Box>}
    </Box>
  );
};

export default UIWrapper;
