import ServiceSelected from "./userManageComponents/ServiceSelected";
import { Divider } from "../mui";
import { HeaderText } from "../components/Text/HeaderText";
import { QueryClient, QueryClientProvider } from "react-query";
import UIWrapper from "../components/UIWrapper";
import { Auth } from "aws-amplify";
const queryClient = new QueryClient();

function UserSelectedServiceManager(props) {
  return (
    <>
      <UIWrapper title="User Management" paper>
        <QueryClientProvider client={queryClient}>
          <ServiceSelected service={props.service} />
        </QueryClientProvider>
      </UIWrapper>
    </>
  );
}

export default UserSelectedServiceManager;
