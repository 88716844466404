import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

export default function PageNav(props) {
  const theme = useTheme();

  return (
    <MobileStepper
      variant="dots"
      steps={2}
      position="static"
      activeStep={props.activeStep}
      sx={{ maxWidth: 400, flexGrow: 1 }}
      style={{marginLeft:"40px", paddingBottom: "15px"}}
      nextButton={
        <Button size="small" onClick={props.handleNext} disabled={props.activeStep === 1}>
          Next
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      }
      backButton={
        <Button size="small" onClick={props.handleBack} disabled={props.activeStep === 0}>
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
          Back
        </Button>
      }
    />
  );
}
