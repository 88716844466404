import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  TextField,
  Stack,
  Button,
  Grid,
  Paper,
  Typography,
  InputLabel,
  Select,
} from "@mui/material";
// import { makeStyles } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import AcsService from "../../services/AcsService";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { userTimeZone, timeZonesList } from "../../components/helper/timeZone";

const carrierTypes = [
  {
    value: "BIOCAIR",
    label: "BIOCAIR",
  },
  {
    value: "DHL",
    label: "DHL",
  },
  {
    value: "FedEx",
    label: "FedEx",
  },
  {
    value: "FedEx Ground",
    label: "FedEx Ground",
  },
  {
    value: "Hand Delivered",
    label: "Hand Delivered",
  },
  {
    value: "Marken",
    label: "Marken",
  },
  {
    value: "Priority Express",
    label: "Priority Express",
  },
  {
    value: "Quickstat",
    label: "Quickstat",
  },
  {
    value: "TNT",
    label: "TNT",
  },
  {
    value: "UPS",
    label: "UPS",
  },
  {
    value: "USA Courier",
    label: "USA Courier",
  },
  {
    value: "USPS",
    label: "USPS",
  },
  {
    value: "VIP Cargo",
    label: "VIP Cargo",
  },
  {
    value: "World Courrier",
    label: "World Courrier",
  },
];

const steps = ["Client", "Project", "Subject Code", "Visit", "Tracking Number"];

export default function AcsFormInputs(props) {
  const [listLarge, setListLarge] = React.useState([]);
  const [siteList, setSiteList] = React.useState([]);

  const [trackingBool, setTrackingBool] = React.useState();
  const [count, setCount] = React.useState(1);
  const [projectList, setProjectList] = React.useState([]);

  useEffect(() => {
    console.log(props.client);
    AcsService.getClientProjects(props.val, "")
      .then((resp) => {
        setProjectList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.val]);

  useEffect(() => {
    AcsService.getAllClients(props.client)
      .then((resp) => {
        console.log(resp.data);
        setListLarge(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.client]);

  useEffect(() => {
    if (props.client && props.project) {
      AcsService.getAllSites(
        props.client,
        props.project,
        props.formListHeader["site"]
      )
        .then((resp) => {
          console.log(resp.data);
          setSiteList(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props.project, props.formListHeader["site"]]);

  return (
    <Stack>
      <FormControl
        style={{
          paddingLeft: "35px",
          paddingRight: "35px",
          paddingBottom: "15px",
          paddingTop: "15px",
        }}
      >
        <Paper
          // className={classes.paperBg}
          style={{ padding: "10px", margin: "15px" }}
          variant="outlined"
        >
          <Stack direction="row" style={{ width: "100%" }}>
            <Autocomplete
              id="tags-standard"
              freeSolo
              inputValue={props.client || ""}
              onInputChange={(e, newValue) => props.setClient(newValue)}
              options={listLarge}
              onChange={(e, newValue) => props.setVal(newValue)}
              getOptionLabel={(option) =>
                typeof option === "string" || option instanceof String
                  ? option
                  : ""
              }
              style={{
                margin: "10px",
                width: "100%",
              }}
              value={props.val}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="standard-multiline-flexible"
                  size="small"
                  type="text"
                  variant="filled"
                  label="Client"
                />
              )}
            />

            <FormControl
              variant="filled"
              sx={{
                margin: "10px",
                width: "100%",
              }}
              size="small"
              // disabled={val ? false : true}
            >
              <InputLabel id="filter-id">Select Project</InputLabel>
              <Select
                labelId="filter-id"
                value={props.project}
                label="Filter"
                onChange={(event) => props.setProject(event.target.value)}
                disabled={props.client ? false : true}
              >
                {projectList.map((ele, index) => (
                  <MenuItem key={index} value={ele}>
                    {ele}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label={"Subject Code"}
              type="text"
              variant="filled"
              style={{
                textAlign: "center",
                color: "white",
                margin: "10px",
                width: "100%",
              }}
              size="small"
              value={props.subjectCode || ""}
              onChange={(e) => props.setSubjectCode(e.target.value)}
            />
            <FormControl
              variant="filled"
              style={{
                margin: "10px",
                width: "100%",
              }}
              size="small"
              onSubmit={() => console.log("submit")}
            >
              <TextField
                autoFocus
                label="Tracking Number"
                type="text"
                variant="filled"
                style={{
                  width: "100%",
                }}
                size="small"
                value={props.trackingNumber}
                name="clientName"
                onChange={(e) => props.setTrackingNumber(e.target.value)}
                disabled={props.project ? false : true}
              />
            </FormControl>
          </Stack>

          <Stack direction="row" style={{ width: "100%" }}>
            <Autocomplete
              id="tags-standard"
              freeSolo
              inputValue={props.formListHeader["site"] || ""}
              onInputChange={(e) =>
                props.handleHeaderChange("site", e.target.value)
              }
              options={siteList}
              onChange={(e, newValue) => props.handleSiteChange(newValue)}
              getOptionLabel={(option) =>
                typeof option === "string" || option instanceof String
                  ? option
                  : ""
              }
              style={{
                margin: "10px",
                width: "100%",
              }}
              disabled={props.client && props.project ? false : true}
              value={null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="standard-multiline-flexible"
                  size="small"
                  type="text"
                  variant="filled"
                  label="Sites"
                />
              )}
            />

            <TextField
              id="date"
              label="Date Received"
              type="date"
              variant="filled"
              style={{
                textAlign: "center",
                color: "white",
                margin: "10px",
                width: "100%",
              }}
              value={props.formListHeader["date_received"] || ""}
              onChange={(e) =>
                props.handleHeaderChange("date_received", e.target.value)
              }
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="time"
              label="Time Received"
              type="time"
              variant="filled"
              style={{
                textAlign: "center",
                color: "white",
                margin: "10px",
                width: "100%",
              }}
              value={props.formListHeader["time_received"] || ""}
              onChange={(e) =>
                props.handleHeaderChange("time_received", e.target.value)
              }
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Autocomplete
              id="tags-tz"
              freeSolo
              value={props.formListHeader["dt_received_timezone"]}
              options={timeZonesList}
              onChange={(e, new_value) =>
                props.handleHeaderChange("dt_received_timezone", new_value)
              }
              getOptionLabel={(option) =>
                typeof option === "string" || option instanceof String
                  ? option
                  : ""
              }
              sx={{
                margin: "10px",
                width: "100%",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  required={true}
                  id="user-tz"
                  size="small"
                  type="text"
                  name={"user_timezone"}
                  label={"Select Timezone"}
                />
              )}
            />
            <TextField
              label={"Carrier"}
              select
              variant="filled"
              style={{
                textAlign: "center",
                color: "white",
                margin: "10px",
                width: "100%",
              }}
              size="small"
              // inputProps={{ className: classes.input }}
              // InputLabelProps={{ className: classes.inputLabel }}
              value={props.formListHeader["carrier"] || ""}
              onChange={(e) =>
                props.handleHeaderChange("carrier", e.target.value)
              }
            >
              {carrierTypes.map((option) => (
                <MenuItem key={option.value} value={option.value || ""}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Paper>
      </FormControl>
    </Stack>
  );
}
