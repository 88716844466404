import React, { useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stack from "@mui/material/Stack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AptService from "../../services/AptService";
import CancelIcon from "@mui/icons-material/Cancel";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import FolderIcon from "@mui/icons-material/Folder";
import Tooltip from "@mui/material/Tooltip";
import { Paper, Box, Typography, Divider } from "@mui/material";
import LogFilePop from "./LogFilePop";

const ProgressBarCard = (props) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [openDetail, setOpenDetail] = React.useState(false);
  const [logData, setLogData] = React.useState("");
  const [checkRefresh, setCheckRefresh] = React.useState(0);

  const handleClick = (id) => {
    console.log(id);
    AptService.checkStatus(id)
      .then((resp) => {
        console.log(resp);
        props.setCheckRefresh(checkRefresh + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLog = (id) => {
    console.log(id);
    AptService.getWorkflowLog(id)
      .then((resp) => {
        setLogData(resp.data);
        // setRunningWorkflows(resp.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = (id) => {
    console.log(id);
    AptService.cancelWorkflow(id)
      .then((resp) => {
        console.log(resp.data);
        props.setCheckRefresh(checkRefresh + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("effect is hit");
    setOpenDetail(true);
  }, [logData]);

  return (
    <Paper style={{ marginTop: "15px", padding: "15px" }}>
      <Box style={{ display: "flex", flexWrap: "wrap" }}>
        <Typography
          variant="h7"
          style={{
            flexBasis: "50%",
            fontFamily: "Roboto",
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {`Job Number #${props.jobId}`}
        </Typography>
        <Typography variant="h7" style={{ flexBasis: "50%", textAlign: "right", fontFamily: "Roboto", color: "rgba(0, 0, 0, 0.6)", }}>
          {`Client: ${props.clientName}`}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          {(props.list === 'RUNNING' || props.list === 'QUEUED') && (<LinearProgress />)}
          {(props.list === 'FAILED' || props.list === 'CANCELED') && (<LinearProgress color="red" variant="determinate" value={100} />)}

          {(props.list === 'COMPLETED') && (<LinearProgress variant="determinate" value={100} />)}        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography
            variant="body2"
            color="text.secondary"
          >{`${props.progress}%`}</Typography>
        </Box>
      </Box>
      <Box>
        <Stepper nonLinear>
          <Step>
            <Typography
              variant="subtitle1"
              style={{ color: "rgba(0, 0, 0, 0.6)" }}
            >
              {"File Upload"}
            </Typography>
          </Step>
          <Step
            completed={props.progress >= 60 ? true : false}
            onClick={() => setIsExpanded(!isExpanded)}
            style={{ cursor: "pointer" }}
          >
            <Stack direction="row">
              {isExpanded ? (
                <ExpandMoreIcon
                  fontSize="large"
                  style={{ color: "#9b51e0" }}
                ></ExpandMoreIcon>
              ) : (
                <ChevronRightIcon
                  fontSize="large"
                  style={{ color: "#9b51e0" }}
                ></ChevronRightIcon>
              )}
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ marginTop: "6px" }}
              >{`NextFlow Steps`}</Typography>
            </Stack>
          </Step>
          <Step completed={props.progress === 100 ? true : false}>
            <Typography
              variant="subtitle1"
              style={{ color: "rgba(0, 0, 0, 0.6)" }}
            >
              {"Complete"}
            </Typography>
          </Step>
        </Stepper>
      </Box>
      <Stack direction="row">
        <Tooltip title="Task Logs">
          <FolderIcon
            fontSize="large"
            style={{ color: "#9b51e0", cursor: "pointer" }}
            align="right"
            onClick={() => handleLog(props.jobId)}
          ></FolderIcon>
        </Tooltip>
        {(props.list === "RUNNING" || props.list === "QUEUED") && (
          <Tooltip title="Cancel">
            <CancelIcon
              fontSize="large"
              style={{
                marginLeft: "auto",
                color: "#9b51e0",
                cursor: "pointer",
              }}
              onClick={() => handleCancel(props.jobId)}
            ></CancelIcon>
          </Tooltip>
        )}
      </Stack>

      {isExpanded && (
        <Stack style={{ padding: "15px" }}>
          <Stack direction="column">
            {props.task.stages.map((row, index) => (
              <Stepper nonLinear style={{ padding: "5px" }} key={index}>
                <Step completed={false}>
                  <StepButton color="inherit">{row.stage}</StepButton>
                </Step>
                <Step completed={row.status === "COMPLETED" ? true : false}>
                  <StepButton color="inherit">{row.status}</StepButton>
                </Step>
              </Stepper>
            ))}
          </Stack>
        </Stack>
      )}

      {logData !== "" && (
        <LogFilePop
          openDetail={openDetail}
          logData={logData}
          handleClose={() => setOpenDetail(false)}
        ></LogFilePop>
      )}
    </Paper>
  );
};

export default ProgressBarCard;