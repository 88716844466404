import { createSlice } from "@reduxjs/toolkit";

const initialSearchState = {
  isSearching: false,
  query: "",
};

const servicesSearchSlice = createSlice({
  name: "servicesSearch",
  initialState: initialSearchState,
  reducers: {
    setSearch(state, action) {
      state.query = action.payload.query;
      state.isSearching = true;
    },
    endSearch(state) {
      state.query = "";
      state.isSearching = false;
    },
  },
});

export const servicesSearchActions = servicesSearchSlice.actions;

export default servicesSearchSlice.reducer;
