import React from "react";
import AptService from "../../services/AptService";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, Paper, Box, Typography } from "@mui/material";

const styles = {
  h1: {
    fontFamily: "Roboto",
    color: "rgba(0, 0, 0, 0.6)",
    paddingLeft: "25px",
    paddingTop: "25px",
  },
};

const AptNewClientForm = (props) => {
  const [clientName, setClientName] = React.useState("");
  const [addSucess, setAddSucess] = React.useState(false);
  const [addFail, setAddFail] = React.useState(false);

  const handleChange = (event) => {
    setClientName(event.target.value);
  };

  const handleSubmit = () => {
    AptService.postClients(clientName)
      .then((resp) => {
        setAddSucess(true);
        setAddFail(false);
        props.handleAddedClient(clientName);
        setClientName("");
      })
      .catch((err) => {
        setAddSucess(false);
        setAddFail(true);
        console.log("Failed to Make new Client");
        setClientName("");
      });
  };

  React.useEffect(() => {
    ValidatorForm.addValidationRule("isClientAlready", (value) => {
      if (props.allClients.some((client) => client.client === clientName)) {
        return false;
      }
      return true;
    });
  }, [clientName]);

  return (
    <Paper style={{ marginTop: "15px" }}>
      <Tooltip
        title="Go Back To Tables"
        onClick={() => props.handleChangeViewTable()}
      >
        <CancelPresentationIcon
          style={{
            size: "extralarge",
            color: "rgba(227, 27, 12, 1)",
            width: "2em",
            height: "1.5em",
            cursor: "pointer",
            float: "right",
          }}
        ></CancelPresentationIcon>
      </Tooltip>
      <Typography variant="h4" style={styles.h1}>
        Create New APT Client
      </Typography>
      {addSucess === true && (
        <Alert style={{ margin: "10px" }} severity="success">
          &quot;Successfully Added New Client — check it out!&quot;
        </Alert>
      )}
      {addFail === true && (
        <Alert style={{ margin: "10px" }} severity="error">
          &quot;Failed To Add New Client — check it out!&quot;
        </Alert>
      )}
      <ValidatorForm
        style={{ paddingLeft: "25px", paddingTop: "25px" }}
        onSubmit={handleSubmit}
      >
        <TextValidator
          autoFocus
          label="Enter New Client Name..."
          type="text"
          variant="standard"
          validators={["isClientAlready"]}
          errorMessages={["Client Already Exists"]}
          style={{ width: "400px" }}
          value={clientName}
          name="clientName"
          onChange={handleChange}
        />
        <Box style={{ padding: "25px", paddingLeft: "25px" }}>
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Box>
      </ValidatorForm>
    </Paper>
  );
};

export default AptNewClientForm;
