import { Animation } from "@devexpress/dx-react-chart";
import {
  ArgumentAxis, BarSeries, Chart, Title, ValueAxis
} from "@devexpress/dx-react-chart-material-ui";
import React from "react";
import { Box, Paper } from "../mui";

const style = {
  position: "relative",
  // display: "flex",
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  width: "80%",
  height: "50%",
  // border: '2px solid #000',
  boxShadow: 24,
  marginTop: "40px",
  p: 3,
};

const styles = {
  mainIsExpanded: {
    marginLeft: "100px",
    marginRight: "20px",
    position: "relative",
    height: "100%",
    marginTop: "100px",
    minHeight: "500px",
  },
  mainIsNotExapanded: {
    marginLeft: "195px",
    marginRight: "20px",
    position: "relative",
    height: "100%",
    marginTop: "100px",
    minHeight: "500px",
  },
  h1: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "54px",
    lineHeight: "116.7%",
    letterSpacing: "-1.5px",
    color: "rgba(0, 0, 0, 0.6)",
  },
  h3: {
    fontFamily: "Helvetica",
    fontWeight: 300,
    fontSize: "3rem",
    lineHeight: 1.167,
    letterSpacing: "-0.01562em",
  },
};

const SnpChart = (props) => {
  const [chartData, setChartData] = React.useState([]);

  React.useEffect(() => {
    let arrData = [];
    let list = {};
    props.data.forEach((ele) => {
      if (list[ele.array_id]) {
        list[ele.array_id] = list[ele.array_id] + 1;
      } else {
        list[ele.array_id] = 1;
      }
    });
    for (const val in list) {
      let temp = {
        array_id: val,
        amount: list[val],
      };
      arrData.push(temp);
    }

    setChartData(arrData);
  }, []);

  return (
    <Box sx={style}>
      <Paper>
        <Chart data={chartData}>
          <ArgumentAxis />
          <ValueAxis />

          <BarSeries valueField="amount" argumentField="array_id" />
          <Title text="Array Frequency" />
          <Animation />
        </Chart>
      </Paper>
    </Box>
  );
};

export default SnpChart;
