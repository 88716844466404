import React from "react";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import ParentWrapper from "../../components/ParentWrapper";
import PrivateRoute from "../../components/PrivateRoute";
import AcsExport from "../AcsExport";
import UserAppManager from "../../../src/pages/UserAppManager";
import BiTool from "../BiTool";
import AcsEdit from "../AcsEdit/AcsEdit";
import EditFileUpload from "../EditFileUpload";

const ParentSamples = (props) => {
  let { path } = useRouteMatch();
  return (
    <ParentWrapper path={path}>
      <PrivateRoute exact path={`${path}/search`}>
        <AcsEdit></AcsEdit>
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/edit`}>
        <EditFileUpload></EditFileUpload>
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/export`}>
        <AcsExport />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/users`}>
        <UserAppManager service={`${path}`} />
      </PrivateRoute>
      <Route>
        <Redirect to={`${path}/search`} />
      </Route>
    </ParentWrapper>
  );
};

export default ParentSamples;
