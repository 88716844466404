import React from "react";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ParentWrapper from "../../components/ParentWrapper";
import PrivateRoute from "../../components/PrivateRoute";
import UserAppManager from "../UserAppManager";
import IngestOnHoldSamples from "../OnHold/IngestOnHoldSamples";
import OnHoldQueue from "../OnHold/OnHoldQueue";
const queryClient = new QueryClient();

const ParentConsole = (props) => {
  let { path } = useRouteMatch();
  return (
    <QueryClientProvider client={queryClient}>
      <ParentWrapper path={path}>
        <PrivateRoute exact path={`${path}/on-hold-queue`}>
          <OnHoldQueue />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}/on-hold-ingestion`}>
          <IngestOnHoldSamples />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}/users`}>
          <UserAppManager service={`${path}`} />
        </PrivateRoute>
        <Route>
          <Redirect to={`${path}/on-hold-queue`} />
        </Route>
      </ParentWrapper>
    </QueryClientProvider>
  );
};

export default ParentConsole;
