import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import {
  Autocomplete,
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Modal,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { FormContainer } from "react-hook-form-mui";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import FileUpload from "../../components/FileUpload";
import PreviewTable from "../../components/ManifestBot/PreviewTable";
import UIWrapper from "../../components/UIWrapper";
import AccService from "../../services/AccService";
import AcsService from "../../services/AcsService";
import BiService from "../../services/BiService";
import { snackbarActions } from "../../Store/snackbar";
import AcsUniqueInvCode from "../AcsUniqueInvCode";
import ManifestHints from "./ManifestHints";

const ManifestIngestTool = (props) => {
  const [clientOptions, setClientOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [mappingOptions, setMappingOptions] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [files, setFiles] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [mappingData, setMappingData] = useState(null);
  const [clientState, setClientState] = useState("");
  const [projectState, setProjectState] = useState("");
  const [mappingState, setMappingState] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const csvLink = useRef();
  const [showUnique, setShowUnique] = useState(false);

  const [loading, setLoading] = useState(false);
  const [disableIngest, setDisableIngest] = useState(true);
  const tableBarCodeData = useRef(null);

  const handleCheckDuplicates = () => {
    tableBarCodeData.current = null;
    const filterProfile = mappingOptions.find(
      (map) => map.name == mappingState
    );
    setShowUnique(true);
    setLoading(true);
    const formData = new FormData();
    formData.append("file", files);
    formData.append("client", clientState);
    formData.append("project", projectState);
    if (mappingState) {
      formData.append("profile", filterProfile.name);
    }
    AcsService.getTableBarCodeData(formData)
      .then((resp) => {
        setLoading(false);
        tableBarCodeData.current = resp.data;
      })
      .catch((err) => {
        setLoading(false);
        setDisableIngest(true);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
      });
  };

  const handleShowUniqueClose = () => {
    setShowUnique(false);
    tableBarCodeData.current = null;
  };

  const acsUniqeCallback = (data) => {
    tableBarCodeData.current = data;
    setConfirmation(true);
    setShowUnique(false);
  };

  useEffect(() => {
    if (files) {
      BiService.previewOrig(files)
        .then((resp) => {
          setPreviewData(resp.data);
        })
        .catch((err) => {
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: err?.response?.data?.detail
                ? err.response.data.detail
                : "Failed Preview",
            })
          );
        });
    }
  }, [files]);

  useEffect(() => {
    AccService.getAllClients("").then((resp) => setClientOptions(resp.data));
    return;
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        AccService.getClientProjects(clientState, projectState).then((resp) =>
          setProjectOptions(resp.data)
        ),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [projectState, clientState]);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        AccService.getManifestProfiles(clientState).then((resp) =>
          setMappingOptions(resp.data)
        ),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [clientState]);

  useEffect(() => {
    const filterProfile = mappingOptions.find(
      (map) => map.name == mappingState
    );
    if (files && filterProfile) {
      BiService.mapManifestPreview(files, {
        add: filterProfile.add,
        drop: filterProfile.drop,
        order: filterProfile.order,
        rules: filterProfile.rules,
        mapping: filterProfile.mapping,
      })
        .then((resp) => {
          setDisableIngest(false);
          setMappingData(resp.data.slice(0, 3));
        })
        .catch((err) => {
          setDisableIngest(true);
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: err?.response?.data?.detail
                ? err.response.data.detail
                : "Failed Request",
            })
          );
        });
    }
    return;
  }, [files, mappingState]);

  useEffect(() => {
    if (exportData && csvLink.current && csvLink.current.link) {
      setTimeout(() => {
        csvLink.current.link.click();
        setExportData([]);
      });
    }
  }, [exportData]);

  const onExport = async () => {
    const filterProfile = mappingOptions.find(
      (map) => map.name == mappingState
    );
    if (files && filterProfile) {
      await BiService.mapManifestExport(files, {
        add: filterProfile.add,
        drop: filterProfile.drop,
        order: filterProfile.order,
        rules: filterProfile.rules,
        mapping: filterProfile.mapping,
      })
        .then((resp) => {
          setExportData(resp.data);
        })
        .catch((err) => {
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: "Failed File Download",
            })
          );
        });
    }
  };

  const onIngest = async () => {
    // setIngestLoading(true);
    const filterProfile = mappingOptions.find(
      (map) => map.name == mappingState
    );

    const uniqueBarcodeData = tableBarCodeData.current.data.reduce(
      (accum, curr) => {
        accum[curr.received_barcode] = curr.inventory_code;
        return accum;
      },
      {}
    );
    if (files) {
      await BiService.ingestManifestFile(
        files,
        clientState,
        projectState,
        uniqueBarcodeData,
        mappingState ? filterProfile.name : null
      )
        .then((resp) => {
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "success",
              snackbarMessage: resp.data,
            })
          );
          history.push("/accession/manifest");
        })
        .catch((err) => {
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: err.response.data.detail,
            })
          );
        });
    }
    // setIngestLoading(false);
  };

  return (
    <UIWrapper title="Manifest Ingestion & Export" paper>
      <Typography>
        This tool allows you to ingest manifests or format them for import into
        other tools using user-designed import profiles.
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{ mt: 1 }}
        align="center"
        justify="center"
      >
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormContainer>
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              size="small"
              margin="dense"
              options={clientOptions}
              onChange={(e, new_value) => setClientState(new_value)}
              renderInput={(params) => (
                <TextField margin="dense" {...params} label="Client" />
              )}
              value={clientState}
              sx={{ minWidth: 275 }}
            />
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              size="small"
              options={projectOptions}
              onChange={(e, new_value) => setProjectState(new_value)}
              renderInput={(params) => (
                <TextField margin="dense" {...params} label="Project" />
              )}
              value={projectState}
              disabled={!clientState}
            />
            <TextField
              value={mappingState}
              onChange={(e) => setMappingState(e.target.value)}
              select // tell TextField to render select
              label="Profile"
              size="small"
              margin="dense"
              sx={{ width: "100%" }}
              disabled={!clientState}
            >
              {mappingOptions.map((mapping) => (
                <MenuItem key={mapping.name} value={mapping.name}>
                  {mapping.name}
                </MenuItem>
              ))}
            </TextField>
          </FormContainer>
        </Grid>
        <Grid item xs={12} md={8}>
          <FileUpload
            files={files}
            setFiles={setFiles}
            accept={["csv"]}
            disabled={!projectState}
          />
        </Grid>
        <Grid item xs={12}>
          <PreviewTable content={previewData} emptyTitle={"Preview Data"} />
        </Grid>
        <Grid item xs={12}>
          <KeyboardDoubleArrowDownIcon />
        </Grid>
        <Grid item xs={12}>
          <PreviewTable
            content={mappingData}
            emptyTitle={"Choose a Profile to Preview"}
          />
        </Grid>
        {files && !disableIngest ? (
          <Grid item xs={12}>
            <Modal
              open={showUnique}
              onClose={handleShowUniqueClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Stack>
                <AcsUniqueInvCode
                  loading={loading}
                  tableBarCodeData={tableBarCodeData.current}
                  // processing={ingestLoading}
                  hamdleParentSubmission={acsUniqeCallback}
                  service_name="accession_manifest"
                />
              </Stack>
            </Modal>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Button
            onClick={() => {
              handleCheckDuplicates();
            }}
            disabled={!files || disableIngest}
          >
            Ingest
          </Button>
          <Button onClick={onExport} disabled={!files}>
            Export
          </Button>
        </Grid>
        <Grid item xs={12}>
          <ManifestHints />
        </Grid>
      </Grid>
      {exportData.length ? (
        <CSVLink
          data={exportData}
          filename={"manifest_export.csv"}
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
      ) : undefined}
      <ConfirmationDialog
        open={confirmation}
        handleClose={() => {
          setConfirmation(false);
          tableBarCodeData.current = null;
        }}
        handleConfirm={onIngest}
        title="Ingest this manifest?"
        subTitle="Confirming will accession these samples into SampledSphere and Queue them for transfer to LIMS! 
        Have you verified that all of the information in the 
        manifest is valid and correct?"
      />
    </UIWrapper>
  );
  //Requires first a description telling the user the purpose of the feature
  //Then, it requires selecting a client and a project + optionally, an ingestion profile
  // the ingestion profile will allow the user to map the manifest to a desired format for ingestion
  // next, the manifest is uploaded + a view of it is shown to the user before clicking submit
  // clicking submit will cause the manifest to be uploaded + the selected rule to be applied to it
  //This tool can either allow you to export your manifest, or submit it
  //submission will always prompt an "are you sure dialog"
};

export default ManifestIngestTool;
