import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import UIWrapper from "../../components/UIWrapper";
import ClientsTable from "./ClientsTable";

const onhold_get_url = "/client-onboarding/onhold-clients";
const sampled_get_url = "/client-onboarding/clients";
const ruid_get_url = "/client-onboarding/ruids";

const onhold_update_url = "/client-onboarding/onhold-client/edit";
const sampled_update_url = "/client-onboarding/client/edit";
const ruid_update_url = "/client-onboarding/ruid/edit";

const onhold_create_url = "/client-onboarding/onhold-client/create";
const sampled_create_url = "/client-onboarding/client/create";
const ruid_create_url = "/client-onboarding/ruid/create";

const onhold_newrow = { client: "", poc_email: "" };
const sampled_newrow = { client: "", host_name: "", source: "" };
const ruid_newrow = { client: "", ruid_pattern: "" };

const ManageClients = () => {
  const ruid_columns = [
    {
      field: "client",
      flex: 1,
      headerName: "Client",
      editable: true,
    },
    {
      field: "ruid_pattern",
      headerName: "RUID Pattern",
      flex: 1,
      editable: true,
    },
  ];

  const onhold_columns = [
    {
      field: "client",
      flex: 1,
      headerName: "Client",
      // preProcessEditCellProps: (params) => {
      //   const hasError = params.props.value.trim() === "";
      //   return { ...params.props, error: hasError };
      // },
      //   minWidth: 180,
      editable: true,
    },
    {
      field: "poc_email",
      headerName: "Point of Contact",
      flex: 1,
      editable: true,
    },
  ];

  const sampled_columns = [
    {
      field: "client",
      flex: 1,
      headerName: "Client",
      //   minWidth: 180,
      editable: true,
    },
    {
      field: "host_name",
      headerName: "Host Name",
      flex: 1,
      editable: true,
    },
    {
      field: "source",
      headerName: "Source",
      width: 100,
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueGetter: (params) => {
        if (!params.value) {
          return "";
        }
        return params.value;
      },
      valueOptions: ["LabVantage", "StarLIMS"],
    },
  ];

  const dispatch = useDispatch();

  return (
    <UIWrapper title="Manage Client Details" paper>
      <Grid container spacing={4}>
        <Grid item sm={12} md={6} lg={3.5} height="450px">
          <ClientsTable
            tableName="RUID Patterns"
            isRuidTable={true}
            staticColumns={ruid_columns}
            saveUrl={ruid_update_url}
            initialValue={ruid_newrow}
            createUrl={ruid_create_url}
            getUrl={ruid_get_url}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={3.5} height="450px">
          <ClientsTable
            tableName="OnHold Clients"
            isRuidTable={false}
            staticColumns={onhold_columns}
            saveUrl={onhold_update_url}
            initialValue={onhold_newrow}
            getUrl={onhold_get_url}
            createUrl={onhold_create_url}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={5} height="450px">
          <ClientsTable
            tableName="SampledSphere Clients"
            isRuidTable={false}
            staticColumns={sampled_columns}
            initialValue={sampled_newrow}
            saveUrl={sampled_update_url}
            createUrl={sampled_create_url}
            getUrl={sampled_get_url}
          />
        </Grid>
      </Grid>
    </UIWrapper>
  );
};

export default ManageClients;
