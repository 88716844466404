import { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Divider,
  Modal,
  Stack,
  TextField,
  Typography,
} from "../../mui";
import { Grid } from "@mui/material";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import OnHoldService from "../../services/OnHoldService";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../../Store/snackbar";

const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  maxHeight: "80%",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowX: "auto",
};
const final_disposition_options = [
  "On Hold",
  "Shipped",
  "Discarded",
  "Ingested Manifest",
];
const reason_options = [
  "Missing Manifest Data",
  "Pending Client Setup",
  "Other",
];

const EditOnHoldSample = (props) => {
  const [editData, setEditData] = useState(props.recordForEdit);
  const dispatch = useDispatch();
  const [carrierList, setCarrierList] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const handleOnHoldEdit = (key, val) => {
    setEditData((prev) => ({ ...prev, [key]: val }));
  };
  const submitEditData = () => {
    setDataLoading(true);
    OnHoldService.singleEditOnHoldSample(editData)
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );
        setDataLoading(false);
        handleClose();
        props.setRefresh((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
        setDataLoading(false);
        handleClose();
      });
  };

  const handleClose = () => {
    props.setOpenServiceModal(false);
  };

  useEffect(() => {
    OnHoldService.getAllCarriers(editData.client)
      .then((resp) => {
        setCarrierList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Modal
      open={props.openServiceModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style}>
        <Typography
          variant="h4"
          style={{
            fontFamily: "Roboto",
            fontWeight: "500",
            lineHeight: "116.7%",
            letterSpacing: "-1.5px",
          }}
          color="secondary"
        >
          Edit On-Hold Sample
        </Typography>
        <Divider style={{ width: "100%" }}></Divider>
        <Grid
          container
          mt="1rem"
          rowSpacing={2}
          columnSpacing={{ sm: 2, md: 3 }}
          p={2}
        >
          <Grid item sm={6} md={4}>
            <TextField
              disabled
              style={{ width: "100%" }}
              id="standard-helperText"
              size="small"
              label="Inventory Code"
              value={editData.inventory_code}
              variant="outlined"
              // helperText="Be Cautious of Changing Inventory Code!"
              //   onChange={(e) =>
              //     handleOnHoldEdit("inventory_code", e.target.value)
              //   }
            />
          </Grid>
          <Grid item sm={6} md={4}>
            <TextField
              disabled
              size="small"
              style={{ width: "100%" }}
              id="standard-helperText"
              label="Client"
              value={editData.client}
              variant="outlined"
            />
          </Grid>
          <Grid item sm={6} md={4}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              id="standard-helperText"
              label="Project"
              value={editData.project}
              variant="outlined"
              onChange={(e) => handleOnHoldEdit("project", e.target.value)}
            />
          </Grid>
          <Grid item sm={6} md={4}>
            <Autocomplete
              id="tags-standard"
              // freeSolo
              value={editData.carrier || ""}
              options={carrierList}
              onChange={(e, newValue) => handleOnHoldEdit("carrier", newValue)}
              getOptionLabel={(option) =>
                typeof option === "string" || option instanceof String
                  ? option
                  : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={true}
                  id="standard-multiline-flexible"
                  // size="small"
                  type="text"
                  name={"Carrier"}
                  label={"Carrier"}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} md={4}>
            <TextField
              style={{ width: "100%" }}
              id="standard-helperText"
              label="Tracking Number"
              value={editData.tracking_number}
              variant="standard"
              onChange={(e) =>
                handleOnHoldEdit("tracking_number", e.target.value)
              }
            />
          </Grid>
          <Grid item sm={6} md={4}>
            <TextField
              style={{ width: "100%" }}
              id="standard-helperText"
              label="Storage Location"
              value={editData.storage_location}
              variant="standard"
              onChange={(e) =>
                handleOnHoldEdit("storage_location", e.target.value)
              }
            />
          </Grid>
          <Grid item sm={6} md={4}>
            <TextField
              style={{ width: "100%" }}
              id="standard-helperText"
              label="Temparature"
              value={editData.temperature}
              variant="standard"
              onChange={(e) => handleOnHoldEdit("temperature", e.target.value)}
            />
          </Grid>
          <Grid item sm={6} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Created On"
                inputFormat="YYYY-MM-DD"
                mask={"____-__-__"}
                value={editData.created_on ? dayjs(editData.created_on) : null}
                onChange={(newValue) => {
                  if (newValue === null) {
                    handleOnHoldEdit("created_on", newValue);
                  } else if (String(newValue.$d) !== "Invalid Date") {
                    handleOnHoldEdit(
                      "created_on",
                      dayjs(newValue.$d)?.format("YYYY-MM-DD")
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={6} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date Disposition"
                inputFormat="YYYY-MM-DD"
                value={
                  editData.date_disposition
                    ? dayjs(editData.date_disposition)
                    : null
                }
                mask={"____-__-__"}
                onChange={(newValue) => {
                  if (newValue === null) {
                    handleOnHoldEdit("date_disposition", newValue);
                  } else if (String(newValue.$d) !== "Invalid Date") {
                    handleOnHoldEdit(
                      "date_disposition",
                      dayjs(newValue.$d)?.format("YYYY-MM-DD")
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={6} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date Received"
                inputFormat="YYYY-MM-DD"
                mask={"____-__-__"}
                value={
                  editData.date_received ? dayjs(editData.date_received) : null
                }
                onChange={(newValue) => {
                  if (newValue === null) {
                    handleOnHoldEdit("date_received", newValue);
                  } else if (String(newValue.$d) !== "Invalid Date") {
                    handleOnHoldEdit(
                      "date_received",
                      dayjs(newValue.$d)?.format("YYYY-MM-DD")
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={6} md={4}>
            <TextField
              style={{ width: "100%" }}
              id="standard-helperText"
              label="Created By"
              value={editData.created_by}
              variant="standard"
              onChange={(e) => handleOnHoldEdit("created_by", e.target.value)}
            />
          </Grid>
          <Grid item sm={6} md={4}>
            <Autocomplete
              id="tags-standard-fd"
              // freeSolo
              value={editData.final_disposition}
              options={final_disposition_options}
              onChange={(e, newValue) =>
                handleOnHoldEdit("final_disposition", newValue)
              }
              getOptionLabel={(option) =>
                typeof option === "string" || option instanceof String
                  ? option
                  : ""
              }
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="tags-standard-fd"
                  size="small"
                  type="text"
                  label="Final Disposition"
                  name={"Final Disposition"}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} md={4}>
            <Autocomplete
              id="tags-standard-reason"
              value={editData.on_hold_reason}
              options={reason_options}
              onChange={(e, newValue) =>
                handleOnHoldEdit("on_hold_reason", newValue)
              }
              getOptionLabel={(option) =>
                typeof option === "string" || option instanceof String
                  ? option
                  : ""
              }
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="tags-standard-reason"
                  size="small"
                  type="text"
                  label="On-Hold Reason"
                  name={"On-Hold Reason"}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} md={4}>
            <TextField
              disabled
              style={{ width: "100%" }}
              id="standard-helperText"
              label="Updated By"
              value={editData.updated_by}
              variant="standard"
              // onChange={(e) => handleOnHoldEdit("updated_by", e.target.value)}
            />
          </Grid>
          <Grid item sm={6} md={4}>
            <TextField
              style={{ width: "100%" }}
              id="standard-helperText"
              label="Tube Type"
              value={editData.tube_type}
              variant="standard"
              onChange={(e) => handleOnHoldEdit("tube_type", e.target.value)}
            />
          </Grid>
        </Grid>
        <TextField
          multiline
          label={"Comment"}
          type="text"
          style={{
            textAlign: "center",
            color: "white",
            margin: "10px",
          }}
          size="small"
          value={editData.comments || ""}
          onChange={(e) => handleOnHoldEdit("comments", e.target.value)}
          minRows={2}
          inputProps={{ maxLength: 200 }}
        />
        <Stack direction="row" style={{ width: "100%" }}>
          <Button
            style={{ width: "100%", margin: "15px 5px 5px 5px" }}
            disabled={!editData?.inventory_code || dataLoading}
            type="submit"
            variant="contained"
            onClick={submitEditData}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default EditOnHoldSample;
