import { Auth } from "aws-amplify";
import axios from "axios";

const BI_API_BASE_URL = process.env.MAIN_API;

class AccService {
  async getAllClients(clientName) {
    let myInit = {
      params: {
        query: `${clientName}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/accession/search/clients",
      myInit
    );
  }

  async getClientProjects(clientName) {
    let myInit = {
      params: {
        client: `${clientName}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/accession/search/projects",
      myInit
    );
  }

  async getAllCarriers() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/accession/search/carriers",
      myInit
    );
  }

  async getManifestProfiles(client) {
    let myInit = {
      params: {
        client,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/manifest/profiles", myInit);
  }
}

export default new AccService();
