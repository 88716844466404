import { Button, Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import Columns from "../../../components/ManifestBot/Columns";
import Options from "../../../components/ManifestBot/Options";
import PreviewTable from "../../../components/ManifestBot/PreviewTable";
import PreviewTableDialog from "../../../components/ManifestBot/PreviewTableDialog";
import Rules from "../../../components/ManifestBot/Rules";
import ProjectManagementService from "../../../services/ProjectManagementService";
import { snackbarActions } from "../../../Store/snackbar";

const DesignManifest = ({ files, setActiveStep, sampleContent }) => {
  const [previewData, setPreviewData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const mapping = useSelector((state) => state.mapManifest);
  const csvLink = useRef();

  const dispatch = useDispatch();

  const onPreview = () => {
    ProjectManagementService.mapManifestPreview(files, mapping)
      .then((resp) => {
        setPreviewData(resp.data);
        setShowPreview(true);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed Request",
          })
        );
      });
  };

  useEffect(() => {
    if (exportData && csvLink.current && csvLink.current.link) {
      setTimeout(() => {
        csvLink.current.link.click();
        setExportData([]);
      });
    }
  }, [exportData]);

  const onExport = async () => {
    await ProjectManagementService.mapManifestExport(files, mapping)
      .then((resp) => {
        setExportData(resp.data);
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed File Download",
          })
        );
      });
  };

  return (
    <>
      <Grid container spacing={2} columns={24}>
        <Grid item xs={24} mt={2}>
          <PreviewTable content={sampleContent} />
        </Grid>
        <Grid
          item
          xs={24}
          sx={{
            justifyContent: "space-between",
          }}
          container
        >
          <Options />
        </Grid>
        <Grid item xs={12}>
          <Box component={Paper} variant="outlined">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: 1,
              }}
            >
              <Typography
                variant="h6"
                color="secondary.main"
                sx={{
                  flex: 8,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Original
              </Typography>
              <Typography
                variant="h6"
                color="secondary.main"
                sx={{ flex: 9, display: "flex", justifyContent: "center" }}
              >
                Mapping
              </Typography>
            </Box>
            <Columns />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box component={Paper} variant="outlined">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: 1,
              }}
            >
              <Typography
                variant="h6"
                color="secondary.main"
                sx={{
                  flex: 8,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Rule Sets
              </Typography>
            </Box>
            <Rules />
          </Box>
        </Grid>
        <Grid item xs={24} container>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => {
                setActiveStep((prev) => prev - 1);
              }}
            >
              Back
            </Button>
            <Button onClick={onExport}>Export</Button>
            <Button onClick={onPreview}>Preview</Button>
            <Button
              onClick={() => {
                setActiveStep(2);
              }}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
      <PreviewTableDialog
        open={showPreview}
        onClose={() => setShowPreview(false)}
        previewData={previewData}
      />
      {exportData.length ? (
        <CSVLink
          data={exportData}
          filename={"manifest_export.csv"}
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
      ) : undefined}
    </>
  );
};

export default DesignManifest;
