import ArticleIcon from '@mui/icons-material/Article';
import FolderIcon from '@mui/icons-material/Folder';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Button, Typography, Paper } from "@mui/material";
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import React, { useEffect } from 'react';
// import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import AptService from "../../services/AptService";
import FormInputs from './FormInputs';
import PageNav from './PageNav';
import PreviewFormPopUp from './PreviewFormPopUp';

const styles = {
    h1: {
        fontFamily: "Roboto",
        color: "rgba(0, 0, 0, 0.6)",
        paddingLeft: "25px",
        paddingTop: "25px",

    },

};
const style = {
    position: 'absolute',
    display: "flex",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    height: "50%",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 3,
};


const PipelineForm = (props) => {
    const [currentSearch, setCurrentSearch] = React.useState('');
    const [currentDirList, setCurrentDirList] = React.useState({});
    const [newPath, setNewPath] = React.useState('');
    const [selectedFile, setSelectedFile] = React.useState('');
    const [folderView, setFolderView] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openDetail, setOpenDetail] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [formList, setFormList] = React.useState({
        'project': '',
        'mani_file': '',
        'cel_dir': '',
        'pl_num': '',
        'fqc_file': '',
        'concordance_out': '',
        'batch_date': ''
    });
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleOpen = (selectedField) => {
        setOpen(true);
        setCurrentSearch(selectedField)
        AptService.getInputPath('/isilon/Brookslab').then(resp => {
            console.log(resp.data)
            setCurrentDirList(resp.data)
        })
    }
    const handleChange = (str, e) => {

        setFormList(prevState => ({
            ...prevState,
            [str]: e.target.value
        }));

    };

    const handleNewPath = (newValue, isDir) => {
        if (isDir) {
            setNewPath(newValue)
        } else {
            setSelectedFile(newValue)
        }
    };

    const handleNewPathBreadcrumb = (newValue, isDir) => {
        setNewPath(newValue)
    };

    const selectDir = (path) => {
        setSelectedFile(path)
    };
    const handlePreviewSubmit = () => {
        setOpenDetail(true)
    };

    useEffect(() => {
        AptService.getInputPath('/isilon/Brookslab').then(resp => {
            setCurrentDirList(resp.data)
        })
    }, [])

    useEffect(() => {
        AptService.getInputPath(newPath).then(resp => {

            setCurrentDirList(resp.data)
        })
    }, [newPath])

    useEffect(() => {
        setFormList(prevState => ({
            ...prevState,
            [currentSearch]: selectedFile
        }));
        setOpen(false)
    }, [selectedFile])






    return (
        <Box style={{ marginTop: "15px", padding: "10px", width:"100%" }} >
            <Paper elevation={1} style={{ position: "relative", margin: "15px",width:"40%" }}>
                <Typography variant="h4" style={{ textAlign: "center", color: "white", padding: "15px", margin: '0px', height: "100%", backgroundColor: "#9b51e0" }}>
                    APT PIPELINE
                </Typography>

                <FormInputs handleChange={handleChange} handleOpen={handleOpen} formList={formList} pageNum={activeStep}></FormInputs>
                <PageNav handleNext={handleNext} handleBack={handleBack} activeStep={activeStep}></PageNav>
                <Button type="submit" variant="contained" style={{ width: "100%" }} onClick={handlePreviewSubmit} >RUN PIPELINE</Button>

            </Paper>
            <PreviewFormPopUp openDetail={openDetail} formList={formList} handleClose={() => setOpenDetail(false)} ></PreviewFormPopUp>

            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Stack sx={style}>
                    <Box>
                        <Stack direction="row" style={{ margin: "0px", padding: "0px", flexWrap: "wrap" }}>
                            {
                                Object.keys(currentDirList).length !== 0 &&
                                currentDirList.path.substring(1).split('/').map((option, index) => (
                                    <Stack direction="row" key={index} style={{ cursor: "pointer" }} onClick={() => handleNewPathBreadcrumb(currentDirList.path.substring(0, currentDirList.path.indexOf(option) + option.length), option.is_dir)} >
                                        <NavigateNextIcon fontSize="large"></NavigateNextIcon>
                                        <Typography variant="h6" style={{ overflow: "hidden", fontFamily: "Roboto", color: "rgba(0, 0, 0, 0.6)" }}>{option.substring(0, 20)}</Typography>
                                    </Stack>

                                ))
                            }
                        </Stack>
                    </Box>




                    <Box sx={{ flexGrow: 1 }} style={{ marginTop: "20px", overflow: "auto" }} >
                        {
                            folderView === false &&
                            <Grid container spacing={{ xs: 2 }} columns={{ xs: 3, sm: 6, md: 16 }} >
                                {
                                    currentDirList.children && currentDirList.children.length !== 0 &&
                                    currentDirList.children.map((option, index) => (
                                        <Grid item xs={2} sm={4} md={4} key={index} style={{ size: "extralarge", color: "#9b51e0" }}>
                                            <Typography variant="body2" style={{ overflow: "hidden", color: "rgba(108, 117, 125, 0.5)" }}>{option.name.substring(0, 20)}</Typography>
                                            {
                                                option.is_dir ?
                                                    <FolderIcon style={{ size: "extralarge", color: "#9b51e0", width: "1.5em", height: "1.5em", cursor: "pointer" }} onClick={() => handleNewPath(currentDirList.path + '/' + option.name, option.is_dir)}></FolderIcon>
                                                    :
                                                    <ArticleIcon style={{ size: "extralarge", color: "#9b51e0", width: "1.5em", height: "1.5em", cursor: "pointer" }} onClick={() => handleNewPath(currentDirList.path + '/' + option.name, option.is_dir)}></ArticleIcon>
                                            }
                                        </Grid>

                                    ))
                                }
                            </Grid>
                        }
                        {
                            folderView === true &&
                            <List component="nav" aria-label="main mailbox folders">
                                {
                                    currentDirList.children && currentDirList.children.length !== 0 &&
                                    currentDirList.children.map((option, index) => (
                                        <Stack direction="row" xs={2} sm={4} md={4} key={index} style={{ size: "extralarge", color: "#9b51e0" }}>
                                            {
                                                option.is_dir ?
                                                    <FolderIcon style={{ size: "extralarge", color: "#9b51e0", width: "1.5em", height: "1.5em", cursor: "pointer" }} onClick={() => handleNewPath(currentDirList.path + '/' + option.name, option.is_dir)}></FolderIcon>
                                                    :
                                                    <ArticleIcon style={{ size: "extralarge", color: "#9b51e0", width: "1.5em", height: "1.5em", cursor: "pointer" }} onClick={() => handleNewPath(currentDirList.path + '/' + option.name, option.is_dir)}></ArticleIcon>
                                            }
                                            <Typography variant="body2" style={{ overflow: "hidden", color: "rgba(108, 117, 125, 0.5)", paddingLeft: "10px", paddingTop: "10px" }}>{option.name.substring(0, 20)}</Typography>

                                        </Stack>

                                    ))
                                }
                            </List>
                        }

                    </Box>
                    <Box style={{ marginTop: "20px", display: "flex" }} >
                        <Button variant="contained" onClick={() => setFolderView(!folderView)}>Switch View</Button>
                        <Button variant="contained" sx={{ marginLeft: "auto" }} onClick={() => selectDir(currentDirList.path)}>Select Dir</Button>
                    </Box>
                </Stack>

            </Modal>


        </Box>

    );
}

export default PipelineForm;
