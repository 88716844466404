import {
  ArrowRightAltIcon,
  Button,
  Divider,
  TextField,
  BoxSystem,
} from "../../../mui";

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { mapManifestActions } from "../../../Store/map-manifest";
import RuleDialogWrapper from "./RuleDialogWrapper";

const AddColumnDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [columnName, setColumnName] = useState({ init: true, name: "" });
  const [defaultValue, setDefaultValue] = useState(null);

  const onSubmitHandler = () => {
    dispatch(
      mapManifestActions.addColumn({
        name: columnName.name,
        default: defaultValue,
      })
    );
    onClose();
  };

  return (
    <RuleDialogWrapper
      open={open}
      onClose={onClose}
      title="Add Column"
      directions={"Add a column with an optional default value"}
    >
      <BoxSystem
        sx={{
          display: "flex",
          flexDirection: "column",
          jusitfyContent: "center",
          alignItems: "center",
        }}
      >
        <BoxSystem display="flex" alignItems="center" m={2}>
          <TextField
            id="standard-adornment-weight"
            size="small"
            label="Column Name"
            onChange={(event) =>
              setColumnName({ init: false, name: event.target.value })
            }
            error={!columnName.init && columnName.name.length == 0}
          />
          <ArrowRightAltIcon />
          <TextField
            id="standard-adornment-weight"
            size="small"
            label="Default Value"
            onChange={(event) => setDefaultValue(event.target.value)}
          />
        </BoxSystem>
        <Divider sx={{ width: "100%" }} />
        <Button
          sx={{ mt: 1 }}
          onClick={onSubmitHandler}
          disabled={columnName.init || columnName.name.length == 0}
        >
          Submit
        </Button>
      </BoxSystem>
    </RuleDialogWrapper>
  );
};

export default AddColumnDialog;
