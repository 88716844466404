import { JsonForms } from "@jsonforms/react";
import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import schema from "./JSON/schema.json";
import uischema from "./JSON/ui.json";
import initialData from "./JSON/data.json";

export default function BasicForm() {
  const basicStyle = {
    padding: "20px",
    margin: "20px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    width: "50%",
    backgroundColor: "#fafafa",
  };

  return (
    <div style={basicStyle}>
      <JsonForms
        schema={schema}
        uischema={uischema}
        data={initialData}
        renderers={materialRenderers}
        cells={materialCells}
      />
    </div>
  );
}
