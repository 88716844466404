import { Button, Typography } from "@mui/material";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { useState, useEffect } from "react";
import ManagementService from "../../../services/ManagementService";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../../../Store/snackbar";
import { Box } from "../../../mui";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { GridToolbarFilterButton } from "@mui/x-data-grid";
import UIWrapper from "../../../components/UIWrapper";

const AF2Active = () => {
  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [title, setTitle] = useState("");
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const inProgress = "in_progress";
  const rejected = "rejected";
  const accept = "Accept";

  // open and close popup for confirmation on Accept or Reject
  const handleOpenModal = (actionType, params) => {
    setTitle(actionType);
    setData(params.row);
    setConfirmation(true);
  };

  // renders buttons inside action colunms for DataGrid
  const renderDetailsButton = (params) => {
    return (
      <strong>
        <Button
          variant="contained"
          onClick={() => handleOpenModal(accept, params)}
          disabled={params.row.file_status === inProgress}
        >
          {accept}
        </Button>

        <Button
          style={{ marginLeft: "10px" }}
          variant="outlined"
          onClick={() => handleOpenModal("Reject", params)}
          disabled={params.row.file_status === inProgress}
        >
          Reject
        </Button>
      </strong>
    );
  };

  const renderDownloadLinks = (params) => {
    const fileNameArray = params.value.split("/");
    const fileName = fileNameArray[fileNameArray.length - 1];
    return (
      <Typography
        variant="p"
        color="secondary.main"
        onClick={() => handleDownload(params.value)}
        title={fileName}
        sx={{
          cursor: "Pointer",
          textDecoration: "underline",
        }}
      >
        {fileName}
      </Typography>
    );
  };

  const handleDownload = (file) => {
    const fileName = file.split("/");
    ManagementService.getAF2File(fileName[fileName.length - 1])
      .then((resp) => {
        window.location.replace(resp.data.presigned_url);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Successful Download",
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed Download",
          })
        );
      });
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  const AF2ActiveColumns = [
    {
      field: "file_name",
      headerName: "File Name",
      minWidth: 300,
      editable: false,
      renderCell: (params) => {
        return (
          <Typography variant="p" color="secondary.main" title={params.value}>
            {params.value}
          </Typography>
        );
      },

      valueGetter: (params) => {
        if (!params.value) {
          return "None/Null";
        }
        return params.value;
      },
    },
    {
      field: "file_date",
      headerName: "File Date",
      minWidth: 220,
      editable: false,
      valueGetter: (params) => {
        if (!params.value) {
          return "None/Null";
        }
        return params.value;
      },
    },
    {
      field: "deltas_file",
      headerName: "Deltas File",
      minWidth: 350,
      editable: false,
      valueGetter: (params) => {
        if (!params.value) {
          return "None/Null";
        }
        return params.value;
      },
      renderCell: renderDownloadLinks,
    },
    {
      field: "deltas_summary_file",
      headerName: "Deltas Summary",
      minWidth: 390,
      editable: false,
      valueGetter: (params) => {
        if (!params.value) {
          return "None/Null";
        }
        return params.value;
      },
      renderCell: renderDownloadLinks,
    },
    {
      field: "",
      headerName: "Action",
      minWidth: 200,
      editable: false,
      valueGetter: (params) => {
        if (!params.value) {
          return "None/Null";
        }
        return params.value;
      },
      renderCell: renderDetailsButton,
    },
  ];

  useEffect(() => {
    getAF2FileRecords();
    return;
  }, []);

  // get all records of AF2 Files
  const getAF2FileRecords = async () => {
    setDataLoading(true);
    await ManagementService.getAF2FileRecords()
      .then((resp) => {
        const fiterData = resp.data.filter(
          (row) =>
            row.file_status === "pending" || row.file_status === inProgress
        );
        setTableData(
          fiterData.map((row, id) => {
            return { id, ...row };
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed to get Samples Pending Accessioning.",
          })
        );
      });
    setDataLoading(false);
  };

  // Action Accept or Reject function
  const onAccetRejectAction = async () => {
    let body = {};
    setDataLoading(true);
    const fileStatus = title === accept ? inProgress : rejected;
    if (fileStatus === rejected) {
      const deltasFile = data.deltas_file.split("/").pop();
      const deltasSummaryFile = data.deltas_summary_file.split("/").pop();
      body.file_name = data.file_name;
      body.file_status = fileStatus;
      body.deltas_file = deltasFile;
      body.deltas_summary_file = deltasSummaryFile;
    } else {
      body.file_name = data.file_name;
      body.file_status = fileStatus;
    }
    ManagementService.acceptOrRejectAF2(body)
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );
        setConfirmation(false);
        getAF2FileRecords();
      })
      .catch((err) => {
        setConfirmation(false);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
      });
    setDataLoading(false);
  };

  return (
   <>
      <Box
        sx={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Typography sx={{ mb: 1, fontSize: 15, fontWeight: "800" }}>
          Total Number of Records: {tableData.length}
        </Typography>
        <Box
          height="375px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <DataGrid
            rows={tableData}
            columns={AF2ActiveColumns}
            loading={dataLoading}
            density="compact"
            disableSelectionOnClick
            autosizeOptions={{
              columns: ['file_name', 'deltas_file', 'deltas_summary_file'],
              includeOutliers: true,
              includeHeaders: false,
            }}
            components={{
              Toolbar: CustomToolbar,
            }}
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
            }}
          />
        </Box>
      </Box>
      <ConfirmationDialog
        open={confirmation}
        handleClose={() => setConfirmation(false)}
        handleConfirm={onAccetRejectAction}
        title="Data Updation"
        subTitle={"Are you sure you want to " + title + " ?"}
      />
    </>
  );
};

export default AF2Active;
