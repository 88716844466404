import { Stack, Divider, Modal, Typography } from "@mui/material";
import React from "react";
// import UIBreadcrumbs from "../../components/UIBreadcrumbs";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};

const LogFilePop = (props) => {
  return (
    <Modal
      open={props.openDetail}
      onClose={() => props.handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style}>
        <Stack direction="row" style={{ paddingBottom: "10px" }}>
          <Typography color="secondary" sx={{ fontSize: 18 }}>
            PIPELINE LOGS
          </Typography>
        </Stack>
        <Divider style={{ width: "100%" }}></Divider>

        <Stack style={{ overflow: "auto", paddingTop: "8px" }}>
          <Typography color="secondary" sx={{ fontSize: 14 }}>
            {props.logData}
          </Typography>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default LogFilePop;
