import { PropTypes } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import BiService from "../../services/BiService";
import MoreDataCard from "./MoreDataCard";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const tableRowData = [
  "Inventory Code",
  "Date Received",
  "Draw Date",
  "Material",
  "Source",
  "Subject Code",
  "Volume",
];
const StoreTable = (props) => {
  const [openDetail, setOpenDetail] = React.useState(false);
  const [pageLarge, setPageLarge] = React.useState(0);
  const [rowsPerPageLarge, setRowsPerPageLarge] = React.useState(6);
  const [moreDetail, setMoreDetail] = React.useState([]);

  const handleDetailData = (code) => {
    BiService.getSingleMoreData(code).then((resp) => {
      console.log(resp.data);
      setMoreDetail(resp.data);
    });
  };

  const handleChangePageLarge = (event, newPage) => {
    setPageLarge(newPage);
  };

  const handleChangeRowsPerPageLarge = (event) => {
    setRowsPerPageLarge(+event.target.value);
    setPageLarge(0);
  };
  useEffect(() => {
    console.log("effect is hit");
    setOpenDetail(true);
  }, [moreDetail]);
  return (
    <>
      <TableContainer style={{ marginTop: "15px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size={"small"}>
          <TableHead sx={{ minWidth: 650 }}>
            <TableRow sx={{ minWidth: 650 }}>
              {tableRowData.map((row, index) => (
                <TableCell
                  align="left"
                  key={index}
                  style={{ fontWeight: "bold" }}
                >
                  {row}
                </TableCell>
              ))}
              <TableCell
                align="left"
                style={{ fontWeight: "bold" }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ border: "none" }}>
            {props.tableData.length !== undefined &&
              props.tableData
                .slice(
                  pageLarge * rowsPerPageLarge,
                  pageLarge * rowsPerPageLarge + rowsPerPageLarge
                )
                .map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell align="left">{row.inventory_code}</TableCell>
                    <TableCell align="left">{row.date_received}</TableCell>
                    <TableCell align="left">{row.draw_date}</TableCell>
                    <TableCell align="left">{row.material}</TableCell>
                    <TableCell align="left">{row.source}</TableCell>
                    <TableCell align="left">{row.subject_code}</TableCell>
                    <TableCell align="left">{row.volume}</TableCell>
                    <TableCell
                      align="left"
                      onClick={(e) => handleDetailData(row.inventory_code)}
                    >
                      <Tooltip
                        title="More Detail"
                        style={{ cursor: "pointer" }}
                      >
                        <ReadMoreIcon></ReadMoreIcon>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[6, 10, 25]}
          component="div"
          count={props.tableData.length}
          rowsPerPage={rowsPerPageLarge}
          page={pageLarge}
          onPageChange={handleChangePageLarge}
          onRowsPerPageChange={handleChangeRowsPerPageLarge}
        />
      </TableContainer>
      {moreDetail.length !== 0 && (
        <MoreDataCard
          openDetail={openDetail}
          tableData={moreDetail}
          handleClose={() => setOpenDetail(false)}
        ></MoreDataCard>
      )}
    </>
  );
};

StoreTable.propTypes = {
  handleClose: PropTypes.func,
  setOpenDetail: PropTypes.func,
  setPageLarge: PropTypes.func,
  setRowsPerPageLarge: PropTypes.func,
  setMoreDetail: PropTypes.func,
  handleDetailData: PropTypes.func,

  openDetail: PropTypes.bool,

  pageLarge: PropTypes.number,
  rowsPerPageLarge: PropTypes.number,
};

export default StoreTable;
