import {
  CloseIcon,
  Divider,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "../../../mui";

import React from "react";

const BootstrapDialogTitle = (props) => {
  const { title, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ display: "flex", m: 0, p: 2 }} {...other}>
      <Typography sx={{ flex: 1, fontSize: 25 }}>{title}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            right: -8,
            top: -8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const RuleDialogWrapper = (props) => {
  const { open, onClose, title } = props;
  const handleClose = (event) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={false}>
      <BootstrapDialogTitle
        onClose={handleClose}
        title={title}
      ></BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography sx={{ maxWidth: 400, mb: 1 }}>
          {props.directions}
        </Typography>
        <Divider />
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

export default RuleDialogWrapper;
