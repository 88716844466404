import React, { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { FormControl, TextField, Stack, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import StoreTable from "./StoreTable";
import Autocomplete from "@mui/material/Autocomplete";
import BiService from "../../services/BiService";
import Chip from "@mui/material/Chip";
import ExportPopUp from "./ExportPopUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { PropTypes } from "prop-types";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const AdvancedSearch = (props) => {
  const [formFilterData, setFormFilterData] = React.useState({
    inventory_code: "",
    client: "",
    project: "",
    material: "",
    status: "",
    study: "",
    date_received: {
      start: "",
    },
  });
  const [listLarge, setListLarge] = React.useState([]);
  const [val, setVal] = useState([]);
  const [largeInput, setLargeInput] = React.useState("");
  const [filterExpand, setFilterExpand] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(true);

  const handleChange = (str, e) => {
    if (str !== "start") {
      setFormFilterData((prevState) => ({
        ...prevState,
        [str]: e.target.value,
      }));
    } else {
      setFormFilterData((prevState) => ({
        ...prevState,
        ["date_received"]: {
          [str]: e.target.value,
        },
      }));
    }
  };
  const handleSubmit = (event) => {
    let final = {};
    if (val.length !== 0) final["inventory_codes"] = val;
    if (formFilterData["client"] !== "")
      final["client"] = formFilterData["client"];
    if (formFilterData["project"] !== "")
      final["project"] = formFilterData["project"];
    if (formFilterData["material"] !== "")
      final["material"] = formFilterData["material"];
    if (formFilterData["status"] !== "")
      final["status"] = formFilterData["status"];
    if (formFilterData["study"] !== "")
      final["study"] = formFilterData["study"];
    if (formFilterData["date_received"]["start"] !== "") {
      final["date_received"] = {
        start: formFilterData["date_received"]["start"],
      };
    }
    console.log(final);
    BiService.advancedSearch(final).then((resp) => {
      console.log(resp);
      setTableData(resp.data);
    });
  };

  const handleExpandView = () => {
    setFilterExpand(!filterExpand);
  };
  React.useEffect(() => {}, []);

  useEffect(() => {
    BiService.getCodeList(largeInput)
      .then((resp) => {
        setListLarge(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [largeInput]);
  return (
    <Stack>
      <Stack direction="row" style={{ marginTop: "15px", cursor: "pointer" }}>
        {isExpanded ? (
          <ExpandMoreIcon
            fontSize="large"
            style={{ color: "#9b51e0" }}
            onClick={() => setIsExpanded(!isExpanded)}
          ></ExpandMoreIcon>
        ) : (
          <ChevronRightIcon
            fontSize="large"
            style={{ color: "#9b51e0" }}
            onClick={() => setIsExpanded(!isExpanded)}
          ></ChevronRightIcon>
        )}
        {isExpanded && (
          <FormControl variant="standard" onSubmit={handleSubmit}>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={2} sm={4} md={4} style={{ padding: "0px" }}>
                <Item style={{ padding: "8px" }}>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    freeSolo
                    inputValue={largeInput}
                    onInputChange={(e, newValue) => setLargeInput(newValue)}
                    options={listLarge}
                    onChange={(e, newValue) => setVal(newValue)}
                    // getOptionLabel={option => option.title}
                    value={val}
                    renderTags={() => {}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="standard-multiline-flexible"
                        label="Inventory Code..."
                        style={{ width: "95%" }}
                        size="small"
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Item style={{ padding: "8px" }}>
                  <TextField
                    id="name-input"
                    label="Client Name..."
                    type="text"
                    style={{ width: "95%" }}
                    value={formFilterData["client"]}
                    onChange={(e) => handleChange("client", e)}
                    size="small"
                  />
                </Item>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Item style={{ padding: "8px" }}>
                  <TextField
                    id="name-input"
                    label="Project Name..."
                    type="text"
                    style={{ width: "95%" }}
                    value={formFilterData["project"]}
                    onChange={(e) => handleChange("project", e)}
                    size="small"
                  />
                </Item>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Item style={{ padding: "8px" }}>
                  <TextField
                    id="name-input"
                    label="Material..."
                    type="text"
                    style={{ width: "95%" }}
                    value={formFilterData["material"]}
                    onChange={(e) => handleChange("material", e)}
                    size="small"
                  />
                </Item>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Item style={{ padding: "8px" }}>
                  <TextField
                    id="name-input"
                    label="Status..."
                    type="text"
                    style={{ width: "95%" }}
                    value={formFilterData["status"]}
                    onChange={(e) => handleChange("status", e)}
                    size="small"
                  />
                </Item>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Item style={{ padding: "8px" }}>
                  <TextField
                    id="name-input"
                    label="Study Name..."
                    type="text"
                    style={{ width: "95%" }}
                    value={formFilterData["study"]}
                    onChange={(e) => handleChange("study", e)}
                    size="small"
                  />
                </Item>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Item style={{ padding: "8px" }}>
                  <TextField
                    id="date"
                    label="Date Received"
                    type="date"
                    sx={{ width: "95%" }}
                    value={formFilterData["date_received"]["start"]}
                    onChange={(e) => handleChange("start", e)}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Item>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Item style={{ padding: "8px" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{ width: "95%" }}
                  >
                    Advanced Search
                  </Button>
                </Item>
              </Grid>
              {tableData.length !== 0 && (
                <Grid item xs={2} sm={4} md={4}>
                  <Item style={{ padding: "8px" }}>
                    <Button
                      type="submit"
                      variant="outlined"
                      onClick={() => setOpen(true)}
                      sx={{ width: "95%" }}
                    >
                      Export
                    </Button>
                  </Item>
                </Grid>
              )}
            </Grid>
          </FormControl>
        )}
      </Stack>
      <Stack direction="row">
        {filterExpand
          ? val.map((option, index) => (
              <Chip
                variant="outlined"
                onDelete={() => {
                  setVal(val.filter((entry) => entry !== option));
                }}
                size="small"
                key={index}
                label={option}
              />
            ))
          : val.slice(0, 5).map((option, index) => (
              <Chip
                variant="outlined"
                onDelete={() => {
                  setVal(val.filter((entry) => entry !== option));
                }}
                size="small"
                key={index}
                label={option}
              />
            ))}

        {6 <= val.length && (
          <Chip
            variant="outlined"
            size="small"
            label="Show More"
            onClick={handleExpandView}
          />
        )}
      </Stack>
      {<StoreTable tableData={tableData}></StoreTable>}
      {
        <ExportPopUp
          open={open}
          tableData={tableData}
          handleClose={() => setOpen(false)}
        ></ExportPopUp>
      }
    </Stack>
  );
};

AdvancedSearch.propTypes = {
  tableData: PropTypes.array,
  filterExpand: PropTypes.bool,
  setFilterExpand: PropTypes.func,
  formFilterData: PropTypes.object,
  setFormFilterData: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  setVal: PropTypes.func,
  val: PropTypes.array,
  largeInput: PropTypes.string,
  setLargeInput: PropTypes.func,
  setTableData: PropTypes.func,
  setFilterData: PropTypes.func,
  filterData: PropTypes.object,
};

export default AdvancedSearch;
