import React, { useEffect, useState } from "react";
import AptService from "../../services/AptService";
import AptNewClientForm from "./AptNewClientForm";
import AptNewProjectsForm from "./AptNewProjectsForm";
import AptEditClientForm from "./AptEditClientForm";

import {
  Box,
  AddCircleOutlineIcon,
  CancelIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TablePagination,
  MenuItem,
  FormControl,
  Select,
  CheckCircleOutlineIcon,
  DoNotDisturbAltIcon,
  CircularProgress,
  Alert,
  StickyNote2Icon,
} from "../../mui";

const AptManagment = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = React.useState("tables");
  const [selectedClient, setSelectedClient] = React.useState({});
  const [addedClient, setAddedClient] = React.useState("");
  const [addedProject, setAddedProject] = React.useState("");

  const [allClients, setAllClients] = React.useState([]);
  const [allProjects, setAllProjects] = React.useState([]);
  const [deactivateID, setDeactivateID] = React.useState("");

  const [list, setlist] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(6);
  const [editSucess, setEditSucess] = React.useState(false);
  const [editFail, setEditFail] = React.useState(false);

  const handleAddedClient = (name) => {
    setAddedClient(name);
  };
  const handleAddedProject = (name) => {
    setAddedProject(name);
  };

  const handleChange = (event) => {
    setlist(event.target.value);
    setPage(0);
  };

  const handleEdit = (truthy) => {
    if (truthy === true) {
      setEditSucess(true);
      setEditFail(false);
    } else {
      setEditSucess(false);
      setEditFail(true);
    }
  };
  const handleAddToProjects = (newProject) => {
    let tempList = allProjects;
    tempList.push(newProject);
  };

  const handleDisable = (client) => {
    AptService.deactivateClients(client)
      .then((resp) => {
        setDeactivateID(client.id);
      })
      .catch((err) => {
        console.log("Already Deactavated");
      });
  };
  const handleClientsUpdateName = (oldname, newName) => {
    let tempList = allClients;
    tempList.forEach((ele) => {
      if (ele.client === oldname) ele.client = newName;
    });
    setAllClients(tempList);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangeView = () => {
    setEditSucess(false);
    setView("form");
  };
  const handleChangeViewTable = () => {
    setView("tables");
  };
  const handleChangeViewEdit = (client) => {
    setEditSucess(false);
    setSelectedClient(client);
    setView("edit");
  };

  React.useEffect(() => {
    AptService.getAllClient().then((responce) => {
      setAllClients(responce.data);
      AptService.getAllProjects().then((resp) => {
        setIsLoading(true);
        setAllProjects(resp.data);
      });
    });
  }, []);

  useEffect(() => {
    AptService.getAllClient().then((responce) => {
      setAllClients(responce.data);
    });
  }, [deactivateID]);

  useEffect(() => {
    AptService.getAllClient().then((responce) => {
      setAllClients(responce.data);
    });
  }, [addedClient]);

  useEffect(() => {
    AptService.getAllProjects().then((resp) => {
      setAllProjects(resp.data);
    });
  }, [addedProject]);

  return (
    <Box>
      <Box sx={{ minWidth: 320 }} style={{ paddingTop: "10px" }}>
        <FormControl style={{ width: "220px", paddingTop: "10px" }}>
          <Select id="demo-simple-select" value={list} onChange={handleChange}>
            <MenuItem value={10}>Clients</MenuItem>
            <MenuItem value={20}>Projects</MenuItem>
          </Select>
        </FormControl>
        {list === 10 && (
          <Tooltip title="Add new Client">
            <AddCircleOutlineIcon
              onClick={handleChangeView}
              style={{
                marginTop: "20px",
                size: "extralarge",
                color: "#46c3cf",
                width: "2em",
                height: "1.5em",
                cursor: "pointer",
              }}
            ></AddCircleOutlineIcon>
          </Tooltip>
        )}
        {list === 20 && (
          <Tooltip title="Add new Project">
            <AddCircleOutlineIcon
              onClick={handleChangeView}
              style={{
                marginTop: "20px",
                size: "extralarge",
                color: "#46c3cf",
                width: "2em",
                height: "1.5em",
                cursor: "pointer",
              }}
            ></AddCircleOutlineIcon>
          </Tooltip>
        )}
      </Box>

      {isLoading === false && (
        <Alert style={{ top: "45%" }} severity="info">
          Data Is currently Loading
        </Alert>
      )}
      {editSucess === true && (
        <Alert
          style={{ margin: "10px" }}
          severity="success"
        >{`Successfully Edited ${selectedClient.client} Project  — check it out!`}</Alert>
      )}
      {editFail === true && (
        <Alert style={{ margin: "10px" }} severity="error">
          &quot;Failed To Edit Project — check it out!&quot;
        </Alert>
      )}
      {view === "tables" && (
        <Box>
          {isLoading === false && (
            <Box>
              <CircularProgress
                style={{ position: "absolute", top: "55%", left: "35%" }}
                size={"11rem"}
              />
            </Box>
          )}

          {list === 10 && isLoading === true && (
            <TableContainer style={{ marginTop: "15px" }}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                size={"small"}
              >
                <TableHead sx={{ minWidth: 650 }}>
                  <TableRow sx={{ minWidth: 650 }}>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      ID
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      CLIENT
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      ACTIVE
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      CREATED ON{" "}
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      EDIT{" "}
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      DISABLE{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allClients.length !== undefined &&
                    allClients
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">{row.id}</TableCell>
                          <TableCell align="left">{row.client}</TableCell>
                          <TableCell align="left">
                            {row.active === "Y" ? (
                              <CheckCircleOutlineIcon
                                style={{ color: "rgba(76, 175, 80, 1)" }}
                              ></CheckCircleOutlineIcon>
                            ) : (
                              <DoNotDisturbAltIcon
                                style={{ color: "rgba(227, 27, 12, 1)" }}
                              ></DoNotDisturbAltIcon>
                            )}
                          </TableCell>
                          <TableCell align="left">{row.created_on}</TableCell>
                          <TableCell align="left">
                            <StickyNote2Icon
                              onClick={() => handleChangeViewEdit(row)}
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                cursor: "pointer",
                              }}
                            ></StickyNote2Icon>
                          </TableCell>
                          <TableCell align="left">
                            <CancelIcon
                              onClick={() => handleDisable(row.client)}
                              style={{
                                color: "rgba(244, 67, 54, 1)",
                                cursor: "pointer",
                              }}
                            ></CancelIcon>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[6, 10, 25]}
                component="div"
                count={allClients.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
          {list === 20 && isLoading === true && (
            <TableContainer style={{ marginTop: "15px" }}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                size={"small"}
              >
                <TableHead sx={{ minWidth: 650 }}>
                  <TableRow sx={{ minWidth: 650 }}>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      ID
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      ARRAY ID
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      CLIENT ID
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      PROJECT NAME{" "}
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      ACTIVE{" "}
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      CREATED ON{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ border: "none" }}>
                  {allProjects.length !== undefined &&
                    allProjects
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">{row.id}</TableCell>
                          <TableCell align="left">{row.array_id}</TableCell>
                          <TableCell align="left">{row.client_id}</TableCell>
                          <TableCell align="left">{row.project_name}</TableCell>
                          <TableCell align="left">
                            {row.active === "Y" ? (
                              <CheckCircleOutlineIcon
                                style={{ color: "rgba(76, 175, 80, 1)" }}
                              ></CheckCircleOutlineIcon>
                            ) : (
                              <DoNotDisturbAltIcon
                                style={{ color: "rgba(227, 27, 12, 1)" }}
                              ></DoNotDisturbAltIcon>
                            )}
                          </TableCell>
                          <TableCell align="left">{row.created_on}</TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[6, 10, 25]}
                component="div"
                count={allProjects.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      )}
      {view === "form" && list === 10 && (
        <AptNewClientForm
          handleChangeViewTable={handleChangeViewTable}
          allClients={allClients}
          handleAddedClient={handleAddedClient}
        ></AptNewClientForm>
      )}
      {view === "form" && list === 20 && (
        <AptNewProjectsForm
          handleChangeViewTable={handleChangeViewTable}
          allClients={allClients}
          handleAddToProjects={handleAddToProjects}
          handleAddedProject={handleAddedProject}
        ></AptNewProjectsForm>
      )}
      {view === "edit" && list === 10 && (
        <AptEditClientForm
          handleChangeViewTable={handleChangeViewTable}
          allClients={allClients}
          selectedClient={selectedClient}
          handleEdit={handleEdit}
          handleClientsUpdateName={handleClientsUpdateName}
        ></AptEditClientForm>
      )}
    </Box>
  );
};

export default AptManagment;
