import React, { useEffect, useState } from "react";
import UIWrapper from "../components/UIWrapper";
import AcsService from "../services/AcsService";
import AcsDynamic from "./acsComp/AcsDynamic";
import AcsFormInputs from "./acsComp/AcsFormInputs";
import AcsNav from "./acsComp/AcsNav";
import AcsPreview from "./acsComp/AcsPreview";
import AcsSamples from "./acsComp/AcsSamples";
import AcsStepper from "./acsComp/AcsStepper";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import AcsBulkBtn from "./acsComp/AcsBulkBtn";
import AcsBarcodeScanning from "./acsComp/AcsBarcodeScanning";
import { userTimeZone } from "../components/helper/timeZone";

const defaultInputs = ["Site", "Gender", "Alternate ID"];
const shema_type_mapping = {
  number: "integer",
  numeric: "number",
  string: "string",
  text: "string",
  decimal: "number",
  float: "number",
  double_precision: "number",
  boolean: "boolean",
};

const AcsForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [bulkScanFlag, setBulkScanFlag] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [dynamicInputs, setDynamicInputs] = React.useState({});
  const [dynamicValuesSample, setDynamicValuesSample] = React.useState();
  const [dynamicValuesSubject, setDynamicValuesSubject] = React.useState({});
  const [sampleInputs, setSampleInputs] = React.useState({});

  const [val, setVal] = useState([]);
  const [client, setClient] = useState("");
  const [project, setProject] = React.useState("");
  const [subjectCode, setSubjectCode] = React.useState("");
  const [trackingNumber, setTrackingNumber] = React.useState("");
  const [formListHeader, setFormListHeader] = React.useState({
    site: "",
    carrier: "",
    date_received: "",
    time_received: "",
    dt_received_timezone: userTimeZone,
  });
  const [comments, setComments] = React.useState("");
  const [formValues, setFormValues] = useState([]);
  const [formValuesSubject, setFormValuesSubject] = useState([]);

  const [sourceList, setSourceList] = useState([]);

  const [sampleSchema, setSampleSchema] = useState({});
  const [subjectSchema, setSubjectSchema] = useState({});
  const [scannedBarcodes, setScannedBarcodes] = React.useState(null);
  const clearForm = () => {
    setActiveStep(0);
    setDynamicInputs({});
    setDynamicValuesSample();
    setDynamicValuesSubject({});
    setSampleInputs({});
    setBulkScanFlag(false);
    setVal([]);
    setClient("");
    setProject("");
    setSubjectCode("");
    setTrackingNumber("");

    let now = dayjs();
    let date = now.format("YYYY-MM-DD");
    let time = now.format("HH:mm");
    setFormListHeader({
      site: "",
      carrier: "",
      date_received: date,
      time_received: time,
      dt_received_timezone: userTimeZone,
    });

    setComments("");
    setFormValues([]);
    setFormValuesSubject([]);
    // setSourceList([])
    setSampleSchema({});
    setSubjectSchema({});
  };

  let addFormFields = () => {
    if (dynamicValuesSample) {
      let deepClone = JSON.parse(JSON.stringify(dynamicValuesSample));
      Object.keys(deepClone.data).forEach((ele) => {
        deepClone.data[ele] = "";
      });
      setFormValues([...formValues, deepClone]);
    } else {
      // setFormValues([...formValues, {}])
    }
  };

  const handleNext = () => {
    if (activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 5) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleHeaderChange = (str, new_value) => {
    let strVal = "";
    if (new_value !== undefined) strVal = new_value;

    setFormListHeader((prevState) => ({
      ...prevState,
      [str]: strVal,
    }));
  };

  const handleSiteChange = (newVal) => {
    setFormListHeader((prevState) => ({
      ...prevState,
      ["site"]: newVal,
    }));
  };

  useEffect(() => {
    AcsService.getDynamicInputs(client, project)
      .then((resp) => {
        //JSONForm accept only limited types. Changing the resp type with the supported types.
        const sample_keys = Object.keys(resp.data.sample.properties);
        const sample_properties = sample_keys.reduce((acc, curr) => {
          acc[curr] = {
            ...resp.data.sample.properties[curr],
            type: resp.data.sample.properties[curr].type
              ? shema_type_mapping[resp.data.sample.properties[curr].type]
              : "string",
          };
          return acc;
        }, {});
        const subject_keys = Object.keys(resp.data.subject.properties);
        const subject_properties = subject_keys.reduce((acc, curr) => {
          acc[curr] = {
            ...resp.data.subject.properties[curr],
            type: resp.data.subject.properties[curr].type
              ? shema_type_mapping[resp.data.subject.properties[curr].type]
              : "string",
          };
          return acc;
        }, {});

        const updated_resp = {
          sample: {
            ...resp.data.sample,
            properties: sample_properties,
          },
          subject: {
            ...resp.data.subject,
            properties: subject_properties,
          },
        };
        setSampleInputs(updated_resp.sample);
        setDynamicInputs(updated_resp);

        let temp = {};

        Object.keys(updated_resp.subject.properties).forEach((ele) => {
          if (
            updated_resp.subject.properties[ele].type &&
            updated_resp.subject.properties[ele].type === "string"
          ) {
            if (
              updated_resp.subject.properties[ele].format &&
              (updated_resp.subject.properties[ele].format === "date" ||
                updated_resp.subject.properties[ele].format === "time")
            ) {
              temp[ele] = undefined;
            } else {
              temp[ele] = undefined;
            }
          } else {
            temp[ele] = undefined;
          }
        });

        let templistSample = {
          inventory_code_input: "",
          inventory_code: [],
          source: "",
          container_type: "",
          data: {},
        };

        Object.keys(updated_resp.sample.properties).forEach((ele) => {
          if (
            updated_resp.sample.properties[ele].type &&
            updated_resp.sample.properties[ele].type === "string"
          ) {
            if (
              (updated_resp.sample.properties[ele].format &&
                updated_resp.sample.properties[ele].format === "date") ||
              updated_resp.sample.properties[ele].format === "time"
            ) {
              templistSample.data[ele] = undefined;
            } else {
              templistSample.data[ele] = undefined;
            }
          } else {
            templistSample.data[ele] = undefined;
          }
        });
        setDynamicValuesSample(templistSample);
        setDynamicValuesSubject(temp);

        // Sets UI SCHEMAS FOR JSON FORMS BASED OFF RENDERED FIELDS

        let schema = {
          type: "VerticalLayout",
          elements: [],
        };

        let hor = {
          type: "HorizontalLayout",
          elements: [],
        };

        Object.keys(templistSample.data).forEach((ele, index) => {
          if (
            (index + 1) % 3 === 0 ||
            index + 1 === Object.keys(templistSample.data).length
          ) {
            let obj = {
              type: "Control",
              scope: `#/properties/${ele}`,
            };
            hor.elements.push(obj);
            let temp = JSON.parse(JSON.stringify(hor));
            schema.elements.push(temp);
            hor.elements = [];
          } else {
            let obj = {
              type: "Control",
              scope: `#/properties/${ele}`,
            };
            hor.elements.push(obj);
          }
        });
        setSampleSchema(schema);

        let schemaSubject = {
          type: "VerticalLayout",
          elements: [],
        };

        let horSubject = {
          type: "HorizontalLayout",
          elements: [],
        };

        Object.keys(temp).forEach((ele, index) => {
          if ((index + 1) % 3 === 0 || index + 1 === Object.keys(temp).length) {
            let obj = {
              type: "Control",
              scope: `#/properties/${ele}`,
            };
            horSubject.elements.push(obj);
            let temp = JSON.parse(JSON.stringify(horSubject));
            schemaSubject.elements.push(temp);
            horSubject.elements = [];
          } else {
            let obj = {
              type: "Control",
              scope: `#/properties/${ele}`,
            };
            horSubject.elements.push(obj);
          }
        });

        setSubjectSchema(schemaSubject);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [project]);

  useEffect(() => {
    AcsService.getData(client, project, subjectCode, trackingNumber)
      .then((resp) => {
        if (Object.keys(resp.data).length !== 0) {
          console.log("#@", resp.data);

          //formListHeader
          let testList = JSON.parse(JSON.stringify(formListHeader));

          Object.keys(resp.data.core).forEach((ele) => {
            if (!testList[ele]) {
              testList[ele] = resp.data.core[ele];
            }
          });
          setFormListHeader(testList);

          //Sample
          let loadedSampleData = [];
          resp.data.sample.forEach((ele, index) => {
            let deepClone = JSON.parse(JSON.stringify(dynamicValuesSample));
            deepClone["inventory_code"].push(
              resp.data.sample[index]["inventory_code"]
            );

            if (resp.data.sample[index]["source"])
              deepClone["source"] = resp.data.sample[index]["source"];
            if (resp.data.sample[index]["container_type"])
              deepClone["container_type"] =
                resp.data.sample[index]["container_type"];

            Object.keys(ele).forEach((option) => {
              if (
                option !== "inventory_code" &&
                resp.data.sample[index][option]
              ) {
                if (
                  dynamicInputs.sample.properties[option] &&
                  dynamicInputs.sample.properties[option].type &&
                  dynamicInputs.sample.properties[option].type === "string"
                ) {
                  deepClone.data[option] = String(
                    resp.data.sample[index][option]
                  );
                } else {
                  deepClone.data[option] = resp.data.sample[index][option];
                }
              }
            });

            loadedSampleData.push(deepClone);
          });
          setFormValues(loadedSampleData);

          //subject
          let loadedSubjectData = [];
          let tempInputs = JSON.parse(JSON.stringify(dynamicValuesSubject));
          tempInputs.subject_code = testList.subject_code;

          Object.keys(resp.data.subject).forEach((ele) => {
            if (resp.data.subject[ele] && resp.data.subject[ele] !== "") {
              if (
                dynamicInputs.subject.properties[ele] &&
                dynamicInputs.subject.properties[ele].type &&
                dynamicInputs.subject.properties[ele].type === "string"
              ) {
                tempInputs[ele] = resp.data.subject[ele].toString();
              } else {
                tempInputs[ele] = resp.data.subject[ele];
              }
            }
          });
          loadedSubjectData.push(tempInputs);
          // setDynamicValuesSubject(tempInputs);
          setFormValuesSubject(loadedSubjectData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [client, project, subjectCode, trackingNumber]);

  useEffect(() => {
    if (bulkScanFlag) {
      //API for Bulk acs
      AcsService.getBulkData(scannedBarcodes)
        .then((resp) => {
          if (Object.keys(resp.data).length !== 0) {
            //formListHeader
            let testList = JSON.parse(JSON.stringify(formListHeader));
            Object.keys(resp.data.core).forEach((ele) => {
              if (!testList[ele]) {
                testList[ele] = resp.data.core[ele];
              }
            });
            setFormListHeader(testList);

            //Subject
            let loadedSubjectData = [];
            resp.data.subject.forEach((ele, index) => {
              let tempInputs = JSON.parse(JSON.stringify(dynamicValuesSubject));

              Object.keys(ele).forEach((items) => {
                if (resp.data.subject[index][items]) {
                  if (
                    dynamicInputs.subject.properties[items] &&
                    dynamicInputs.subject.properties[items].type &&
                    dynamicInputs.subject.properties[items].type === "string"
                  ) {
                    tempInputs[items] = String(resp.data.subject[index][items]);
                  } else {
                    tempInputs[items] = resp.data.subject[index][items];
                  }
                }
              });
              loadedSubjectData.push(tempInputs);
            });
            // setDynamicValuesSubject(loadedSubjectData);
            setFormValuesSubject(loadedSubjectData);

            //Sample
            let loadedSampleData = [];
            resp.data.sample.forEach((ele, index) => {
              let deepClone = JSON.parse(JSON.stringify(dynamicValuesSample));

              deepClone["inventory_code"].push(
                resp.data.sample[index]["inventory_code"]
              );

              if (resp.data.sample[index]["source"])
                deepClone["source"] = resp.data.sample[index]["source"];
              if (resp.data.sample[index]["container_type"])
                deepClone["container_type"] =
                  resp.data.sample[index]["container_type"];

              Object.keys(ele).forEach((option) => {
                if (
                  option !== "inventory_code" &&
                  resp.data.sample[index][option]
                ) {
                  if (
                    dynamicInputs.sample.properties[option] &&
                    dynamicInputs.sample.properties[option].type &&
                    dynamicInputs.sample.properties[option].type === "string"
                  ) {
                    deepClone.data[option] = String(
                      resp.data.sample[index][option]
                    );
                  } else {
                    deepClone.data[option] = resp.data.sample[index][option];
                  }
                }
              });
              loadedSampleData.push(deepClone);
            });
            setFormValues(loadedSampleData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [bulkScanFlag]);

  useEffect(() => {
    let now = dayjs();
    let date = now.format("YYYY-MM-DD");
    let time = now.format("HH:mm");
    setFormListHeader((prevState) => ({
      ...prevState,
      ["date_received"]: date,
      ["time_received"]: time,
    }));

    AcsService.getSources()
      .then((resp) => {
        setSourceList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onScanningComplete = () => {
    setActiveStep(1);
    setBulkScanFlag(true);
  };
  return (
    <UIWrapper title="Accessioning Form" paper>
      {/* <Paper elevation={3} style={{ width: "100%", marginTop: "20px" }} sx={{borderRadius: 3.5}}> */}

      {activeStep !== 5 && <AcsStepper activeStep={activeStep} />}
      {activeStep === 0 && (
        <>
          <AcsFormInputs
            defaultInputs={defaultInputs}
            formListHeader={formListHeader}
            handleHeaderChange={handleHeaderChange}
            handleSiteChange={handleSiteChange}
            val={val}
            setVal={setVal}
            client={client}
            project={project}
            subjectCode={subjectCode}
            setClient={setClient}
            setProject={setProject}
            setSubjectCode={setSubjectCode}
            trackingNumber={trackingNumber}
            setTrackingNumber={setTrackingNumber}
          />
        </>
      )}

      <AcsDynamic
        bulkScanFlag={bulkScanFlag}
        dynamicInputs={dynamicInputs}
        // handleChange={handleChange}
        defaultInputs={defaultInputs}
        activeStep={activeStep}
        dynamicValuesSample={dynamicValuesSample}
        dynamicValuesSubject={dynamicValuesSubject}
        setDynamicValuesSubject={setDynamicValuesSubject}
        subjectSchema={subjectSchema}
        setFormValuesSubject={setFormValuesSubject}
        formValuesSubject={formValuesSubject}
      />
      {activeStep === 2 && (
        <AcsSamples
          // dynamicInputs={dynamicInputs.sample}
          activeStep={activeStep}
          defaultInputs={defaultInputs}
          dynamicValuesSample={dynamicValuesSample}
          // setDynamicValuesSample={setDynamicValuesSample}
          formValues={formValues}
          addFormFields={addFormFields}
          setFormValues={setFormValues}
          sampleInputs={sampleInputs}
          sampleSchema={sampleSchema}
          sourceList={sourceList}
          client={client}
        />
      )}

      {activeStep === 3 && (
        <>
          <AcsPreview
            client={client}
            project={project}
            subjectCode={subjectCode}
            trackingNumber={trackingNumber}
            formListHeader={formListHeader}
            // val={val}
            dynamicValuesSample={dynamicValuesSample}
            dynamicValuesSubject={dynamicValuesSubject}
            formValues={formValues}
            formValuesSubject={formValuesSubject}
            comments={comments}
            setComments={setComments}
            setActiveStep={setActiveStep}
            clearForm={clearForm}
          />
        </>
      )}
      {activeStep === 5 && (
        <>
          <AcsBarcodeScanning
            handleNext={handleNext}
            handleBack={handleBack}
            defaultInputs={defaultInputs}
            formListHeader={formListHeader}
            handleHeaderChange={handleHeaderChange}
            client={client}
            project={project}
            trackingNumber={trackingNumber}
            scannedBarcodes={scannedBarcodes}
            setScannedBarcodes={setScannedBarcodes}
          />
        </>
      )}
      {activeStep === 0 && !bulkScanFlag && (
        <AcsBulkBtn
          defaultInputs={defaultInputs}
          formListHeader={formListHeader}
          handleHeaderChange={handleHeaderChange}
          handleSiteChange={handleSiteChange}
          val={val}
          setVal={setVal}
          client={client}
          project={project}
          subjectCode={subjectCode}
          // setClient={setClient}
          // setProject={setProject}
          setSubjectCode={setSubjectCode}
          trackingNumber={trackingNumber}
          // setTrackingNumber={setTrackingNumber}
          handleNext={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      )}
      <AcsNav
        handleNext={handleNext}
        handleBack={handleBack}
        onScanningComplete={onScanningComplete}
        activeStep={activeStep}
        client={client}
        project={project}
        formListHeader={formListHeader}
        subjectCode={subjectCode}
        trackingNumber={trackingNumber}
        setBulkScanFlag={setBulkScanFlag}
      ></AcsNav>
      {/* </Paper> */}
    </UIWrapper>
  );
};

export default AcsForm;
