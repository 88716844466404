import { useState } from "react";
import { useDispatch } from "react-redux";
import logo from "../assets/sampled-logo.png";
import { ParticlesContainer } from "../components/Background/ParticlesContainer";
import { Box, Button, Modal, Stack, Typography } from "../mui/";
import { authenticateUser } from "../Store/auth-actions";

const privacyUrl = "https://sampled.com/legal/client-portals/privacy-policy/";
const termsUrl = "https://sampled.com/legal/client-portals/terms-of-use/";

const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  width: "75%",
  maxWidth: "750px",
  boxShadow: 24,
  borderRadius: 2,
  p: 5,
};

function getLocalStorageItem(key) {
  let item = localStorage.getItem(key);
  if (!item) return "false";
  item = JSON.parse(item);
  if (Date.now() > item.expiry ? item.expiry : 0) {
    localStorage.removeItem(key);
    return "false";
  }
  return item?.value;
}

const SignIn = (props) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleAccept = () => {
    localStorage.setItem(
      "ss_terms_and_conditions",
      JSON.stringify({
        value: "true",
        expiry: Date.now() + 180 * 24 * 60 * 60 * 1000,
      })
    );
    dispatch(authenticateUser());
  };

  const handleSignIn = () => {
    if (getLocalStorageItem("ss_terms_and_conditions") !== "true") {
      setShowModal(true);
    } else {
      dispatch(authenticateUser());
    }
  };

  const handleRedirect = (url) => {
    window.open(url, "_blank", "noreferrer noopener");
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "90vh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {" "}
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack sx={style}>
          <Typography variant="h4" align="center" color="secondary" mb="30px">
            Terms and Conditions/Privacy Policies.
          </Typography>
          <Typography variant="h6" mb="30px" align="center" paragraph>
            I have read and agree to the{" "}
            <em
              onClick={() => handleRedirect(termsUrl)}
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Terms of Use - Sampled{" "}
            </em>
            and{" "}
            <em
              onClick={() => handleRedirect(privacyUrl)}
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Privacy Policy - Sampled.
            </em>
          </Typography>

          <Stack direction="row" justifyContent="center">
            <Button
              variant="contained"
              color="success"
              onClick={handleAccept}
              sx={{ margin: "0 1rem" }}
            >
              Accept
            </Button>
            <Button
              onClick={handleCloseModal}
              variant="contained"
              color="error"
              sx={{ margin: "0 1rem" }}
            >
              Decline
            </Button>
          </Stack>
        </Stack>
      </Modal>
      <ParticlesContainer />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          pb: 10,
        }}
      >
        <Box sx={{ zIndex: 1 }}>
          <img src={logo} alt="Logo" />
        </Box>
        <Button
          variant="contained"
          sx={{ bgcolor: "#484D56" }}
          onClick={handleSignIn}
          style={{ letterSpacing: "1.5px" }}
        >
          Sign In
        </Button>
      </Box>
    </Box>
  );
};

export default SignIn;
