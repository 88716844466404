import React from "react";
import AptService from "../../services/AptService";
import {
  CancelPresentationIcon,
  FormControl,
  Tooltip,
  InputLabel,
  Button,
  Paper,
  Box,
  TextField,
  MenuItem,
  Select,
  Typography,
} from "../../mui";

const styles = {
  h1: {
    fontFamily: "Roboto",
    color: "rgba(0, 0, 0, 0.6)",
    paddingLeft: "25px",
    paddingTop: "25px",
    paddingBottom: "20px",
  },
};

const AptEditClientForm = (props) => {
  const [list, setlist] = React.useState(props.allClients[0].id);
  const [newName, setNewName] = React.useState("");

  const handleChange = (event) => {
    setlist(event.target.value);
  };
  const handleChangeNewName = (event) => {
    setNewName(event.target.value);
  };
  const handleSubmit = () => {
    AptService.updateClients(props.selectedClient.client, newName)
      .then((resp) => {
        props.handleEdit(true);
        props.handleClientsUpdateName(props.selectedClient.client, newName);
        props.handleChangeViewTable();
      })
      .catch((err) => {
        props.handleEdit(false);
      });
  };

  return (
    <Paper style={{ marginTop: "15px" }}>
      <Tooltip
        title="Go Back To Tables"
        onClick={() => props.handleChangeViewTable()}
      >
        <CancelPresentationIcon
          style={{
            size: "extralarge",
            color: "rgba(227, 27, 12, 1)",
            width: "2em",
            height: "1.5em",
            cursor: "pointer",
            float: "right",
          }}
        ></CancelPresentationIcon>
      </Tooltip>
      <Typography variant="h4" style={styles.h1}>
        Edit APT Client
      </Typography>

      <FormControl style={{ paddingLeft: "25px", paddingTop: "25px" }}>
        <InputLabel id="demo-simple-select-label">Clients</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.selectedClient.id}
          onChange={handleChange}
          style={{ width: "400px" }}
          disabled
        >
          {props.allClients.map((row, index) => (
            <MenuItem key={index} value={row.id}>
              {row.client}
            </MenuItem>
          ))}
        </Select>

        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="New Client Name"
          type="email"
          fullWidth
          variant="standard"
          style={{ width: "400px" }}
          value={newName}
          name="newName"
          onChange={handleChangeNewName}
        />
      </FormControl>
      <Box style={{ padding: "25px", paddingLeft: "25px" }}>
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Paper>
  );
};

export default AptEditClientForm;
