import { Auth } from "aws-amplify";
import axios from "axios";

const BASE_URL = process.env.MAIN_API;

class CommonService {
  async getMaintenanceDetails(email) {
    let myInit = {
      params: {
        email,
      },
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${(await Auth.currentSession())
        //   .getIdToken()
        //   .getJwtToken()}`,
      },
    };

    return await axios.get(
      "https://ro3m7kg6qkltaxszzyhq7hmapu0aqmkg.lambda-url.us-east-1.on.aws/",
      myInit
    );
  }
  async validateBarCode(inv_code, service, source) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      cancelToken: source.token,
    };
    const url_endpoint = service.includes("accession")
      ? "/accession/validate-barcode"
      : "/management/validate-barcode";
    return await axios.post(BASE_URL + url_endpoint, inv_code, myInit);
  }
  async generateUniqueBarcodes(table_data, service) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    const url_endpoint = service.includes("accession")
      ? "/accession/generate-unique-barcodes"
      : "/management/generate-unique-barcodes";
    return await axios.post(BASE_URL + url_endpoint, table_data, myInit);
  }
}

export default new CommonService();
