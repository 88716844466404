import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import BackspaceIcon from "@mui/icons-material/Backspace";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import UIWrapper from "../components/UIWrapper";
import BiService from "../services/BiService";
import { snackbarActions } from "../Store/snackbar";
import Header from "./acsComp/Header";
import ExportDownloads from "./exportDownloads";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import AcsCustomExport from "./AcsCustomExport";

const icons = {
  1: <FormatListBulletedIcon />,
  2: <FilterAltIcon />,
  3: <FactCheckIcon />,
  4: <ImportExportIcon />,
};
const filterInputs = {
  field_name: "",
  condition: "",
  value: "",
};
const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 3,
};
const steps = ["Study/Project Profile", "Filter", "Mappings", "Export"];

const AcsExport = (props) => {
  const [selectionView, setSelectionView] = useState(false);
  const [filterView, setFilterView] = useState(false);
  const [mappingView, setMappingView] = useState(false);
  const [searchedDetail, setSearchedDetail] = useState(false);
  const [value, setValue] = useState("one");

  const [profile, setProfile] = useState("");
  const [profileList, setProfileList] = useState([]);

  const [profileNameList, setProfileNameList] = useState([]);

  const [profileName, setProfileName] = useState("");
  const [clientState, setClientState] = useState("");
  const [projectState, setProjectState] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [headerData, setHeaderData] = useState(null);
  const [transferData, setTransferData] = useState([]);
  const [filtersCondition, setFiltersCondition] = useState([]);
  const [open, setOpen] = useState(false);
  const [alias, setAlias] = useState(true);
  const dispatch = useDispatch();

  const handleViewChange = (value) => {
    if (value === "Client/Project Selection")
      setSelectionView((prevView) => !prevView);
    if (value === "Filter") setFilterView((prevView) => !prevView);
    if (value === "Mapping") setMappingView((prevView) => !prevView);
  };

  const handleChangeArray = (value, index) => {
    let clone = filtersCondition.slice(0);
    clone[index] = value;
    setFiltersCondition(clone);
  };
  const doExport = async () => {
    let orderList = [];
    transferData.forEach((ele) => {
      orderList.push(ele.field);
    });
    let temp = [];
    let isFilterValueEmpty = false;
    filterList.forEach((ele, index) => {
      Object.values(filterList[index]).forEach((option) => {
        if (option === "") isFilterValueEmpty = true;
      });
      temp.push([
        filtersCondition[index]?.toLowerCase()
          ? filtersCondition[index].toLowerCase()
          : "and",
        ...Object.values(filterList[index]),
      ]);
    });

    let profile = {
      client: clientState,
      projects: projectState,
      filters: temp,
      order: orderList,
    };

    if (isFilterValueEmpty) {
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "A Filter Value is Empty",
        })
      );
    } else {
      await BiService.doExport(profile)
        .then((resp) => {
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "success",
              snackbarMessage: "Successful Exported",
            })
          );
          setValue("two");
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: "Failed to Export",
            })
          );
        });
    }
  };

  const saveProfile = async () => {
    let orderList = [];
    transferData.forEach((ele) => {
      orderList.push(ele.field);
    });

    let temp = [];
    filterList.forEach((ele, index) => {
      temp.push(Object.values(filterList[index]));
    });

    let profile = {
      client: clientState,
      projects: projectState,
      name: profileName,
      filters: temp,
      order: orderList,
    };

    await BiService.saveProfile(profile)
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Profile Successful Saved",
          })
        );
        setOpen(false);
        setProfileName("");
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed to Save Profile",
          })
        );
        setOpen(false);
        setProfileName("");
      });
  };

  const handleSubmission = async (value) => {
    let finalFilter = [];
    let finalTransfer = [];
    let client = "";
    let project = [];

    if (profile) {
      profileList.forEach((ele) => {
        if (ele.name === profile) {
          client = ele.client;
          project = ele.projects;

          setClientState(ele.client);
          setProjectState(ele.projects);

          ele.filters.forEach((option) => {
            let temp = {};
            temp["field_name"] = option[0];
            temp["condition"] = option[1];
            temp["value"] = option[2];
            finalFilter.push(temp);
          });
          finalTransfer = ele.order;
        }
      });
    }
    await BiService.getFilters()
      .then((resp) => {
        setSearchedDetail(true);
        setFilterData(resp.data);
        if (finalFilter.length) setFilterList(finalFilter);
        setMappingView(true);
      })
      .catch((err) => {
        console.log(err);
        setSearchedDetail(false);
      });
    await BiService.getFieldHeaders(
      client ? client : clientState,
      project.length ? project : projectState
    )
      .then((resp) => {
        let newList = resp.data;
        Object.keys(newList).forEach((ele, index) => {
          newList[ele]["checked"] = finalTransfer.includes(ele) ? true : false;
        });
        setHeaderData(newList);
        let finalTransferList = [];
        finalTransfer.forEach((ele) => {
          let temp = {};
          temp["field"] = ele;
          temp["alias"] = resp.data[ele]["alias"];
          finalTransferList.push(temp);
        });
        setTransferData(finalTransferList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFilterEvent = (event) => {
    event.preventDefault();
  };

  const addFilter = (e) => {
    if (filterInputs) {
      let copy = filtersCondition.slice(0);
      copy.push("AND");
      setFiltersCondition(copy);

      let newList = filterList.slice(0);
      newList.push(JSON.parse(JSON.stringify(filterInputs)));
      setFilterList(newList);
    }
  };
  const handleProfileChange = (e) => {
    setProfileName(e.target.value);
  };
  const changeFilterField = (index, newVal) => {
    let newList = [...filterList];
    newList[index].field_name = newVal;
    newList[index]["condition"] = "";
    setFilterList(newList);
  };

  const changeFilterFil = (index, newVal) => {
    let newList = [...filterList];
    newList[index]["condition"] = newVal;
    setFilterList(newList);
  };
  const changeFilterVal = (e, index) => {
    let newList = [...filterList];
    newList[index]["value"] = e.target.value;
    setFilterList(newList);
  };

  const handleCheckAll = () => {
    let newData = JSON.parse(JSON.stringify(headerData));
    Object.keys(newData).forEach((ele) => {
      newData[ele]["checked"] = true;
    });
    setHeaderData(newData);
  };

  const handleUncheckAll = () => {
    let newData = JSON.parse(JSON.stringify(headerData));
    Object.keys(newData).forEach((ele) => {
      newData[ele]["checked"] = false;
    });
    setHeaderData(newData);
  };

  const handleCheckChange = (ele) => {
    let newData = JSON.parse(JSON.stringify(headerData));
    newData[ele]["checked"] = !newData[ele]["checked"];
    setHeaderData(newData);
  };
  const transferHeaders = () => {
    const transferNew = [];
    Object.keys(headerData).forEach((ele) => {
      if (headerData[ele]["checked"] === true) {
        transferNew.push({ field: ele, alias: headerData[ele]["alias"] });
      }
    });
    setTransferData(transferNew);
  };

  let handleFilterDelete = (eleIndex) => {
    let temp = [...filterList];
    temp.splice(eleIndex, 1);
    setFilterList(temp);
  };
  const handleDateChange = (index, newValue) => {
    const dte = dayjs(newValue.$d).format("YYYY-MM-DD");
    let newList = [...filterList];

    newList[index]["value"] = dte;
    setFilterList(newList);
  };

  const onDragEnd = (result) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }
    const newItems = Array.from(transferData);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setTransferData(newItems);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    BiService.getAllClients("")
      .then((resp) => setClientOptions(resp.data))
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    BiService.searchProjects(clientState, projectState)
      .then((resp) => setProjectOptions(resp.data))
      .catch((err) => {
        console.log(err);
      });
  }, [clientState]);

  useEffect(() => {
    BiService.getProfiles(profile)
      .then((resp) => {
        let list = [];
        resp.data.forEach((ele) => {
          list.push(ele.name);
        });
        setProfileNameList(list);
        setProfileList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [profile]);
  return (
    <UIWrapper title="Export Samples" paper>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="primary tabs example"
        >
          <Tab value="one" label="Basic Export" />
          <Tab value="three" label="Custom Export" />
          <Tab value="two" label="Downloads" />
        </Tabs>
        <Divider style={{ width: "100%" }}></Divider>
        {value === "two" && <ExportDownloads type="export"></ExportDownloads>}
        {value === "three" && <AcsCustomExport setValue={setValue} />}
        {value === "one" && (
          <Box style={{ padding: "20px" }}>
            <Header
              header={"Client/Project Selection"}
              selected={true}
              handleViewChange={handleViewChange}
            />
            {
              <Stack direction="row" style={{ padding: "15px" }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: "100%" }}
                >
                  <FormControl variant="standard" sx={{ width: "20%" }}>
                    <Autocomplete
                      id="tags-standard"
                      freeSolo
                      size="small"
                      options={profileNameList}
                      onChange={(e, new_value) => setProfile(new_value)}
                      renderInput={(params) => (
                        <TextField margin="dense" {...params} label="Profile" />
                      )}
                      value={profile || ""}
                    />
                  </FormControl>

                  <Typography
                    style={{
                      color: "#9b51e0",
                      marginTop: "20px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                  >
                    OR
                  </Typography>

                  <FormControl variant="standard" sx={{ width: "20%" }}>
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      size="small"
                      margin="dense"
                      options={clientOptions}
                      onChange={(e, new_value) => setClientState(new_value)}
                      renderInput={(params) => (
                        <TextField margin="dense" {...params} label="Client" />
                      )}
                      value={clientState || ""}
                    />
                  </FormControl>

                  <ArrowRightAltIcon
                    fontSize="large"
                    style={{
                      color: "#9b51e0",
                      marginTop: "4px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  ></ArrowRightAltIcon>

                  <FormControl variant="standard" sx={{ width: "20%" }}>
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      freeSolo
                      size="small"
                      options={projectOptions}
                      onChange={(e, new_value) => setProjectState(new_value)}
                      renderInput={(params) => (
                        <TextField margin="dense" {...params} label="Project" />
                      )}
                      value={projectState || ""}
                      disabled={!clientState}
                    />
                  </FormControl>

                  <Button
                    type="submit"
                    variant="contained"
                    onClick={handleSubmission}
                    style={{ margin: "15px" }}
                  >
                    Search
                  </Button>
                </Stack>
              </Stack>
            }
            <Header
              header={"Filter"}
              selected={filterView}
              handleViewChange={handleViewChange}
            />
            {searchedDetail && (
              <Stack
                style={{
                  padding: "15px",
                  display: filterView ? "block" : "none",
                  overflow: "auto",
                  maxHeight: "300px",
                }}
                sx={{ width: "100%" }}
              >
                <Stack
                  direction="row"
                  alignItems="right"
                  justifyContent="right"
                  sx={{ width: "99%" }}
                >
                  <Button
                    onClick={(e) => addFilter(e)}
                    style={{ cursor: "pointer" }}
                  >
                    <AddCircleOutlineIcon
                      fontSize="medium"
                      style={{
                        color: "#9b51e0",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        cursor: "pointer",
                      }}
                      // onClick={() => setFilterList(filterList => [...filterList, 1])}
                    ></AddCircleOutlineIcon>
                    <Typography variant="body1" color="secondary">
                      Add Filter
                    </Typography>
                  </Button>
                </Stack>

                <form onSubmit={handleFilterEvent}>
                  {Object.keys(filterList).map((ele, index) => (
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      key={index}
                    >
                      {filterList.length !== 0 && index !== 0 && (
                        <FormControl
                          variant="standard"
                          sx={{ width: "8%", marginTop: "5px" }}
                        >
                          <InputLabel id="and-or-id">AND/OR</InputLabel>
                          <Select
                            labelId="and-or-id"
                            label="AND/OR"
                            value={filtersCondition[index]}
                            onChange={(event) =>
                              handleChangeArray(event.target.value, index)
                            }
                          >
                            <MenuItem value={"AND"}>AND</MenuItem>
                            <MenuItem value={"OR"}>OR</MenuItem>
                          </Select>
                        </FormControl>
                      )}

                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ width: "100%" }}
                        key={index}
                      >
                        <Autocomplete
                          id="free-solo-demo"
                          sx={{ width: "20%" }}
                          freeSolo
                          size="small"
                          margin="dense"
                          options={Object.keys(filterData)}
                          onChange={(e, new_value) =>
                            changeFilterField(index, new_value)
                          }
                          renderInput={(params) => (
                            <TextField
                              margin="dense"
                              {...params}
                              label="Field Name"
                            />
                          )}
                          value={filterList[index]["field_name"] || ""}
                        />

                        <ArrowRightAltIcon
                          fontSize="large"
                          style={{
                            color: "#9b51e0",
                            marginTop: "4px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        ></ArrowRightAltIcon>

                        <Autocomplete
                          id="free-solo-demo"
                          sx={{ width: "20%" }}
                          freeSolo
                          size="small"
                          margin="dense"
                          options={
                            filterList[index]["field_name"]
                              ? filterData[filterList[index]["field_name"]][
                                  "conditions"
                                ]
                              : []
                          }
                          onChange={(e, new_value) =>
                            changeFilterFil(index, new_value)
                          }
                          renderInput={(params) => (
                            <TextField
                              margin="dense"
                              {...params}
                              label="Condition"
                            />
                          )}
                          value={filterList[index]["condition"] || ""}
                          disabled={!filterList[index]["field_name"]}
                        />

                        <ArrowRightAltIcon
                          fontSize="large"
                          style={{
                            color: "#9b51e0",
                            marginTop: "4px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        ></ArrowRightAltIcon>

                        <FormControl variant="standard" sx={{ width: "20%" }}>
                          {filterList[index]["field_name"] ? (
                            filterData[filterList[index]["field_name"]][
                              "type"
                            ] === "date" ? (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  // label="Select Date"
                                  inputFormat="YYYY-MM-DD"
                                  //   helperText="DD/MM/YYYY"
                                  value={dayjs(filterList[index]["value"])}
                                  onChange={(newValue) => {
                                    handleDateChange(index, dayjs(newValue));
                                  }}
                                  renderInput={(props) => (
                                    <TextField
                                      {...props}
                                      inputProps={{
                                        ...props.inputProps,
                                        readOnly: true,
                                      }}
                                      helperText="Select Date(yyyy-mm-dd)"
                                      placeHolderText="YYYY-MM-DD"
                                      variant="standard"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            ) : (
                              <TextField
                                label={"Filter Value"}
                                variant="standard"
                                onChange={(e, new_value) =>
                                  changeFilterVal(e, index, new_value)
                                }
                                value={filterList[index]["value"] || ""}
                              />
                            )
                          ) : (
                            <TextField
                              label={"Filter Value"}
                              variant="standard"
                              onChange={(e, new_value) =>
                                changeFilterVal(e, index, new_value)
                              }
                              value={filterList[index]["value"] || ""}
                            />
                          )}
                        </FormControl>

                        <BackspaceIcon
                          fontSize="medium"
                          style={{
                            color: "#EE3263",
                            marginTop: "4px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleFilterDelete(index)}
                        ></BackspaceIcon>
                      </Stack>
                    </Stack>
                  ))}
                </form>
              </Stack>
            )}

            <Header
              header={"Mapping"}
              selected={mappingView}
              handleViewChange={handleViewChange}
            />
            {searchedDetail && (
              <Stack
                style={{
                  padding: "15px",
                  display: mappingView ? "block" : "none",
                }}
                sx={{ width: "100%" }}
              >
                <Stack direction="row">
                  <Typography variant="h6" color="secondary.main">
                    Alias
                  </Typography>
                  <Switch
                    checked={alias}
                    onChange={() => setAlias((prev) => !prev)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Stack>

                <Stack
                  direction="row"
                  style={{ padding: "15px" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Stack style={{ width: "40%" }}>
                    <Typography
                      variant="h6"
                      color="secondary.main"
                      sx={{
                        flex: 8,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Fields
                    </Typography>
                    <Stack
                      direction="row"
                      sx={{ width: "100%" }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Button
                        variant="outlined"
                        onClick={handleCheckAll}
                        style={{ margin: "5px" }}
                      >
                        Check All
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handleUncheckAll}
                        style={{ margin: "5px" }}
                      >
                        Uncheck All
                      </Button>
                    </Stack>
                    <Paper
                      sx={{ width: "100%", height: "400px", overflow: "auto" }}
                      style={{ margin: "15px" }}
                      elevation={0}
                      variant="outlined"
                    >
                      <Stack>
                        {headerData &&
                          Object.keys(headerData)
                            .sort()
                            .map((ele, index) => (
                              <Stack
                                direction="row"
                                key={index}
                                sx={{ width: "90%", paddingLeft: "5px" }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={headerData[ele]["checked"]}
                                      onChange={() => handleCheckChange(ele)}
                                    />
                                  }
                                  label={alias ? headerData[ele]["alias"] : ele}
                                />
                              </Stack>
                            ))}
                      </Stack>
                    </Paper>
                  </Stack>
                  <CompareArrowsIcon
                    fontSize="large"
                    style={{
                      color: "#9b51e0",
                      marginLeft: "40px",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={transferHeaders}
                  ></CompareArrowsIcon>

                  <Stack style={{ width: "40%" }}>
                    <Typography
                      variant="h6"
                      color="secondary.main"
                      sx={{
                        flex: 8,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Export Fields
                    </Typography>
                    <Button
                      variant="contained"
                      color="success"
                      style={{ marginLeft: "10px" }}
                      onClick={() => setOpen(true)}
                    >
                      <SaveAltIcon
                        fontSize="medium"
                        style={{
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                      ></SaveAltIcon>
                      Save Profile
                    </Button>
                    <Paper
                      sx={{ width: "100%", height: "400px", overflow: "auto" }}
                      style={{ margin: "15px" }}
                      elevation={0}
                      variant="outlined"
                    >
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {transferData.map((item, index) => (
                                <Draggable
                                  draggableId={item.field}
                                  index={index}
                                  key={item.field}
                                >
                                  {(provided, snapshot) => (
                                    <Stack
                                      direction="row"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      sx={{
                                        marginBottom: "3px",
                                        background: "rgb(211,211,211,0.8)",
                                      }}
                                    >
                                      <DragIndicatorIcon
                                        fontSize="medium"
                                        style={{
                                          color: "#9b51e0",
                                          paddingLeft: "5px",
                                          paddingRight: "10px",
                                        }}
                                      ></DragIndicatorIcon>
                                      <Typography
                                        variant="subtitle1"
                                        key={index}
                                      >
                                        {alias
                                          ? item["alias"] || "none"
                                          : item.field}
                                      </Typography>
                                      {provided.placeholder}
                                    </Stack>
                                  )}
                                </Draggable>
                              ))}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Paper>
                  </Stack>
                </Stack>
              </Stack>
            )}
            <Button
              color="blue"
              variant="contained"
              size="medium"
              style={{ margin: "10px" }}
              disabled={!searchedDetail}
              onClick={doExport}
            >
              <SaveAltIcon
                fontSize="medium"
                style={{
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              ></SaveAltIcon>
              Export
            </Button>
          </Box>
        )}
      </Box>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack sx={style}>
          <Typography variant="h6" color="secondary">
            Enter Profile Name:
          </Typography>
          <TextField
            label={""}
            onChange={(e) => handleProfileChange(e)}
            value={profileName || ""}
            variant="standard"
          />
          <Button
            variant="contained"
            color="success"
            style={{ marginTop: "5px" }}
            onClick={saveProfile}
          >
            <SaveAltIcon
              fontSize="medium"
              style={{
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            ></SaveAltIcon>
            Save
          </Button>
        </Stack>
      </Modal>
    </UIWrapper>
  );
};

export default AcsExport;
