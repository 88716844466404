import React, { useEffect, useState } from "react";
import { Box, Typography, Stack } from "@mui/material";
import ProgressBarCard from "./ProgressBarCard";
import AptService from "../../services/AptService";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useInterval } from "../../components/helper/utils";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  h1: {
    fontFamily: "Roboto",
    color: "rgba(0, 0, 0, 0.6)",
    paddingLeft: "25px",
    paddingTop: "10px",
  },
};



const Progress = (props) => {

  const [runningWorkflows, setRunningWorkflows] = React.useState([]);
  const [list, setlist] = React.useState('RUNNING');
  const [checkRefresh, setCheckRefresh] = React.useState(0);
  const [loadingData, setLoadingData] = React.useState(true);

  const handleChange = (event) => {
    setLoadingData(true)
    setlist(event.target.value);
  };

  useEffect(() => {
    AptService.getWorkflows(list)
      .then((resp) => {
        setLoadingData(false)
        setRunningWorkflows(resp.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [list]);

  useEffect(() => {
    AptService.getWorkflows(list)
      .then((resp) => {
        setRunningWorkflows(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [checkRefresh]);

  useInterval(() => {
    AptService.getWorkflowsSpec()
      .then((resp) => {
        console.log(resp.data);
        resp.data.forEach((ele) => {
          AptService.checkStatus(ele.workflow_id)
            .then((resp) => {
              setCheckRefresh(checkRefresh + 1);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, 1000 * 30);

  return (
    <Box style={{ marginTop: "15px", padding: "15px" }}>
      <FormControl style={{ width: "160px", paddingBottom: "10px" }}>
        <Select
          id="demo-simple-select"
          value={list}
          onChange={handleChange}
        >
          <MenuItem value={'RUNNING'}>Running</MenuItem>
          <MenuItem value={'QUEUED'}>Queued</MenuItem>
          <MenuItem value={'COMPLETED'}>Completed</MenuItem>
          <MenuItem value={'FAILED'}>Failed</MenuItem>
          <MenuItem value={'CANCELED'}>Canceled</MenuItem>

        </Select>
      </FormControl>
      <Typography variant="h4" style={styles.h1}>
        {list} TASK
      </Typography>
      {
        loadingData !== true &&
        runningWorkflows.map((option, index) => (
          <ProgressBarCard
            clientName={option.client}
            task={option} progress={10}
            jobId={option.workflow_id}
            key={index}
            checkRefresh={checkRefresh}
            setCheckRefresh={setCheckRefresh}
            list={list}
          >
          </ProgressBarCard>
        ))
      }
      {
        loadingData === true &&
        <CircularProgress
          style={{ position: "absolute", top: "45%", left: "35%" }}
          size={"11rem"}
        />

      }
    </Box>
  );
}
export default Progress;

