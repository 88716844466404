import { useState, useRef, useEffect } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Modal,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { snackbarActions } from "../.././Store/snackbar";
import AcsService from "../../services/AcsService";
import AcsUniqueInvCode from "../AcsUniqueInvCode";

// const steps = ['Client', 'Project', 'Tracking Number', 'Subject Code', 'Visit ID'];
const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "400px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};

export default function AcsPreview(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [tableDetail, setTableDetail] = useState({});
  const [openDetail, setOpenDetail] = useState(false);
  const [subjectExpanded, setSubjectExpanded] = useState(false);
  const [sampleExpanded, setSampleExpanded] = useState(false);
  const [invExpanded, setInvExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [submitData, setSubmitData] = useState({});

  const [showUnique, setShowUnique] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const tableBarCodeData = useRef(null);

  const handleCheckDuplicates = () => {
    const allInvCodes =
      submitData.samples &&
      submitData.samples.length !== 0 &&
      submitData.samples.map((ele) => ele.inventory_code);
    const req_data = {
      client: submitData.client,
      project: submitData.project,
      inventory_codes: allInvCodes || [],
    };
    setLoading(true);
    setShowUnique(true);
    AcsService.getTableBarCodeDataAccession(req_data)
      .then((resp) => {
        setLoading(false);
        tableBarCodeData.current = resp.data;
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Failed Request",
          })
        );
      });
  };

  const handleShowUniqueClose = () => {
    setShowUnique(false);
    tableBarCodeData.current = null;
  };
  const handleApprove = async (updatedTableBarCodeData) => {
    setSubmitLoading(true);
    const uniqueBarcodeData = updatedTableBarCodeData.data?.reduce(
      (accum, curr) => {
        if (curr.inventory_code !== curr.old_inventory_code)
          accum[curr.old_inventory_code] = curr.inventory_code;

        return accum;
      },
      {}
    );

    let final = JSON.parse(JSON.stringify(submitData));
    let arrUrgent = [];
    final.samples.forEach((ele) => {
      if (ele["urgent"]) arrUrgent.push(ele.inventory_code);
    });
    final["urgent"] = arrUrgent;
    final["unique_inventory_codes"] = uniqueBarcodeData;

    await AcsService.submitForm(final)
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Successful Ingested Samples",
          })
        );
        setShowUnique(false);
        props.clearForm();
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.detail,
          })
        );
      });
    setSubmitLoading(false);
  };

  const handleCheckAll = () => {
    let newData = JSON.parse(JSON.stringify(submitData));
    newData.samples.forEach((ele, index) => {
      newData.samples[index]["urgent"] = true;
    });
    setSubmitData(newData);
  };

  const handleUncheckAll = () => {
    let newData = JSON.parse(JSON.stringify(submitData));
    newData.samples.forEach((ele, index) => {
      newData.samples[index]["urgent"] = false;
    });
    setSubmitData(newData);
  };
  const handleDetailData = (detail) => {
    setTableDetail(detail);
    setOpenDetail(true);
  };
  const handleSubmission = () => {
    let finalData = {
      client: props.client,
      project: props.project,
      //   subject_code: props.subjectCode,
      site: props.formListHeader.site,
      tracking_number: props.trackingNumber,
      carrier: props.formListHeader.carrier,
      date_received: props.formListHeader.date_received,
      time_received: props.formListHeader.time_received,
      dt_received_timezone: props.formListHeader.dt_received_timezone,
      comments: props.comments,

      // "static": {
      //     // "site": props.formList.site,
      //     // "gender": props.formList.gender,
      //     // "subject_id": props.formList.USUBJID,
      //     // "alternate_id": props.formList.alternate_id,
      //     // "date_of_birth": props.formList.date_of_birth,
      //     // "draw_date": props.formList.draw_date,
      //     // "draw_time": props.formList.draw_time
      // },
      subjects: [],
      samples: [],
    };

    // Object.keys(props.formList).forEach(ele => {
    //     if (props.formList[ele] && props.formList[ele] !== '') {
    //         finalData.static[ele] = props.formList[ele]
    //     }
    // })

    // Object.keys(props.dynamicValuesSubject).forEach((ele) => {
    //   if (
    //     props.dynamicValuesSubject[ele] &&
    //     props.dynamicValuesSubject[ele] !== ""
    //   ) {
    //     finalData.subject[ele] = props.dynamicValuesSubject[ele];
    //   }
    // });

    props.formValuesSubject.forEach((ele, index) => {
      let temp = {};

      Object.keys(ele).forEach((option) => {
        if (ele[option] && ele[option] !== "") {
          temp[option] = ele[option];
        }
      });
      let pushData = JSON.parse(JSON.stringify(temp));
      finalData.subjects.push(pushData);
    });

    props.formValues.forEach((ele, index) => {
      let temp = {};
      temp["urgent"] = false;

      Object.keys(ele.data).forEach((option) => {
        if (ele.data[option] && ele.data[option] !== "") {
          temp[option] = ele.data[option];
        }
      });
      temp["source"] = ele.source;
      temp["container_type"] = ele["container_type"];
      ele.inventory_code.forEach((option, pos) => {
        let pushData = JSON.parse(JSON.stringify(temp));
        pushData["inventory_code"] = option;
        finalData.samples.push(pushData);
      });
    });
    setSubmitData(finalData);

    setOpen(true);
  };
  const handleCheckChange = (index) => {
    let newData = JSON.parse(JSON.stringify(submitData));
    newData.samples[index]["urgent"] = !newData.samples[index]["urgent"];
    setSubmitData(newData);
  };
  return (
    <Paper
      style={{ padding: "10px", margin: "15px", background: "#eaeaf0" }}
      variant="outlined"
    >
      <Modal
        open={showUnique}
        onClose={handleShowUniqueClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack>
          <AcsUniqueInvCode
            loading={loading}
            tableBarCodeData={tableBarCodeData.current}
            processing={submitLoading}
            hamdleParentSubmission={handleApprove}
            service_name="accession"
          />
        </Stack>
      </Modal>
      <Typography
        variant="h6"
        color="secondary"
        style={{ padding: "5px", color: "secondary", height: "100%" }}
      >
        Preview Data Inputs:
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Inventory Codes</TableCell>
              <TableCell align="center">Client</TableCell>
              <TableCell align="center">Project</TableCell>
              <TableCell align="center">Subject Code</TableCell>
              <TableCell align="center">Site</TableCell>
              <TableCell align="center">Carrier Type</TableCell>
              <TableCell align="center">Tracking Number</TableCell>
              <TableCell align="center">Date Received</TableCell>

              <TableCell align="center">More..</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.formValues.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" component="td" scope="row">
                  {row.inventory_code[0] || "null"}
                </TableCell>
                <TableCell align="center" component="td" scope="row">
                  {props.client || "null"}
                </TableCell>
                <TableCell align="center" component="td" scope="row">
                  {props.project || "null"}
                </TableCell>
                <TableCell align="center" component="td" scope="row">
                  {props.formValuesSubject.map((option, i) => {
                    if (option.inventory_codes.length !== 0) {
                      return option.inventory_codes.map((ele) => {
                        if (ele === row.inventory_code[0]) {
                          return option.subject_code || "null";
                        }
                      });
                    }
                  })}
                </TableCell>
                <TableCell align="center" component="td" scope="row">
                  {props.formListHeader.site || "null"}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  {props.formListHeader.carrier || "null"}
                </TableCell>
                <TableCell align="center" component="td" scope="row">
                  {props.trackingNumber || "null"}
                </TableCell>
                <TableCell align="center" component="td" scope="row">
                  {props.formListHeader.date_received || "null"}
                </TableCell>

                <TableCell
                  align="center"
                  onClick={(e) => handleDetailData(row)}
                >
                  <Tooltip title="More Detail" style={{ cursor: "pointer" }}>
                    <ReadMoreIcon></ReadMoreIcon>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TextField
        multiline
        label={"Comment"}
        type="text"
        style={{
          textAlign: "center",
          color: "white",
          margin: "10px",
          width: "95%",
        }}
        size="small"
        value={props.comments || ""}
        onChange={(event) => props.setComments(event.target.value)}
        minRows={3}
        inputProps={{ maxLength: 200 }}
      />

      <Stack
        direction="row"
        style={{ width: "95%", margin: "10px", marginRight: "10px" }}
        alignItems="center"
        justifyContent="center"
      >
        <Button
          variant="contained"
          style={{ width: "95%" }}
          onClick={handleSubmission}
        >
          submit
        </Button>
      </Stack>

      <Modal
        open={openDetail}
        onClose={() => setOpenDetail(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack sx={style}>
          <Stack style={{ overflow: "auto", paddingTop: "8px" }}>
            <Stack
              direction="row"
              onClick={() => setSubjectExpanded(!subjectExpanded)}
              style={{ cursor: "pointer" }}
            >
              {subjectExpanded ? (
                <ExpandMoreIcon
                  fontSize="large"
                  style={{ color: "#9b51e0" }}
                ></ExpandMoreIcon>
              ) : (
                <ChevronRightIcon
                  fontSize="large"
                  style={{ color: "#9b51e0" }}
                ></ChevronRightIcon>
              )}
              <Typography color="secondary" sx={{ fontSize: 22 }}>
                {"Subject"}
              </Typography>
            </Stack>
            {subjectExpanded && (
              <Stack>
                {Object.keys(props.dynamicValuesSubject).map((row, index) => (
                  <Stepper nonLinear style={{ padding: "2px" }} key={index}>
                    <Step completed={false}>
                      <Typography color="secondary" sx={{ fontSize: 14 }}>
                        {row}
                      </Typography>
                    </Step>
                    <Step completed={row.status === "COMPLETED" ? true : false}>
                      <Typography color="secondary" sx={{ fontSize: 14 }}>
                        {props.formValuesSubject.map((item, i) => {
                          return item.inventory_codes.map((inv) => {
                            if (inv === tableDetail.inventory_code[0]) {
                              return item[row] || "null";
                            }
                          });
                        })}
                      </Typography>
                    </Step>
                  </Stepper>
                ))}
              </Stack>
            )}

            <Stack
              direction="row"
              onClick={() => setInvExpanded(!invExpanded)}
              style={{ cursor: "pointer" }}
            >
              {invExpanded ? (
                <ExpandMoreIcon
                  fontSize="large"
                  style={{ color: "#9b51e0" }}
                ></ExpandMoreIcon>
              ) : (
                <ChevronRightIcon
                  fontSize="large"
                  style={{ color: "#9b51e0" }}
                ></ChevronRightIcon>
              )}
              <Typography color="secondary" sx={{ fontSize: 22 }}>
                {"Inventory Codes"}
              </Typography>
            </Stack>
            {invExpanded && (
              <Stack>
                <Stack direction="row" style={{ width: "95%", margin: "5px" }}>
                  <Typography color="secondary" sx={{ fontSize: 16 }}>
                    {"Inventory Code Count: "}
                  </Typography>
                  <Typography color="secondary" sx={{ fontSize: 16 }}>
                    {tableDetail["inventory_code"].length}
                  </Typography>
                </Stack>
                <Stack direction="row" style={{ width: "95%", margin: "5px" }}>
                  <Typography color="secondary" sx={{ fontSize: 16 }}>
                    {tableDetail["inventory_code"].join(" | ")}
                  </Typography>
                </Stack>
              </Stack>
            )}

            <Stack
              direction="row"
              onClick={() => setSampleExpanded(!sampleExpanded)}
              style={{ cursor: "pointer" }}
            >
              {sampleExpanded ? (
                <ExpandMoreIcon
                  fontSize="large"
                  style={{ color: "#9b51e0" }}
                ></ExpandMoreIcon>
              ) : (
                <ChevronRightIcon
                  fontSize="large"
                  style={{ color: "#9b51e0" }}
                ></ChevronRightIcon>
              )}
              <Typography color="secondary" sx={{ fontSize: 22 }}>
                {"Sample"}
              </Typography>
            </Stack>
            {sampleExpanded && tableDetail && (
              <Stack>
                {Object.keys(tableDetail.data).map((row, index) => (
                  <Stepper nonLinear style={{ padding: "2px" }} key={index}>
                    <Step completed={false}>
                      <Typography color="secondary" sx={{ fontSize: 14 }}>
                        {row}
                      </Typography>
                    </Step>
                    <Step completed={row.status === "COMPLETED" ? true : false}>
                      <Typography color="secondary" sx={{ fontSize: 14 }}>
                        {tableDetail.data[row] || "null"}
                      </Typography>
                    </Step>
                  </Stepper>
                ))}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Modal>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack sx={style}>
          <Typography variant="h6" color="secondary">
            {"Do you want to accession any of these samples urgently?"}
          </Typography>
          <Divider style={{ width: "100%" }}></Divider>

          <Stack
            style={{
              overflow: "auto",
              maxHeight: "400px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleCheckAll}
              style={{ margin: "5px" }}
            >
              Check All
            </Button>
            <Button
              variant="outlined"
              onClick={handleUncheckAll}
              style={{ margin: "5px" }}
            >
              Uncheck All
            </Button>
            {submitData.samples &&
              submitData.samples.length !== 0 &&
              submitData.samples.map((ele, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={ele["urgent"]}
                      onChange={() => handleCheckChange(index)}
                    />
                  }
                  label={ele.inventory_code}
                />
              ))}
          </Stack>

          <Button
            variant="contained"
            color="success"
            style={{ marginTop: "5px" }}
            onClick={handleCheckDuplicates}
          >
            <SaveAltIcon
              fontSize="medium"
              style={{
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            ></SaveAltIcon>
            Accession
          </Button>
        </Stack>
      </Modal>
    </Paper>
  );
}
