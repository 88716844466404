import { useState } from "react";
import { PropTypes } from "prop-types";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Divider,
  Paper,
} from "../../mui";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const tableRowData = [
  "Col Name",
  "Client",
  "Project",
  "Inventory Code",
  "Prev Data",
  "New Data",
  "Table",
  "Modified Date",
  "Created By",
  "Reason",
];
const DropDownCardHistory = (props) => {
  const [pageLarge, setPageLarge] = useState(0);
  const [rowsPerPageLarge, setRowsPerPageLarge] = useState(6);

  const handleChangePageLarge = (event, newPage) => {
    setPageLarge(newPage);
  };

  const handleChangeRowsPerPageLarge = (event) => {
    setRowsPerPageLarge(+event.target.value);
    setPageLarge(0);
  };

  return (
    <Stack direction="column">
      <Box sx={{ width: "100%" }}>
        <Divider style={{ width: "100%", marginBottom: "10px" }}></Divider>
        <Grid
          // container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <TableContainer style={{ marginTop: "15px" }}>
            <Table
              stickyHeader={true}
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              size={"small"}
            >
              <TableHead sx={{ minWidth: 650 }}>
                <TableRow sx={{ minWidth: 650 }}>
                  {tableRowData.map((row, index) => (
                    <TableCell
                      align="left"
                      key={index}
                      style={{ fontWeight: "bold" }}
                    >
                      {row}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody style={{ border: "none" }}>
                {props.data.length !== undefined &&
                  props.data
                    .slice(
                      pageLarge * rowsPerPageLarge,
                      pageLarge * rowsPerPageLarge + rowsPerPageLarge
                    )
                    .map((row, index) => (
                      <TableRow
                        hover={true}
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell align="left">
                          {row.col_name || "None / Null"}
                        </TableCell>
                        <TableCell align="left">
                          {row.client || "None / Null"}
                        </TableCell>
                        <TableCell align="left">
                          {row.project || "None / Null"}
                        </TableCell>
                        <TableCell align="left">
                          {row.inventory_code || "None / Null"}
                        </TableCell>
                        <TableCell align="left">
                          {row.prev_data || "None / Null"}
                        </TableCell>
                        <TableCell align="left">
                          {row.new_data || "None / Null"}
                        </TableCell>
                        <TableCell align="left">
                          {row.table || "None / Null"}
                        </TableCell>
                        <TableCell align="left">
                          {row.created_on || "None / Null"}
                        </TableCell>
                        <TableCell align="left">
                          {row.created_by || "None / Null"}
                        </TableCell>
                        <TableCell align="left">
                          {row.reason || "None / Null"}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[6, 10, 25]}
              component="div"
              count={props.data.length}
              rowsPerPage={rowsPerPageLarge}
              page={pageLarge}
              onPageChange={handleChangePageLarge}
              onRowsPerPageChange={handleChangeRowsPerPageLarge}
            />
          </TableContainer>
        </Grid>
      </Box>
      {/* )} */}
    </Stack>
  );
};

DropDownCardHistory.propTypes = {
  header: PropTypes.string,
  data: PropTypes.array,
};

export default DropDownCardHistory;
