import {
  BoxSystem, Button,
  Divider,
  TextField,
  Typography
} from "../../../mui";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mapManifestActions } from "../../../Store/map-manifest";
import Conditions from "../Conditions";
import RuleDialogWrapper from "./RuleDialogWrapper";

export const SetSlice = ({
  startSlice,
  endSlice,
  setStartSlice,
  setEndSlice,
  regex,
  setRegex,
}) => {
  return (
    <BoxSystem sx={{ display: "flex", alignItems: "center", mb: 2, mt: 1 }}>
      <BoxSystem
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <BoxSystem sx={{ display: "flex", alignItems: "center" }}>
          <BoxSystem>
            <Typography
              sx={{ display: "flex", flex: 1, justifyContent: "center" }}
            >
              Begin
            </Typography>
            <TextField
              type="number"
              size="small"
              defaultValue={startSlice}
              onChange={(event) => setStartSlice(event.target.value)}
              sx={{ maxWidth: 75 }}
              error={!startSlice && !endSlice}
            />
          </BoxSystem>
          <Typography mt={2.5} mr={1} ml={1}>
            :
          </Typography>
          <BoxSystem>
            <Typography
              sx={{ display: "flex", flex: 1, justifyContent: "center" }}
            >
              End
            </Typography>
            <TextField
              type="number"
              size="small"
              defaultValue={endSlice}
              sx={{ maxWidth: 75 }}
              onChange={(event) => setEndSlice(event.target.value)}
              error={!startSlice && !endSlice}
            />
          </BoxSystem>
        </BoxSystem>
        <Divider sx={{ width: "100%" }}>
          <Typography>or</Typography>
        </Divider>
        <TextField
          id="standard-adornment-weight"
          size="small"
          inputProps={{
            "aria-label": "weight",
          }}
          label="RegEx"
          onChange={(event) =>
            setRegex({ init: false, value: event.target.value })
          }
          error={!regex.init && regex.value.length == 0}
        />
      </BoxSystem>
    </BoxSystem>
  );
};

const SliceDialog = ({ open, onClose }) => {
  const rules = useSelector((state) => state.mapManifest.rules);
  const [startSlice, setStartSlice] = useState("");
  const [endSlice, setEndSlice] = useState("");
  const [regex, setRegex] = useState({ init: true, value: "" });
  const dispatch = useDispatch();

  const resetState = () => {
    setStartSlice("");
    setEndSlice("");
    setRegex({ init: true, value: "" });
  };

  useEffect(() => {
    if (open) {
      dispatch(mapManifestActions.initRule({ action: "slice" }));
    }
    return;
  }, [open]);

  const onCloseHandler = () => {
    dispatch(mapManifestActions.deleteRule());
    resetState();
    onClose();
  };

  const onSubmitHandler = () => {
    dispatch(
      mapManifestActions.slice({
        startSlice,
        endSlice,
        regex: regex.value,
      })
    );
    resetState();
    onClose();
  };

  return (
    <RuleDialogWrapper
      open={open}
      onClose={onCloseHandler}
      title="Slice or Regex Substring"
      directions={
        "Set an index slice to grab part of the column, or pass a RegEx string to grab a match."
      }
    >
      <BoxSystem
        sx={{
          display: "flex",
          flexDirection: "column",
          jusitfyContent: "center",
          alignItems: "center",
        }}
      >
        <SetSlice
          startSlice={startSlice}
          endSlice={endSlice}
          setStartSlice={setStartSlice}
          setEndSlice={setEndSlice}
          regex={regex}
          setRegex={setRegex}
        />
        <Divider sx={{ width: "100%" }} />
        <Conditions />
        <Divider sx={{ width: "100%" }} />
        <Button
          sx={{ mt: 1 }}
          onClick={onSubmitHandler}
          disabled={
            (startSlice === "" && endSlice == "" && regex.value === "") ||
            ((startSlice !== "" || endSlice !== "") && regex.value !== "") ||
            rules[rules.length - 1]["rule"]["condition"].flat().includes("")
          }
        >
          Submit
        </Button>
      </BoxSystem>
    </RuleDialogWrapper>
  );
};

export default SliceDialog;
