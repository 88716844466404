import { Auth } from "aws-amplify";
import axios from "axios";
import React, { useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useQuery } from "react-query";
import { useDebounce } from "use-debounce";
import ExternalService from "./ExternalService";
const BASE_URL = process.env.ADMIN_API;

const ExternalServicesManager = () => {
  const [orgValue, setOrgValue] = useState("");
  const [serviceQuery, setServiceQuery] = useState("");
  const debouncedFilter = useDebounce(orgValue, 333);
  const [tableBulkData, setTableBulkData] = useState([]);

  const fetchAllservices = async () => {
    const response = await axios.get(
      `${BASE_URL}/admin/search/service?group=MERCK&service_type=external`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      }
    );
    return response.data;
  };

  const {
    isLoading: isLoadingServices,
    isSuccess: isSuccessServices,
    error: errorServices,
    data: dataServices,
  } = useQuery(
    ["services", debouncedFilter],
    () => fetchAllservices(debouncedFilter),
    { keepPreviousData: true, enabled: Boolean(debouncedFilter) }
  );

  const orgValues =
    orgValue &&
    dataServices &&
    dataServices.map((service) => {
      return {
        label: service.service_name,
      };
    });

  const selectedService =
    serviceQuery &&
    dataServices.length &&
    //this sets the log to be service id
    dataServices.find(
      (service) => service?.service_name === serviceQuery.label
    );

  const fetchOrganizations = async () => {
    const response = await axios.get(
      // `${BASE_URL}/client-admin/search/organization`,
      `${BASE_URL}/admin/search/groups?service_type=external`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      }
    );
    return response.data;
  };

  const {
    isLoading: isLoadingOrg,
    isSuccess: isSuccessOrg,
    error: errorOrg,
    data: dataOrg,
  } = useQuery(
    ["organizations", debouncedFilter],
    () => fetchOrganizations(debouncedFilter),
    { keepPreviousData: true, enabled: Boolean(debouncedFilter) }
  );

  // const allOrganizations =
  //   isSuccessOrg &&
  //   dataOrg.map((org) => {
  //     return {
  //       label: org.organization_name,
  //     };
  //   });

  // const orgId =
  //   orgValue &&
  //   dataOrg &&
  //   //this sets the log to be service id
  //   dataOrg.map((org) => org.organization_id);

  return (
    <>
      <h1>{orgValue ? orgValue + " Organization" : "Select Organization"}</h1>

      <Stack spacing={2} direction="row" justifyContent="left">
        <Autocomplete
          disablePortal
          disabled={serviceQuery ? true : false}
          id="organization-select"
          // options={allOrganizations ? allOrganizations : []}
          options={dataOrg ? dataOrg : []}
          onChange={(event, value) => {
            if (value) {
              setOrgValue(value);
            } else {
              setOrgValue("");
            }
          }}
          sx={{ width: 300 }}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Organization"
              variant="outlined"
              required
            />
          )}
        />
        {orgValue && (
          <Autocomplete
            disablePortal
            id="service-select"
            options={orgValues ? orgValues : []}
            onChange={(event, value) => {
              if (value) {
                setServiceQuery(value);
              } else {
                setServiceQuery("");
              }
            }}
            sx={{ width: 300 }}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={Boolean(serviceQuery)}
                label="Select Service"
                variant="outlined"
                required
              />
            )}
          />
        )}
      </Stack>

      {/* {orgId && serviceId && (
        <ExternalService orgId={dataOrg} serviceId={dataServices} />
      )} */}
      {orgValue && selectedService.service_id && (
        <ExternalService orgName={orgValue} serviceId={selectedService} />
      )}
    </>
  );
};

export default ExternalServicesManager;

// onInputChange={(_event, orgInputValue) => {
//   setInputValue(orgInputValue);
//   setOrgQuery(orgInputValue);
//   if (orgInputValue === "") {
//     setOrgQuery("");
//   }
// }}
