import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const JiraRequests = () => {
  const history = useHistory();
  window.open(
    "https://sampled.atlassian.net/servicedesk/customer/portal/13",
    "_blank",
    "noreferrer noopener"
  );
  history.goBack();
  return null;
};

export default JiraRequests;
