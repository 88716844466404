import { Auth } from "aws-amplify";
import axios from "axios";

const API_BASE_URL = process.env.MAIN_API + "/client-access"

class ReqService {

    async denyAccess(email) {
        console.log(email)
        let body = `${email}`
        let myInit = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
            },
        };

        return await axios.post(
            API_BASE_URL + "/deny-access",
            body,
            myInit
        );
    }
    async approveAccess(userObj) {
        let body = {
            organization_id: userObj.org,
            email: userObj.email,
            first_name: userObj.first_name,
            last_name: userObj.last_name,
            phone_number: userObj.phone_number,

        }
        let myInit = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
            },
        };

        return await axios.post(
            API_BASE_URL + "/grant-access",
            body,
            myInit
        );
    }
    async getRequests() {
        let myInit = {
            // params: {
            //     file_name: `${file_name}`,
            // },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
            },
        };

        return await axios.get(API_BASE_URL + "/new-requests", myInit);
    }

    async getOrgs() {
        let myInit = {
            // params: {
            //     file_name: `${file_name}`,
            // },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
            },
        };

        return await axios.get(API_BASE_URL + "/organizations", myInit);
    }


}

export default new ReqService();
